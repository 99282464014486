import * as defs from './defs';

export class TaxCalculatorKE extends defs.TaxCalculatorBase {
  public getDepositTax = (req: defs.TaxRequest): defs.TaxResponse => {
    // deposit tax - 0;
    return {
      rawTaxAmount: 0,
      taxAmount: 0,
      originalAmount: req.totalAmount,
      amountAfterTax: req.totalAmount,
      taxedAmount: 0,
    };
  };

  public getWithdrawTax = (req: defs.TaxRequest): defs.TaxResponse => {
    // withdraw tax - 0
    return {
      rawTaxAmount: 0,
      taxAmount: 0,
      originalAmount: req.totalAmount,
      amountAfterTax: req.totalAmount,
      taxedAmount: 0,
    };
  };

  public getSportsbookBetTax = (req: defs.TaxRequest): defs.TaxResponse => {
    // sport bet tax - 12.5% taken from net stake
    const netStake = req.totalAmount / 1.125;
    const rawTaxAmount = req.totalAmount - netStake;
    const taxAmount = Math.round(rawTaxAmount);

    return {
      rawTaxAmount,
      taxAmount,
      originalAmount: req.totalAmount,
      amountAfterTax: req.totalAmount - taxAmount,
      taxedAmount: req.totalAmount,
    };
  };

  public getSportsbookWinTax = (req: defs.TaxRequest): defs.TaxResponse => {
    // sport win tax: 15% of winnings (excluding bet)
    const taxedAmount = req.totalAmount - (req.notTaxableAmount ?? 0);
    const rawTaxAmount = 0.2 * taxedAmount;
    const taxAmount = Math.round(rawTaxAmount);

    const amountAfterTax = taxedAmount - taxAmount;

    return {
      rawTaxAmount,
      taxAmount,
      originalAmount: req.totalAmount,
      amountAfterTax,
      taxedAmount,
    };
  };
}
