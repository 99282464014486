export default [
  { id: 1, label: 'Amusnet' },
  { id: 2, label: 'Amatic' },
  { id: 3, label: 'Skywind' },
  { id: 4, label: 'CT Interactive' },
  { id: 5, label: 'Novomatic' },
  { id: 6, label: 'Spribe' },
  { id: 7, label: 'Pragmatic' },
  { id: 8, label: 'Evolution' },
  { id: 9, label: 'iSoftBet' },
  { id: 10, label: 'Relax Gaming' },
  { id: 11, label: 'EGT Digital' },
  { id: 12, label: 'Nevada' },
  { id: 13, label: 'OmniPlay' },
  { id: 15, label: 'Hacksaw' },
  { id: 16, label: 'Wazdan' },
  { id: 17, label: 'Playson' },
  { id: 18, label: 'Playtech' },
  { id: 20, label: 'Section8' },
  { id: 21, label: 'Split The Pot' },
  { id: 22, label: 'Booming' },
  { id: 23, label: 'Pateplay' },
];
