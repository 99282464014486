// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { endpoints } from '../../api/endpoints';
import { RootState } from '../index';

type ChallengeText = { text: { [lang: string]: string }; style: string | undefined };
type ChallengeImage = { url: string; style: string | undefined };

type ChallengeUI = {
  title: ChallengeText;
  description: ChallengeText;
  extended_rules: ChallengeText;
  objectives_image: ChallengeImage;
  corner_image: ChallengeImage;
  rules: ChallengeText;
  progress_currency: ChallengeText;
};

type ChallengeSportFilter = {
  id?: number;
  name: string;
  idSport?: string;
  idCategory?: string;
  idTournament?: string;
};

type ChallengeSportFilters = {
  prematch?: ChallengeSportFilter[];
  live?: ChallengeSportFilter[];
};

type ChallengeMeta = {
  ui: ChallengeUI;

  sport_filters: ChallengeSportFilters;
  games: any[string];
  recommended: any[string];
};

type ChallengePrize = {
  prize_display_currency: string;
  prize_value: number;
};

type Challenge = {
  activated: boolean;
  complete_date_limit: string;
  end_date: string;
  start_date: string;
  duration: number;
  enrolled: boolean;
  id: number;
  meta: ChallengeMeta;
  name: string;
  player_mission_id: number;
  prize: ChallengePrize;
  progress: number;
  status: string;

  value: string;
  max_value: string;

  challenge_scope: any[string];
  display_progress_as: string;
};

type FetchChallengesResult = {
  data: Challenge[];
  success: boolean;
};

type FetchChallengeError = {
  rejectValue: {
    error: string;
  };
};

export const fetchChallenges = createAsyncThunk<FetchChallengesResult, void, FetchChallengeError>(
  'challenges/list',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      const response = await axios.get(endpoints.challenges.list, {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
      });

      const okc = [];

      for (const c of response.data) {
        if (c.status === 'active' || c.status === 'pending' || c.status === 'completed') {
          okc.push(c);
        }
      }

      if (response.data) {
        return { data: okc, success: true };
      }

      return rejectWithValue({
        error: "Couldn't fetch challenges list",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

type CancelChallengeConfig = {
  player_mission_id: string | number;
};

type CancelChallengeResult = {
  player_mission_id: string | number;
  data: any;
  success: boolean;
};

type CancelChallengeError = {
  rejectValue: {
    error: string;
  };
};

export const cancelChallenge = createAsyncThunk<CancelChallengeResult, CancelChallengeConfig, CancelChallengeError>(
  'challenges/cancel',
  async ({ player_mission_id }, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      const response = await axios.get(endpoints.challenges.cancel + '/' + player_mission_id, {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
      });

      if (response.data) {
        return { player_mission_id, data: response.data, success: true };
      }

      return rejectWithValue({
        error: "Couldn't cancel challenge",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

type ClaimChallengeConfig = {
  player_mission_id: string | number;
};

type ClaimChallengeResult = {
  player_mission_id: string | number;
  data: any;
  success: boolean;
};

type ClaimChallengeError = {
  rejectValue: {
    error: string;
  };
};

export const claimChallenge = createAsyncThunk<ClaimChallengeResult, ClaimChallengeConfig, ClaimChallengeError>(
  'challenges/claim',
  async ({ player_mission_id }, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      const response = await axios.get(endpoints.challenges.claim + '/' + player_mission_id, {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
      });

      if (response.data) {
        return { player_mission_id, data: response.data, success: true };
      }

      return rejectWithValue({
        error: "Couldn't claim challenge",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

type EnrollChallengeConfig = {
  mission_id: number | string;
};

type EnrollChallengeResult = {
  mission_id: number | string;
  data: Challenge;
  success: boolean;
};

type EnrollChallengeError = {
  rejectValue: {
    error: string;
  };
};

export const enrollChallenge = createAsyncThunk<EnrollChallengeResult, EnrollChallengeConfig, EnrollChallengeError>(
  'challenges/enroll',
  async ({ mission_id }, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      const response = await axios.get(endpoints.challenges.enroll + '/' + mission_id, {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
      });

      if (response.data) {
        return { mission_id, data: response.data, success: true };
      }

      return rejectWithValue({
        error: "Couldn't enroll challenge",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

export interface ChallengesState {
  challenges: Challenge[];
  loading: boolean;
  // loaded: boolean;
  error: any;
  claimedChallengeActivated?: boolean | null;
  triggerCelebration?: boolean;
  triggerProgressIncrease?: boolean | null | number;
}

export const challengesSlice = createSlice({
  name: 'challenges',
  initialState: <ChallengesState>{
    challenges: [],
    loading: false,
    // loaded: false,
    error: null,
    claimedChallengeActivated: null,
    triggerCelebration: false,
    triggerProgressIncrease: false,
  },
  reducers: {
    updateChallenge: (state, action) => {
      const nc = action.payload;
      const cidx = state.challenges.findIndex((c) => c.player_mission_id === action.payload.player_mission_id);
      if (cidx !== -1) {
        if (nc.status !== 'active' && nc.status !== 'pending' && nc.status !== 'completed') {
          // remove inactive/expired/canceled challenge
          state.challenges.splice(cidx, 1);
        } else {
          // update challenge
          state.challenges[cidx].status = action.payload.status;
          state.challenges[cidx].max_value = action.payload.max_value;
          state.challenges[cidx].value = action.payload.value;
          state.triggerProgressIncrease = action.payload.player_mission_id;

          if (nc.status === 'completed') state.triggerCelebration = true;
        }
      } else {
        console.log(`Challenge ${action.payload.player_mission_id} not found for update`);
      }
    },
    resetChallengeCelebration: (state) => {
      state.triggerCelebration = false;
    },

    resetChallengeProgressIncrease: (state) => {
      state.triggerProgressIncrease = false;
    },

    resetChallengeState: (state) => {
      state.claimedChallengeActivated = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChallenges.fulfilled, (state, action) => {
        state.loading = false;
        // state.loaded = true;
        if (action.payload) {
          state.challenges = action.payload.data;
        }
        console.log('fetchChallenges.fulfilled', action.payload);
      })
      .addCase(fetchChallenges.pending, (state) => {
        state.loading = true;
        // state.loaded = false;
      })
      .addCase(fetchChallenges.rejected, (state, action) => {
        state.loading = false;
        // state.loaded = true;
        state.error = action.payload?.error;
        console.log('fetchChallenges.rejected', action.payload);
      })
      .addCase(claimChallenge.fulfilled, (state, action) => {
        state.loading = false;
        // remove claimed challenge
        const cidx = state.challenges.findIndex((c) => c.player_mission_id == action.payload.player_mission_id);
        if (cidx !== -1) {
          state.challenges.splice(cidx, 1);
        }
        console.log('claimChallenge.fulfilled', action.payload);
        state.claimedChallengeActivated = action.payload.data.activated;
      })
      .addCase(claimChallenge.pending, (state) => {
        state.loading = true;
        // state.loaded = false;
      })
      .addCase(claimChallenge.rejected, (state, action) => {
        state.loading = false;
        // state.loaded = true;
        state.error = action.payload?.error;
        console.log('claimChallenge.rejected', action.payload);
      })
      .addCase(enrollChallenge.fulfilled, (state, action) => {
        state.loading = false;
        // state.loaded = true;
        const idx = state.challenges.findIndex((c) => c.id == action.payload.mission_id);
        if (idx === -1) {
          state.challenges.push(action.payload.data);
        } else {
          state.challenges[idx] = action.payload.data;
        }
        console.log('enrollChallenge.fulfilled', action.payload);
      })
      .addCase(enrollChallenge.pending, (state) => {
        state.loading = true;
        // state.loaded = false;
      })
      .addCase(enrollChallenge.rejected, (state, action) => {
        state.loading = false;
        // state.loaded = true;
        state.error = action.payload?.error;
        console.log('enrollChallenge.rejected', action.payload);
      });
  },
});

export const { updateChallenge, resetChallengeState, resetChallengeCelebration, resetChallengeProgressIncrease } =
  challengesSlice.actions;

export type { Challenge };

export default challengesSlice.reducer;
