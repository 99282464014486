import React from 'react';

import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { getBonusCalculator, getEligibleBonuses } from '@/modules/casino/store/actions/application';
import { requestWallet } from '@/modules/casino/store/actions/wallet';
import Bonus from '@/components/classes/Bonus/Bonus';
import { requestLimits } from '@/modules/casino/store/actions';
import Bridger from '@/components/classes/PaymentProviders/Deposit/Bridger/Bridger';
import { AmountButtonInterface } from '@/components/classes/PaymentProviders/Interfaces/AmountButtonInterface';
import { DEFAULT_AMOUNT, PaymentProvider, paymentProviderNameById } from '@/constants/paymentProvider';
import {
  Card,
  PaymentDetailsInterface,
  PaymentProviderInterface,
} from '@/components/classes/PaymentProviders/Deposit/Interfaces/PaymentProviderInterface';
import Viva from '@/components/classes/PaymentProviders/Deposit/Viva/Viva';
import Safecharge from '@/components/classes/PaymentProviders/Deposit/Safecharge/Safecharge';
import Okto from '@/components/classes/PaymentProviders/Deposit/Okto/Okto';
import Abon from '@/components/classes/PaymentProviders/Deposit/Abon/Abon';
import { useLocation, useParams } from 'react-router-dom';
import { receivedVivaChargeToken } from '@/modules/casino/store/actions/paymentCheckout';
import { useTranslation } from 'react-i18next';
import './index.scss';
import {
  fetchPlayerPaymentMethods,
  getTax,
  oktoGetCustomer,
  resetDeposit,
} from '@/modules/casino/store/actions/deposit';
import Betlion from '@/components/classes/PaymentProviders/Deposit/Betlion/Betlion';
import { getCurrencyNumberInfo, getFormattedCurrencyAmount } from '@/page-components/utils/functions';
import { extractNumberFromFormattedString } from '@/components/modules/deposit/functions';
import { logDepositEvent } from '@/analytics';

type DepositProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type ModuleStateProps = {
  depositValue: number;
  depositValueWithoutTax: number;
  taxes: number | null;
  selectedBonus: any;
  currentStep: string;
  lastSteps: string[];
  selectedBonusInfo: any;
  selectedCard: any;
  bonuses: any;
  loading: boolean;
  bonusLoading: boolean;
  cardsLoading: boolean;
  inputsLoading: boolean;
  initialized: boolean;
  paymentProvider: number;
  defaultPaymentProvider: number;
  isOpen: boolean;
  paymentProviderObject: PaymentProviderInterface | null;
  activePaymentProviders: ActivePaymentProvider[];
  tcAccepted: boolean;
  privacyAccepted: boolean;
  maxLimit: number | boolean;
  oldValues: {
    [key: number]: PaymentDetailsInterface;
  };
  bonusCategories?: any;
  oldPaymentProvider?: number;
  cardInfo?: {
    number: string | undefined;
    name: string | undefined;
    expiry: {
      month: string | undefined;
      year: string | undefined;
      formatted: string | undefined;
    };
    cvv: string | undefined;
  };
  allSavedPaymentMethods?: any;
  alternativeInfo: string;
  hasAlternativeInfo: boolean;
  focusedInput?: boolean;
  trigger?: string;
  // currencyPosition: string;
};

type ActivePaymentProvider = {
  paymentProviderId: number;
  applePayButton?: {
    hide: boolean;
    onClick: () => void;
  };
  mainButton?: {
    hide: boolean;
  };
  channel?: any;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const DEPOSIT_PAYMENT_PROVIDERS_FIRST = '1';
const DEPOSIT_PAYMENT_METHODS_FIRST = '2';
// add more channel details here, if they appear in the future; they will be displayed in the component
const ALLOWED_CHANNEL_DETAILS_KEYS = ['merchant_code', 'payment_reference', 'account_identifier', 'merchant_name'];

const Deposit = (componentProps: DepositProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;
  const { t } = useTranslation();
  // params
  const location = useLocation(); // used with new URLSearchParams() (/deposit?param=value)
  const searchParams = new URLSearchParams(location.search);
  const { currentCurrency } = useAppSelector((state) => state.currencies);

  const forcedStep = searchParams.get('step') ?? null;

  // @ts-ignore
  const forcedAmount = searchParams.get('amount') ? extractNumberFromFormattedString(searchParams.get('amount')) : null;

  const defaultAmount: number = Number(
    forcedAmount ?? window.config?.depositSettings?.defaultAmount?.[currentCurrency] ?? DEFAULT_AMOUNT,
  );

  const dispatch = useAppDispatch();
  const { depositStatus, depositType } = useParams(); // /deposit/:depositStatus? (/deposit/error, /deposit/success, /deposit/pending, /deposit/cancel)
  const profile = useAppSelector((state) => state.profile);
  const deposit = useAppSelector((state) => state.deposit);
  const bonuses = useAppSelector((state) => state.application.eligibleBonuses);
  const bonusAward = useAppSelector((state) => state.application.bonusAward);
  const { taxes } = useAppSelector((state) => state.deposit);

  const initialStep: any = {};
  initialStep[DEPOSIT_PAYMENT_PROVIDERS_FIRST] = forcedStep ?? 'amount';
  initialStep[DEPOSIT_PAYMENT_METHODS_FIRST] = forcedStep ?? 'saved-payment-methods';

  const getPreselectedBonusId = () => {
    // check if bonusId exists in url as parameter
    const params = new URLSearchParams(location.search);
    return params.get('bonusId') ?? false;
  };

  const initialState = {
    selectedBonus: getPreselectedBonusId(),
    // currencyPosition: 'left',
    selectedBonusInfo: {},
    depositValue: defaultAmount,
    depositValueWithoutTax: 0,
    selectedCard: null,
    bonuses: null,
    taxes: null,
    initialized: false,
    currentStep: initialStep[props.properties.depositType] ?? initialStep[DEPOSIT_PAYMENT_PROVIDERS_FIRST],
    lastSteps: [initialStep[props.properties.depositType] ?? initialStep[DEPOSIT_PAYMENT_PROVIDERS_FIRST]],
    loading: false,
    bonusLoading: true,
    cardsLoading: false,
    inputsLoading: false,
    oldPaymentProvider: 0,
    paymentProvider: 0,
    paymentProviderObject: null,
    defaultPaymentProvider: 0,
    tcAccepted: true,
    privacyAccepted: true,
    oldValues: {},
    bonusCategories: {},
    allSavedPaymentMethods: [],
    activePaymentProviders: [],
    isOpen: false,
    cardInfo: {
      number: '',
      name: '',
      expiry: {
        month: '',
        formatted: '',
        year: '',
      },
      cvv: '',
    },
    maxLimit: false,
    alternativeInfo: '',
    hasAlternativeInfo: false,
    focusedInput: false,
  };

  const [state, setState] = React.useState<ModuleStateProps>(initialState);

  const setActivePaymentProviders = () => {
    let selectedProviders = searchParams.getAll('providerId[]');
    if (selectedProviders.length === 0) {
      // if no providerIds[] in url, add all defined in PaymentProvider
      selectedProviders = Object.values(PaymentProvider).map((provider) => provider.toString());
    }
    let loading: boolean | null = null;

    const activePaymentProviders: ActivePaymentProvider[] = [];
    // intersect selectedProviders with active providers
    selectedProviders.forEach((provider: any) => {
      const providerConfig = window.config.depositSettings?.providers?.[parseInt(provider, 10)];
      if (providerConfig?.isActive === '1') {
        const activePaymentProvider: ActivePaymentProvider = {
          paymentProviderId: parseInt(provider, 10),
          applePayButton: {
            hide: true,
            onClick: () => {},
          },
          mainButton: {
            hide: !(providerConfig?.isMainPaymentMethodActive === '1'),
          },
        };
        switch (parseInt(provider, 10)) {
          case PaymentProvider.betlion:
            const betlion = new Betlion({
              triggerSuccess: () => 0,
              triggerError: () => 0,
              triggerPending: () => 0,
              dispatch: dispatch,
              hideLoader: () => 0,
              showLoader: showLoader,
            });
            if (deposit.betLion.channels === null) {
              betlion.fetchChannels();
              loading = true;
              activePaymentProviders.push(activePaymentProvider); // this will be replaced when we receive the channels from the server
            } else {
              loading = false;
              deposit.betLion.channels?.map((channel: any) => {
                const newActivePaymentProvider = { ...activePaymentProvider };

                if (channel.channel_id === 'mobile') {
                  newActivePaymentProvider.channel = {
                    ...channel,
                    mno: {
                      player: deposit.betLion.mno.player,
                      // map others with value: value
                      others: deposit.betLion.mno.other,
                    },
                  };
                  activePaymentProviders.push(newActivePaymentProvider);
                } else {
                  if (PaymentProvider[`betlion_${channel.channel_id}`]) {
                    newActivePaymentProvider.channel = channel;
                    newActivePaymentProvider.paymentProviderId = PaymentProvider[`betlion_${channel.channel_id}`];
                    activePaymentProviders.push(newActivePaymentProvider);
                  }
                }
              });
            }
            break;
          case PaymentProvider.bridger:
            // check localstorage for bridger data
            const bridgerApplePayEnabled = window.localStorage.getItem('bridgerApplePayEnabled');
            if (bridgerApplePayEnabled) {
              providerConfig.isApplePayActive = bridgerApplePayEnabled;
            }
            if (providerConfig.isApplePayActive === '1') {
              const ap = new Bridger({
                triggerSuccess: () => 0,
                triggerError: () => 0,
                triggerPending: () => 0,
                dispatch: () => 0,
                hideLoader: () => 0,
                showLoader: () => 0,
              })?.getApplePay?.();
              if (ap) {
                activePaymentProvider.applePayButton = {
                  hide: !ap.isApplePayAvailable(),
                  onClick: ap.onApplePayButtonClicked,
                };
              }
            }
            if (!(activePaymentProvider.applePayButton?.hide && activePaymentProvider.mainButton?.hide)) {
              activePaymentProviders.push(activePaymentProvider);
            }
            break;
          case PaymentProvider.viva:
            if (providerConfig?.isApplePayActive === '1') {
              const ap = new Viva({
                triggerSuccess: () => 0,
                triggerError: () => 0,
                triggerPending: () => 0,
                dispatch: () => 0,
                hideLoader: () => 0,
                showLoader: () => 0,
              }).initVivaApplePay({
                amount: defaultAmount,
                bonusId: null,
              });

              if (ap) {
                activePaymentProvider.applePayButton = {
                  hide: !ap.isApplePayAvailable(),
                  onClick: ap.onApplePayButtonClicked,
                };
              }
            }
            if (!(activePaymentProvider.applePayButton?.hide && activePaymentProvider.mainButton?.hide)) {
              activePaymentProviders.push(activePaymentProvider);
            }
            break;
          default:
            activePaymentProviders.push(activePaymentProvider);
            break;
        }
      }
    });

    // sort activePaymentProviders by window.config.depositSettings.providers order
    activePaymentProviders.sort((a, b) => {
      const aOrder = window.config.depositSettings.providers[a.paymentProviderId]?.order
        ? parseInt(window.config.depositSettings.providers[a.paymentProviderId].order, 10)
        : 9999;
      const bOrder = window.config.depositSettings.providers[b.paymentProviderId]?.order
        ? parseInt(window.config.depositSettings.providers[b.paymentProviderId].order, 10)
        : 9999;
      return aOrder - bOrder;
    });

    const defaultPaymentProvider = activePaymentProviders?.[0]?.paymentProviderId;

    setState((v) => ({
      ...v,
      paymentProvider: defaultPaymentProvider,
      defaultPaymentProvider: defaultPaymentProvider,
      activePaymentProviders: activePaymentProviders,
      trigger: 'setActivePaymentProviders',
    }));
    if (loading !== null) {
      if (loading) {
        showLoader();
      } else {
        hideLoader('setActivePaymentProviders');
      }
    }
  };

  const triggerError = React.useCallback(() => {
    window.parent.postMessage({ type: 'deposit-response', status: 'error' }, '*');
    setState((v) => ({
      ...v,
      currentStep: 'error',
      trigger: 'triggerError',
    }));
    hideLoader('triggerError');
    logDepositEvent('deposit_failed', state);
  }, [state]);

  const triggerSuccess = React.useCallback(() => {
    window.parent.postMessage({ type: 'deposit-response', status: 'success' }, '*');
    let step = 'success';
    switch (state.paymentProviderObject?.getType()) {
      case PaymentProvider.okto:
        step = 'success-okto';
        break;
      case PaymentProvider.betlion_ekwanza:
        step = 'success-ekwanza';
        break;
      case PaymentProvider.betlion_paypay:
        step = 'success-paypay';
        break;
      case PaymentProvider.betlion:
        step = 'success-betlion-mobile';
        break;
      default:
        break;
    }
    setState((v) => ({
      ...v,
      currentStep: step,
      trigger: 'triggerSuccess',
    }));
    hideLoader('triggerSuccess');
    // call api to get wallet
    setTimeout(() => {
      dispatch(requestWallet());
    }, 500);
    logDepositEvent('deposit_success', state);
  }, [state]);

  const triggerPending = React.useCallback(() => {
    window.parent.postMessage({ type: 'deposit-response', status: 'pending' }, '*');
    setState((v) => ({
      ...v,
      currentStep: 'pending',
      trigger: 'triggerPending',
    }));
    hideLoader('triggerPending');
    logDepositEvent('deposit_pending', state);
  }, [state]);

  const showLoader = React.useCallback(() => {
    setState((v) => ({
      ...v,
      loading: true,
      trigger: 'showLoader',
    }));
  }, [state]);

  const showInputsLoader = React.useCallback(() => {
    setState((v) => ({
      ...v,
      inputsLoading: true,
      trigger: 'showInputsLoader',
    }));
  }, [state]);

  const hideLoader = React.useCallback(
    (debugString: string | boolean = false) => {
      // eslint-disable-next-line no-constant-condition
      if (debugString && false) {
        console.log('hiding loader', debugString);
      }
      setState((v) => ({
        ...v,
        loading: false,
        trigger: 'hideLoader',
      }));
    },
    [state],
  );

  const hideInputsLoader = React.useCallback(() => {
    setState((v) => ({
      ...v,
      inputsLoading: false,
      trigger: 'hideInputsLoader',
    }));
  }, [state]);

  React.useEffect(() => {
    setActivePaymentProviders();

    if (props.properties.depositType === DEPOSIT_PAYMENT_METHODS_FIRST) {
      dispatch(fetchPlayerPaymentMethods());
    }
    if (profile?.client_player_id) {
      initComponent();
    }
    if (state.currentStep === 'deposit-bridger') {
      state.paymentProviderObject?.fetchCards?.();
    }

    if (
      ['deposit-bridger', 'deposit-bridger-card', 'amount'].includes(state.currentStep) &&
      state.paymentProviderObject?.getType?.() === PaymentProvider.bridger
    ) {
      // after setting payment details, Apple Pay session is initialized automatically
      state.paymentProviderObject?.setPaymentDetails({
        amount: state.depositValue,
        bonusId: getPreselectedBonusId(),
      });
      // state.paymentProviderObject?.getApplePay?.()?.startSession?.();
    }

    dispatch(requestLimits());

    if (location.search) {
      // store the path to the game we're coming from so we can return to it after deposit
      const params = new URLSearchParams(location.search);
      const fromGame = params.get('from_game');
      if (fromGame != null) {
        localStorage.setItem('fgm', fromGame);
      }
    }
    // setCurrencyPosition();
    return () => {
      dispatch(resetDeposit());
      state.paymentProviderObject?.destroy();
    };
  }, []);

  // React.useEffect(() => {
  //   setCurrencyPosition();
  // },[currentCurrency]);

  React.useEffect(() => {
    if (state.loading) {
      // scroll to top, smooth
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [state.loading]);

  React.useEffect(() => {
    if (props.properties.depositType === DEPOSIT_PAYMENT_METHODS_FIRST) {
      if (!deposit.paymentMethods.loading) {
        const activePaymentProviderIds = state.activePaymentProviders.map((provider) => provider.paymentProviderId);
        const paymentMethods = deposit.paymentMethods.all.filter((method: any) =>
          activePaymentProviderIds.includes(parseInt(method.paymentMethodType)),
        );
        // loop through payment methods and create card objects
        const cards = paymentMethods.map((card: any) => {
          const tempPaymentProviderObject = getPaymentProviderByMethodType(parseInt(card.paymentMethodType), null);
          tempPaymentProviderObject?.setCards([card.paymentMethodDetails]);
          const all = tempPaymentProviderObject?.getCards();
          return all && all.length ? all[0] : null;
        });
        if (cards && cards.length) {
          cards[0].selectCard();
          setState((v) => ({
            ...v,
            allSavedPaymentMethods: cards ?? [],
            trigger: 'paymentReceived_cards',
          }));
        } else {
          setState((v) => ({
            ...v,
            allSavedPaymentMethods: [],
            trigger: 'paymentReceived_no_cards',
          }));
          checkDepositStatus();
        }
      }
    }
  }, [deposit.paymentMethods.all, state.activePaymentProviders]); // DEPOSIT_PAYMENT_METHODS_FIRST

  React.useEffect(() => {
    if (state.selectedBonus && bonusAward?.bonusId == state.selectedBonus) {
      const bonus = new Bonus(bonuses[state.selectedBonus], bonusAward.awardAmount ?? 0);
      const selectedBonusInfo = getBonusInfo(bonus, true, state.paymentProvider, bonusAward.awardAmount ?? 0);

      setState((v) => ({
        ...v,
        selectedBonusInfo: selectedBonusInfo,
        trigger: 'bonusAwardReceived',
      }));
    }
  }, [bonusAward]);

  React.useEffect(() => {
    updateTax(taxes);
  }, [taxes]);

  // const setCurrencyPosition = ()=> {
  //   const currencyPosition = getCurrencyNumberInfo({locale:'',amount:'1',currency: currentCurrency,digits: ''}).types?.[0] === 'a' ? 'right' : 'left';
  //   setState((v) => ({
  //     ...v,
  //     currencyPosition: currencyPosition,
  //     trigger: 'set-currencyPosition',
  //   }));
  // };

  const updateTax = React.useCallback(
    (taxes: any) => {
      const currentTax = taxes[state.depositValue] ?? 0;
      setState((v) => {
        let depositWithoutTax = v.depositValue - currentTax;
        if (depositWithoutTax < 0) {
          depositWithoutTax = 0;
        }

        return {
          ...v,
          depositValueWithoutTax: depositWithoutTax,
          taxes: currentTax,
          trigger: 'updateTax',
        };
      });
    },
    [state],
  );

  React.useEffect(() => {
    // loop through limits and get max limit
    const MAX_LIMIT = 999999;
    let maxLimit = MAX_LIMIT;
    if (profile.limits) {
      Object.keys(profile.limits).map((key) => {
        if (profile.limits[key].limit_amount) {
          const diff = profile.limits[key].limit_amount - profile.limits[key].accumulated_amount;
          if (diff < maxLimit) maxLimit = diff;
        }
      });
    }
    setState((v) => ({
      ...v,
      maxLimit: maxLimit === MAX_LIMIT ? false : maxLimit,
      trigger: 'updateMaxLimit',
    }));
  }, [profile.limits]);

  React.useEffect(() => {
    initComponent();
  }, [profile.client_player_id]);

  React.useEffect(() => {
    initPaymentProvider();
    resetBonus();
    if (state.paymentProviderObject?.getType() === PaymentProvider.bridger) {
      state.paymentProviderObject?.setAmount(state.depositValue);
    }
  }, [state.paymentProviderObject]);

  React.useEffect(() => {
    state.paymentProviderObject?.setBonusId(state.selectedBonus ? parseInt(state.selectedBonus) : null);
    // changePaymentProvider(state.oldPaymentProvider ?? state.paymentProvider);
  }, [state.selectedBonus]);

  const refreshBonusInfo = React.useCallback(() => {
    setState((v) => ({
      ...v,
      selectedBonusInfo: getBonusInfo(new Bonus(v.selectedBonus), true, v.paymentProvider),
      trigger: 'refreshBonusInfo',
    }));
  }, [state]);

  React.useEffect(() => {
    const isAnyInitialStep = Object.values(initialStep).includes(state.currentStep);

    if ((isAnyInitialStep || state.currentStep === 'final-step') && state.depositValue > 0) {
      state.paymentProviderObject?.setAmount(state.depositValue);
      selectBonus(state.selectedBonus, state.paymentProvider);
    }
  }, [state.depositValue]);

  // this is just for betlion
  React.useEffect(() => {
    if (deposit.betLion.mobile.status !== null) {
      switch (deposit.betLion.mobile.status) {
        case 2:
          triggerSuccess();
          // triggerPending();
          break;
        case 1:
          triggerSuccess();
          break;
        default:
          triggerError();
          break;
      }
    }
  }, [deposit.betLion.mobile]);

  React.useEffect(() => {
    if (deposit.betLion.ekwanza.paymentCode !== null) {
      triggerSuccess();
    }
  }, [deposit.betLion.ekwanza]);

  React.useEffect(() => {
    if (deposit.betLion.paypay.url !== null) {
      triggerSuccess();
    }
  }, [deposit.betLion.paypay]);

  React.useEffect(() => {
    if (deposit.betLion.channels) {
      setActivePaymentProviders();
    }
  }, [deposit.betLion.channels]);

  React.useEffect(() => {
    if (state.paymentProviderObject?.getType() === PaymentProvider.betlion_express) {
      // @ts-ignore
      state.paymentProviderObject.fetchAlternativePhoneNumber();
      showInputsLoader();
    }
  }, [state.hasAlternativeInfo]);

  React.useEffect(() => {
    if (deposit.betLion.express.alternativePhoneNumber) {
      hideInputsLoader();
      setState((v) => ({
        ...v,
        alternativeInfo: deposit.betLion.express.alternativePhoneNumber,
        trigger: 'alternativeInfoReceived',
      }));
    }
  }, [deposit.betLion.express.alternativePhoneNumber]);

  // this is just for bridger
  React.useEffect(() => {
    if (deposit.bridger.cashierToken) {
      initPaymentProvider();
      if (
        state.currentStep === 'deposit-bridger-card' ||
        state.currentStep === initialStep[DEPOSIT_PAYMENT_PROVIDERS_FIRST]
      ) {
        state.paymentProviderObject?.initInputsForNewCard();
      }
    }
  }, [deposit.bridger.cashierToken]);

  React.useEffect(() => {
    if (deposit.bridgerApplePay.cashierToken && deposit.bridgerApplePay.cashierKey) {
      state.paymentProviderObject?.initApplePay?.({
        ...deposit.bridgerApplePay,
        amount: state.depositValue,
        bonusId: state.selectedBonus,
      });
    }
  }, [deposit.bridgerApplePay.cashierToken, deposit.bridgerApplePay.cashierKey]);

  React.useEffect(() => {
    if (deposit.bridger.status) {
      if (deposit.bridger.has_3ds_challenge) {
        // redirect to 3ds
        state.paymentProviderObject?.redirect(deposit.bridger.url_3ds);
      } else {
        switch (deposit.bridger.status) {
          case 'approved':
            triggerSuccess();
            break;
          case 'pending':
            triggerPending();
            break;
          default:
            triggerError();
            break;
        }
      }
    }
  }, [deposit.bridger.status]);

  React.useEffect(() => {
    if (deposit.bridger.error || deposit.bridger.errorCode) {
      triggerError();
    }
  }, [deposit.bridger.error, deposit.bridger.errorCode]);

  // this is just for paysafe/skrill
  React.useEffect(() => {
    if (deposit.paysafe.iframeUrl) {
      state.paymentProviderObject?.redirect(deposit.paysafe.iframeUrl);
    }
  }, [deposit.paysafe.iframeUrl]);

  React.useEffect(() => {
    if (deposit.paysafe.error) {
      triggerError();
    }
  }, [deposit.paysafe.error]);

  // this is just for aircash
  React.useEffect(() => {
    if (deposit.aircash.iframeUrl) {
      state.paymentProviderObject?.redirect(deposit.aircash.iframeUrl);
    }
  }, [deposit.aircash.iframeUrl]);

  React.useEffect(() => {
    if (deposit.aircash.error) {
      triggerError();
    }
  }, [deposit.aircash.error]);

  // this is just for viva
  React.useEffect(() => {
    if (state.paymentProvider === PaymentProvider.viva) {
      if (deposit.viva.tokenList && state.currentStep === 'deposit-viva') {
        state.paymentProviderObject?.setCards(deposit.viva.tokenList);

        setState((v) => ({
          ...v,
          currentStep: deposit.viva.tokenList?.length ? 'deposit-viva' : 'deposit-viva-card',
          cardsLoading: deposit.viva.loading ?? false,
          trigger: 'vivaTokenListReceived',
        }));
      }
    }
  }, [deposit.viva]); // this is just for viva

  React.useEffect(() => {
    if (props.properties.depositType === DEPOSIT_PAYMENT_PROVIDERS_FIRST) {
      if (!deposit.paymentMethods.loading && deposit.paymentMethods.initialized) {
        if (state.paymentProvider === PaymentProvider.viva) {
          if (deposit.viva.tokenList && state.currentStep === 'deposit-viva') {
            //  get cards from paymentProviderObject
            const cards = state.paymentProviderObject?.getCards();
            if (cards?.length !== deposit.viva.tokenList.length) {
              state.paymentProviderObject?.setCards(deposit.viva.tokenList);
              if (deposit.viva?.tokenList?.length) {
                state.paymentProviderObject?.initInputsForSavedCards();
              }
            }

            setState((v) => ({
              ...v,
              currentStep: deposit.viva.tokenList?.length ? 'deposit-viva' : 'deposit-viva-card',
              cardsLoading: deposit.viva.loading ?? false,
              trigger: 'vivaTokenListReceived2',
            }));
          }
        } else if (state.paymentProvider === PaymentProvider.bridger) {
          if (state.currentStep === 'deposit-bridger') {
            //  get cards from paymentProviderObject
            const cards = state.paymentProviderObject?.getCards();
            if (cards?.length !== deposit.bridger?.tokenList?.length) {
              state.paymentProviderObject?.setCards(deposit.bridger?.tokenList ?? []);
              if (deposit.bridger?.tokenList?.length) {
                state.paymentProviderObject?.initInputsForSavedCards();
              }
            }
            setState((v) => ({
              ...v,
              currentStep: deposit.bridger?.tokenList?.length ? 'deposit-bridger' : 'deposit-bridger-card',
              cardsLoading: deposit.paymentMethods.loading ?? false,
              trigger: 'bridgerTokenListReceived',
            }));
          }
        }
      } else {
        setState((v) => ({
          ...v,
          cardsLoading: deposit.paymentMethods.loading ?? false,
          trigger: 'paymentMethodsLoading',
        }));
      }
    }
  }, [deposit.paymentMethods.loading, deposit.paymentMethods.all]); // DEPOSIT_PAYMENT_PROVIDERS_FIRST

  // this is just for viva
  React.useEffect(() => {
    if (deposit.viva.serverResponse) {
      if (state.currentStep === 'deposit-viva' || state.currentStep === 'deposit-viva-card') {
        if (deposit.viva.serverResponse === 'success') {
          triggerSuccess();
        } else {
          triggerError();
        }
      }
    }
  }, [deposit.viva.serverResponse]);

  // this is just for okto
  React.useEffect(() => {
    if (state.paymentProvider === PaymentProvider.okto) {
      setState((v) => ({
        ...v,
        tcAccepted: deposit.okto.customer.okto_terms_accepted,
        privacyAccepted: deposit.okto.customer.okto_terms_accepted, // privacy is terms
        trigger: 'oktoTermsReceived',
      }));
    }
  }, [deposit.okto.customer]);

  React.useEffect(() => {
    if (state.currentStep === 'deposit-okto') {
      if (deposit.okto.paymentCode) {
        triggerSuccess();
      } else {
        if (deposit.okto.error) {
          triggerError();
        }
      }
    }
  }, [deposit.okto]);

  React.useEffect(() => {
    if (state.selectedBonus && state.depositValue >= state.selectedBonusInfo?.deposit?.min) {
      dispatch(getBonusCalculator(state.selectedBonus, state.depositValue, currentCurrency));
    } else {
      if (state.selectedBonus && bonusAward?.bonusId == state.selectedBonus) {
        const bonus = new Bonus(bonuses[state.selectedBonus]);
        const selectedBonusInfo = getBonusInfo(bonus, true, state.paymentProvider);

        setState((v) => ({
          ...v,
          selectedBonusInfo: selectedBonusInfo,
          trigger: 'useEffect[state.depositValue,state.selectedBonus]',
        }));
      }
    }
    if (state.depositValue > 0) {
      // @ts-ignore
      if (typeof taxes[state.depositValue] === 'undefined') {
        dispatch(getTax(state.depositValue));
      } else {
        updateTax(taxes);
      }
    }
  }, [state.depositValue, state.selectedBonus]);

  React.useEffect(() => {
    createBonusCategories();
    preselectBonus();
  }, [bonuses, state.activePaymentProviders]);

  React.useEffect(() => {
    init();
  }, [state.activePaymentProviders]);

  React.useEffect(() => {
    // check if bonus categories is empty
    if (state.bonusCategories && Object.keys(state.bonusCategories).length) {
      selectBonus(state.selectedBonus, state.paymentProvider);
    }
  }, [state.bonusCategories]);

  React.useEffect(() => {
    // postMessage
    window.parent.postMessage({ type: 'deposit-step-changed', currentStep: state.currentStep }, '*');

    setState((v: any) => {
      if (v.lastSteps.length < 1) {
        return v;
      }

      let lastSteps = [...v.lastSteps];

      if (v.currentStep === v.lastSteps?.[v.lastSteps.length - 2]) {
        // remove last step
        lastSteps = lastSteps.slice(0, -1);
      }

      const lastStep = lastSteps[lastSteps.length - 1];
      if (lastStep === v.currentStep) {
        if (lastSteps === v.lastSteps) {
          return v;
        } else {
          return {
            ...v,
            lastSteps: [...lastSteps],
          };
        }
      }

      return {
        ...v,
        lastSteps: [...lastSteps, v.currentStep],
        trigger: 'lastSteps',
      };
    });

    switch (state.currentStep) {
      case 'amount':
        if (props.properties.depositType === DEPOSIT_PAYMENT_METHODS_FIRST) {
          state.paymentProviderObject?.initInputsForNewCard();
        }
        if (state.paymentProviderObject?.getType() === PaymentProvider.bridger) {
          if (deposit.bridgerApplePay.cashierKey && deposit.bridgerApplePay.cashierToken) {
            state.paymentProviderObject?.initApplePay?.({
              ...deposit.bridgerApplePay,
              amount: state.depositValue,
              bonusId: state.selectedBonus,
            });
          } else {
            state.paymentProviderObject.getApplePay?.()?.startSession?.();
          }
        }
        break;
      case initialStep[DEPOSIT_PAYMENT_METHODS_FIRST]:
        state.paymentProviderObject?.initInputsForSavedCards();
        break;
      case 'final-step':
        showLoader();

        // init payment provider based on url
        const tempString = window.localStorage.getItem('deposit');
        window.localStorage.removeItem('deposit');

        const tempObject = tempString ? JSON.parse(tempString) : [];
        const paymentProvider = tempObject?.paymentProvider ?? false;
        if (paymentProvider) {
          changePaymentProvider(parseInt(paymentProvider));
          setState((v) => ({
            ...v,
            depositValue: tempObject?.amount ? tempObject.amount / 100 : v.depositValue,
            trigger: 'final-step',
          }));
          hideLoader('changeStep');
          // init send confirmation for viva if exists maybe this needs to be called in the same useEffect as all the above
          if (parseInt(paymentProvider) === PaymentProvider.viva) {
            if (tempObject?.data?.chargeToken) {
              state.paymentProviderObject?.sendConfirmation(tempObject);
            }
          }
        }
        break;
      case 'deposit-bridger':
        // state.paymentProviderObject?.setPaymentDetails({
        //   amount: state.depositValue,
        //   bonusId: state.selectedBonus,
        // });
        // state.paymentProviderObject?.getApplePay?.()?.startSession?.();
        state.paymentProviderObject?.initInputsForSavedCards();
        break;
      case 'deposit-viva':
        state.paymentProviderObject?.initInputsForSavedCards();
        break;
      case 'deposit-bridger-card':
        // state.paymentProviderObject?.setPaymentDetails({
        //   amount: state.depositValue,
        //   bonusId: state.selectedBonus,
        // });
        // state.paymentProviderObject?.getApplePay?.()?.startSession?.();
        state.paymentProviderObject?.initInputsForNewCard();
        break;
      case 'deposit-viva-card':
        state.paymentProviderObject?.initInputsForNewCard();
        break;
      default:
        break;
    }
  }, [state.currentStep]);

  const init = () => {
    if (state.initialized) {
      try {
        // add tracking here in case it was from a redirect
        const tempString = window.localStorage.getItem('deposit');
        const tempObject = tempString ? JSON.parse(tempString) : [];
        let step = depositStatus ?? tempObject?.step ?? state.currentStep;
        if (step === 'success') {
          step = initialStep[props.properties.depositType];
          logDepositEvent('deposit_success', state);
        }
      } catch (e) {
        console.warn('Error in init', e);
      }

      return;
    }

    setState((v) => ({
      ...v,
      initialized: true,
      trigger: 'init',
    }));

    if (getPreselectedBonusId()) {
      showLoader();
    } else {
      if (depositType) {
        selectDepositType();
      } else {
        checkDepositStatus();
      }
    }
  };

  const loadEventListeners = () => {
    window.parent.postMessage({ type: 'event-listener', status: 'loaded' }, '*');
    // remove event listener to prevent double loading
    window.removeEventListener('message', processEvents);
    // prevent double loading
    window.addEventListener('message', processEvents, false);
  };

  const processEvents = (e: any) => {
    const data = e.data;
    // window.parent.postMessage(
    //   {
    //     type: 'deposit-message-event-log',
    //     data: data,
    //   },
    //   '*',
    // );
    switch (data.type) {
      case 'deposit-set-payment-details':
        // const { currency } = data.paymentDetails;
        setState((v) => ({
          ...v,
          depositValue: data.paymentDetails.amount,
          selectedBonus: data.paymentDetails?.bonusId ?? null,
          selectedCard: data.paymentDetails?.card ?? null,
          cardInfo: data.paymentDetails?.cardInfo ?? null,
          trigger: 'deposit-set-payment-details',
        }));
        state.paymentProviderObject?.setPaymentDetails(data.paymentDetails);
        break;
      case 'deposit':
        {
          // @ts-ignore
          const details = state.paymentProviderObject.getPaymentDetails();

          if (
            (details.amount =
              data.paymentDetails.amount &&
              // details.currency === data.paymentDetails.currency &&
              details.bonusId === data.paymentDetails.bonusId)
          ) {
            state.paymentProviderObject?.quickDeposit(data.paymentDetails);
          }
        }
        break;
      case 'quick-deposit': {
        // create a semaphore using localstorage to prevent double quick deposit
        if (window.localStorage.getItem('quick-deposit')) {
          return;
        }

        window.localStorage.setItem('quick-deposit', 'true');

        window.parent.postMessage({ type: 'quick-deposit', status: 'hand-shake' }, '*');
        if (data.paymentDetails.amount) {
          state.paymentProviderObject?.quickDeposit(data.paymentDetails);
        }
        break;
      }
      case 'render-apple-button': {
        if (data.paymentDetails.amount) {
          state.paymentProviderObject?.setPaymentDetails(data.paymentDetails);
          const ap = state.paymentProviderObject?.getApplePay?.();
          ap?.startSession?.();
        }
        break;
      }
      default:
        break;
    }
  };

  const selectDepositType = () => {
    if (depositType) {
      setState((v) => ({
        ...v,
        currentStep: depositType,
        trigger: 'selectDepositType',
      }));
      const depositTypeArray = depositType.split('-');
      switch (depositTypeArray[0]) {
        case 'bridger':
          loadEventListeners();
          changePaymentProvider(PaymentProvider.bridger);
          state.paymentProviderObject?.auth?.();
          if (depositTypeArray[1] === 'new') {
            state.paymentProviderObject?.initInputsForNewCard();
          } else if (depositTypeArray[1] === 'saved') {
            state.paymentProviderObject?.fetchCards?.();
            state.paymentProviderObject?.initInputsForSavedCards();
          }
          break;
        default:
          console.log('Unknown deposit type');
          break;
      }
    }
  };

  React.useEffect(() => {
    // in cordova app the url is dynamically changed so
    // we need to check if depositStatus has changed
    if (window.config.cordova && depositStatus) {
      checkDepositStatus();
    }
  }, [depositStatus]);

  React.useEffect(() => {
    if (state?.selectedCard?.providerId) {
      changePaymentProvider(state.selectedCard.providerId);
    }
  }, [state.selectedCard]);

  const checkDepositStatus = () => {
    // get info from url, localstorage, and default values
    const tempString = window.localStorage.getItem('deposit');

    const tempObject = tempString ? JSON.parse(tempString) : [];
    const paymentProvider = tempObject?.paymentProvider ?? state.defaultPaymentProvider;
    let step = depositStatus ?? tempObject?.step ?? state.currentStep;
    let amount = tempObject?.amount ? tempObject.amount / 100 : defaultAmount;

    if (step === 'cancel') {
      step = initialStep[props.properties.depositType];
    }
    // replace amount from url
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get('totalAmount')) {
      amount = extractNumberFromFormattedString(urlParams.get('totalAmount') ?? '0');
    }
    // depositStatus is the most important;
    // fallback: localstorage > state > default
    switch (step) {
      case 'success':
      case 'pending':
      case 'error':
      case 'final-step':
        // setTimeout(() => {
        setState((v) => ({
          ...v,
          currentStep: step,
          depositValue: amount,
          trigger: 'checkDepositStatus',
          // taxes: null,
        }));
        // }, 1000);
        break;

      default:
        getBonuses();
        setState((v) => ({
          ...v,
          currentStep: initialStep[props.properties.depositType],
          depositValue: amount,
          trigger: 'checkDepositStatusDefault',
          // taxes: null,
        }));
        break;
    }

    setTimeout(() => {
      // remove deposit from localstorage
      window.localStorage.removeItem('deposit');
    }, 3000);
  };

  const initComponent = () => {
    dispatch(receivedVivaChargeToken({ serverResponse: false })); // reset vivacharge token
    // init payment provider based on data from url/localstorage/state/defaultValues
    init();
  };

  const getBonuses = () => {
    resetBonus();
    dispatch(getEligibleBonuses()); // get bonuses
  };

  const toBonuses = React.useCallback(() => {
    setState((v: any) => ({
      ...v,
      currentStep: 'bonuses',
      trigger: 'toBonuses',
    }));
    hideLoader('toBonuses');
  }, [state, bonuses]);

  const toAmount = React.useCallback(() => {
    // this function actualy redirects to the last step
    setState((v) => ({
      ...v,
      currentStep: 'amount',
      trigger: 'toAmount',
      // depositValue: v.depositValue ?? defaultAmount, this needs to be removed??
    }));
    hideLoader('toAmount');
  }, [state]);

  const goBack = React.useCallback(() => {
    if (['success', 'pending', 'error'].includes(state.currentStep)) {
      return tryAgain();
    }
    let backStep = state.lastSteps[state.lastSteps.length - 2];

    if (
      !['bonuses', 'amount', 'deposit-viva', 'deposit-viva-card', 'deposit-bridger', 'deposit-bridger-card'].includes(
        backStep,
      )
    ) {
      backStep = initialStep[props.properties.depositType];
    }

    setState((v) => ({
      ...v,
      currentStep: backStep,
      trigger: 'goBack',
    }));
    hideLoader('goBack');
  }, [state]);

  const goToAllSavedMethods = React.useCallback(() => {
    setState((v) => ({
      ...v,
      currentStep: initialStep[DEPOSIT_PAYMENT_METHODS_FIRST],
      trigger: 'goToAllSavedMethods',
    }));
    hideLoader('goToAllSavedMethods');
  }, [state]);

  const getBonusInfo = React.useCallback(
    (bonusObj: Bonus, selected: boolean, paymentProvider: any, st?: any) => {
      if (!st) {
        st = state;
      }
      let bonus = bonusObj;
      if (!paymentProvider) {
        paymentProvider = st.paymentProvider;
      }
      const paymentProviders = bonus.getBonusPaymentMethodsRaw();
      // check if paymentProviders have payment provider
      if (paymentProviders.length && !paymentProviders.includes(paymentProvider)) {
        bonus = new Bonus(0);
      }

      const bonusId = bonus.getBonusId();
      const selectedBonusInfo = {
        name: bonus.getBonusName(),
        products: bonus.getBonusProducts(),
        primary_message: bonus.getBonusAmountDisplay(),
        secondary_message: bonus.getBonusDescription(),
        end_date: bonus.getBonusEndDate(),
        show_date: bonus.getBonusShowDate(),
        terms_url: bonus.getBonusTermsUrl(),
        cta_text: bonus.getBonusCtaText(),
        onClickCTA: toBonuses,
        image: bonus.getBonusImage(),
        icon: bonus.getBonusIcon(),
        bonus_conflicts: bonus.getBonusConflicts(),
        canBeSelected: !bonus.haveConflicts(),
        buttonInfo: !bonus.haveConflicts()
          ? {
              state: 'default',
              colorMapping: 'accent',
              buttonType: 'filled',
              size: 'large',
              style: 'text',
              shadow: 'depth_3',
              iconLeft: false,
              iconRight: false,
              ctaText: 'Claim Bonus',
              loading: false,
              icon: '',
              onClick: () => {
                if (bonusId === 0) {
                } else {
                  // @ts-ignore
                  selectBonus(bonusId, paymentProvider);
                }
              },
            }
          : {
              state: 'disabled',
              colorMapping: 'inverted',
              buttonType: 'filled',
              size: 'large',
              style: 'text',
              shadow: 'none',
              iconLeft: false,
              iconRight: false,
              ctaText: 'Claim Bonus',
              loading: false,
              icon: '',
            },
        id: bonusId,
        bonusId: bonusId,
        deposit: bonus.getBonusDeposit(),
        bonusAward: bonus.getBonusAmountDisplay(
          bonusAward?.bonusId == bonusId ? bonusAward ?? 0 : undefined,
          st.depositValue,
        ),
      };

      if (bonusId === 0) {
        if (st.bonusCategories?.[paymentProvider]?.length) {
          selectedBonusInfo.name = `<span style="color:#47B96A">${
            st.bonusCategories[paymentProvider].length
          } ${t('bonuses available')}</span>`;
        } else {
          selectedBonusInfo.name = state.bonusLoading ? 'skeleton' : t('No bonuses available');
        }
      }

      if (selected) {
        const depositMin = selectedBonusInfo?.deposit?.min ?? 0;

        if (depositMin > st.depositValue) {
          selectedBonusInfo.bonus_conflicts = [
            {

              // message: `${t('Minimum deposit value is')} ${getFormattedCurrencyAmount({amount:selectedBonusInfo.deposit.min, currency:currentCurrency, locale: '', digits: ''})}`,
              message: 'WARNING_BONUS_MINIMUM_DEPOSIT_{{MIN}}',
              translatePlaceholders: {
                // @ts-ignore
                MIN: getFormattedCurrencyAmount({amount:selectedBonusInfo.deposit.min, currency:currentCurrency, locale: '', digits: ''})
              }
            },
          ];
          selectedBonusInfo.canBeSelected = false;
        } else {
          const award = 0;

          selectedBonusInfo.secondary_message = getFormattedCurrencyAmount({amount:award, currency:currentCurrency, locale: '', digits: ''});
        }
      }

      return selectedBonusInfo;
    },
    [state, bonusAward],
  );

  const resetBonus = () => {
    if (!state.selectedBonus && !state.selectedBonusInfo) {
      // this is an init call
      const bonus = new Bonus(false);
      const bonusInfo = getBonusInfo(bonus, false, state.paymentProvider);

      setState((v) => ({
        ...v,
        selectedBonus: false,
        selectedBonusInfo: bonusInfo,
        trigger: 'resetBonus',
      }));
    }
  };

  const preselectBonus = React.useCallback(() => {
    // based on bonusId parameter from url
    const bonusId = getPreselectedBonusId();

    if (bonusId && bonuses?.[bonusId]) {
      // select bonus if exists
      const bonus = new Bonus(bonuses[bonusId]);
      let pm: number[] = bonus.getBonusPaymentMethodsRaw();
      const ids: number[] = state.activePaymentProviders.map((provider) => provider.paymentProviderId);
      if (!pm.length) {
        pm = ids;
      } else {
        pm = pm.filter((id) => ids.includes(id));
      }
      const oldValues = {};
      const bonusInfo = getBonusInfo(bonus, true, state.paymentProvider);
      const depositValue = forcedAmount ?? bonusInfo.deposit.min;
      pm.forEach((paymentMethod: any) => {
        // @ts-ignore
        oldValues[paymentMethod] = {
          selectedBonus: bonusId,
          selectedBonusInfo: bonusInfo,
          depositValue: depositValue,
        };
      });

      // @ts-ignore
      setState((v) => ({
        ...v,
        selectedBonus: bonusId,
        // currentStep: 'amount',
        depositValue: depositValue,
        // taxes: null,
        oldValues: {
          ...v.oldValues,
          ...oldValues,
        },
        trigger: 'preselectBonus',
      }));
      hideLoader();
      changePaymentProvider(pm[0]);
    } else {
      resetBonus();
      setState((v) => ({
        ...v,
        depositValue: defaultAmount,
        trigger: 'preselectBonus_else',
        // taxes: null,
      }));
      // hideLoader();
    }
  }, [state, bonuses]);

  const createBonusCategories = React.useCallback(() => {
    if (!bonuses) {
      return;
    }

    const bonusCategories: any = [];
    const ids: number[] = [];
    state.activePaymentProviders.map((provider) => {
      ids.push(provider.paymentProviderId);
      bonusCategories[provider.paymentProviderId] = [];
    });
    Object.keys(bonuses).map((id: any) => {
      const bonus: Bonus = new Bonus(bonuses[id]);
      let pm: number[] = bonus.getBonusPaymentMethodsRaw();
      if (!pm.length) {
        pm = ids;
      }
      pm.map((paymentMethod: any) => {
        // @ts-ignore
        if (bonusCategories[paymentMethod]) {
          // @ts-ignore
          bonusCategories[paymentMethod].push(getBonusInfo(bonus, false, paymentMethod));
        }
      });
    });
    if (state.bonusCategories !== bonusCategories) {
      setState((v) => ({
        ...v,
        bonusCategories: bonusCategories,
        bonuses: bonuses,
        bonusLoading: false,
        trigger: 'createBonusCategories',
      }));
    }
  }, [state, bonuses]);

  React.useEffect(() => {
    if (deposit.bridger.cashierToken) {
      if (state.paymentProviderObject?.getType?.() === PaymentProvider.bridger) {
        // @ts-ignore
        state.paymentProviderObject?.setCashierToken?.(deposit.bridger.cashierToken);
      }
    }
  }, [deposit.bridger.cashierToken]);

  const selectCard = React.useCallback(
    (c: any) => {
      setState((v) => ({
        ...v,
        selectedCard: c,
        trigger: 'selectCard',
      }));
    },
    [state],
  );

  const changePaymentProvider = React.useCallback(
    (paymentProvider: number) => {
      const oldType = state.paymentProviderObject?.getType() ?? false;

      if (oldType == paymentProvider) {
        // setState((v) => ({
        //   ...v,
        //   isOpen: !v.isOpen,
        // }));
        return;
      }
      const paymentDetails = { ...state.paymentProviderObject?.getPaymentDetails() };
      if (!paymentDetails.amount) {
        paymentDetails.amount = state.depositValue;
      }
      state.paymentProviderObject?.destroy();
      // @ts-ignore
      const oldValues = state.oldValues?.[paymentProvider] ?? state.oldValues?.[oldType] ?? {};
      const selectedBonus = oldValues?.selectedBonus ?? oldValues?.selectedBonus ?? 0;

      const selectedBonusInfo = getBonusInfo(new Bonus(bonuses?.[selectedBonus]), true, paymentProvider);

      const defaultState = {
        isOpen: true,
        tcAccepted: true,
        privacyAccepted: true,
        paymentProvider: paymentProvider,
        oldPaymentProvider: oldType,
        selectedBonus: selectedBonus,
        selectedBonusInfo: selectedBonusInfo,
        depositValue: oldValues?.amount ?? state.depositValue,
        oldValues: oldType
          ? {
              ...state.oldValues,
              [oldType]: {
                ...paymentDetails,
                selectedBonus: state.selectedBonus,
                selectedBonusInfo: selectedBonusInfo,
                depositValue: state.depositValue,
              },
            }
          : {},
      };
      // check if defaultState.depositValue is in the window.config.depositSettings[paymentProvider].defaultPresets;
      if (window.config?.depositSettings?.providers?.[paymentProvider]?.disabledInputAmount === '1') {
        // can't edit amount, so we need to use the default presets
        defaultState.depositValue =
          window.config?.depositSettings?.providers?.[paymentProvider]?.defaultAmount ??
          window.config?.depositSettings?.providers?.[paymentProvider]?.defaultPresets?.[0] ??
          defaultAmount;
        // TODO: select the closest amount rounded up from the default presets
      }
      switch (paymentProvider) {
        case PaymentProvider.bridger:
        case PaymentProvider.viva:
        case PaymentProvider.safecharge:
        case PaymentProvider.skrill:
        case PaymentProvider.betlion:
        case PaymentProvider.betlion_unitel:
        case PaymentProvider.betlion_telopay:
        case PaymentProvider.betlion_afrimoney:
        case PaymentProvider.betlion_ekwanza:
        case PaymentProvider.betlion_aki:
        case PaymentProvider.betlion_paypay:
        case PaymentProvider.betlion_pagaso:
        case PaymentProvider.betlion_multicaixa:
        case PaymentProvider.betlion_express:
          // @ts-ignore
          setState((v) => ({
            ...v,
            ...defaultState,
            paymentProviderObject: getPaymentProviderByMethodType(paymentProvider, paymentDetails),
            trigger: 'changePaymentProvider_betlion',
          }));
          break;
        case PaymentProvider.okto:
          // @ts-ignore
          setState((v) => ({
            ...v,
            ...defaultState,
            tcAccepted: deposit?.okto?.customer?.okto_terms_accepted ?? false,
            privacyAccepted: deposit?.okto?.customer?.okto_terms_accepted ?? false,
            paymentProviderObject: getPaymentProviderByMethodType(paymentProvider, paymentDetails),
            trigger: 'changePaymentProvider_okto',
          }));
          break;
        case PaymentProvider.abon:
          // @ts-ignore
          setState((v) => ({
            ...v,
            ...defaultState,
            // taxes: null,
            paymentProviderObject: getPaymentProviderByMethodType(paymentProvider, paymentDetails),
            trigger: 'changePaymentProvider_abon',
          }));
          break;
        default:
          console.warn(`${paymentProvider} ${t('unknown payment provider')}`);
          break;
      }
      if (props.properties.depositType === DEPOSIT_PAYMENT_METHODS_FIRST) {
        state.paymentProviderObject?.initInputsForNewCard();
      }
    },
    [state, bonuses],
  );

  const getPaymentProviderByMethodType = (paymentProvider: any, paymentDetails: any) => {
    let paymentProviderObject = null;
    const defaultData = {
      triggerSuccess: triggerSuccess,
      triggerPending: triggerPending,
      triggerError: triggerError,
      hideLoader: hideLoader,
      showLoader: showLoader,
      dispatch: dispatch,
      paymentDetails: paymentDetails,
      paymentProvider: paymentProvider,
    };

    switch (paymentProvider) {
      case PaymentProvider.bridger:
        paymentProviderObject = new Bridger({
          ...defaultData,
          hideInputsLoader: hideInputsLoader,
          showInputsLoader: showInputsLoader,
          selectCard: selectCard,
          settings: {
            blox: props.properties.bridgerBloxMainSettings,
            cards: props.properties.depositType === DEPOSIT_PAYMENT_METHODS_FIRST ? deposit.bridger.tokenList : false,
            validateCardInfo: validateCardInfo,
          },
        });
        break;
      case PaymentProvider.viva:
        paymentProviderObject = new Viva({
          ...defaultData,
          selectCard: selectCard,
          settings: {
            cards: props.properties.depositType === DEPOSIT_PAYMENT_METHODS_FIRST ? deposit.viva.tokenList : false,
          },
        });
        break;
      case PaymentProvider.betlion:
      case PaymentProvider.betlion_unitel:
      case PaymentProvider.betlion_telopay:
      case PaymentProvider.betlion_afrimoney:
      case PaymentProvider.betlion_ekwanza:
      case PaymentProvider.betlion_aki:
      case PaymentProvider.betlion_paypay:
      case PaymentProvider.betlion_pagaso:
      case PaymentProvider.betlion_multicaixa:
      case PaymentProvider.betlion_express:
        paymentProviderObject = new Betlion({
          ...defaultData,
          settings: {
            channels: deposit.betLion.channels,
          },
        });
        break;
      case PaymentProvider.safecharge:
      case PaymentProvider.skrill:
        paymentProviderObject = new Safecharge({
          ...defaultData,
        });
        break;
      case PaymentProvider.okto:
        paymentProviderObject = new Okto({
          ...defaultData,
        });
        break;
      case PaymentProvider.abon:
        paymentProviderObject = new Abon({
          ...defaultData,
        });
        break;
      default:
        console.warn(`${paymentProvider} ${t('unknown payment provider')}`);
        break;
    }
    return paymentProviderObject;
  };

  const initPaymentProvider = React.useCallback(() => {
    const initData = {
      currency: currentCurrency,
      paymentProvider: state.paymentProvider,
    };
    const initDataSavedPaymentMethods = {
      cardsFetched: props.properties.depositType === DEPOSIT_PAYMENT_METHODS_FIRST,
    };

    const initApplePay = {
      amount: state.depositValue,
      currency: currentCurrency,
      bonusId: state.selectedBonus,
    };

    // reset bonus:
    switch (state.paymentProvider) {
      case PaymentProvider.bridger:
        state.paymentProviderObject?.init({
          ...initData,
          ...initDataSavedPaymentMethods,
          ...initApplePay,
          cashierToken: deposit.bridger.cashierToken,
          apCashierToken: deposit.bridgerApplePay.cashierToken,
          apCashierKey: deposit.bridgerApplePay.cashierKey,
        });
        if (!depositType) {
          // if depositType is set, it's a quick deposit!
          if (state.currentStep === 'amount') {
            if (props.properties.depositType === DEPOSIT_PAYMENT_METHODS_FIRST) {
              state.paymentProviderObject?.initInputsForNewCard();
            }
          } else {
            state.paymentProviderObject?.initInputsForSavedCards();
          }
        }
        break;
      case PaymentProvider.viva:
        state.paymentProviderObject?.init({
          ...initData,
          ...initDataSavedPaymentMethods,
          ...initApplePay,
        });
        break;
      case PaymentProvider.safecharge:
      case PaymentProvider.skrill:
      case PaymentProvider.abon:
      case PaymentProvider.betlion:
      case PaymentProvider.betlion_unitel:
      case PaymentProvider.betlion_telopay:
      case PaymentProvider.betlion_afrimoney:
      case PaymentProvider.betlion_ekwanza:
      case PaymentProvider.betlion_aki:
      case PaymentProvider.betlion_paypay:
      case PaymentProvider.betlion_pagaso:
      case PaymentProvider.betlion_multicaixa:
      case PaymentProvider.betlion_express:
        state.paymentProviderObject?.init({
          ...initData,
        });
        break;
      case PaymentProvider.okto:
        state.paymentProviderObject?.init({
          ...initData,
          getCustomer: deposit.okto.customer.okto_terms_accepted === null,
        });
        break;
      default:
        console.warn(`${state.paymentProvider} ${t('unknown payment provider')}`);
        break;
    }
  }, [state]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    let newValue:number = 0;/*e.target.value === '' ? 0 : (state.focusedInput ? e.target.value?.replace(/[^0-9.]/g,'') : extractNumberFromFormattedString(e.target.value)) || state.depositValue;*/
    if (e.target.value !== '') {
      if(state.focusedInput) {
        newValue = parseFloat(e.target.value?.replace(/[^0-9.]/g,''));
      } else {
        newValue = extractNumberFromFormattedString(e.target.value);
      }
    } 

    if (newValue === state.depositValue) {
      return;
    }
    // @ts-ignore
    setState((v) => ({
      ...v,
      depositValue: e.target.value === '' ? 0 : newValue || state.depositValue || 0,
      taxes: null,
      trigger: 'onInputChange',
    }));
  };

  const onInputFocus = React.useCallback(() => { 
    console.log('onInputFocus');
    setState((v) => ({
      ...v,
      focusedInput: true,
      // depositValue: (state.depositValue),
      trigger: 'onInputFocus',
    }));
  },[state]);

  const onInputBlur = React.useCallback(( )=> {

    // @ts-ignore
    setState((v) => ({
      ...v,
      focusedInput: false,
      // @ts-ignore
      depositValue: parseFloat(parseFloat(state.depositValue).toFixed(2)), // double parsing to ensure that we have maximum 2 decimal places
      trigger: 'onInputBlur',
    }));
  },[state]);

  const onAlternativeInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setState((v) => ({
      ...v,
      alternativeInfo: e.target.value,
      trigger: 'onAlternativeInfoChange',
    }));
  };

  const toggleHasAlternativeInfo = () => {
    setState((v) => ({
      ...v,
      hasAlternativeInfo: !v.hasAlternativeInfo,
      trigger: 'toggleHasAlternativeInfo',
    }));
  };

  const selectBonus = React.useCallback(
    (e: any, paymentProvider?: any) => {
      if (e === null) return;

      let value = 0;
      if (typeof e !== 'object') {
        value = e;
      } else {
        const el = e.currentTarget ?? (e.currentTarget as HTMLButtonElement);
        // get data-bonus-id from parent
        value = el?.parentElement?.getAttribute('data-bonus-id');
      }

      setState((v) => {
        let provider = paymentProvider;
        if (!provider) {
          provider = v.oldPaymentProvider;
          if (!provider) {
            provider = v.paymentProvider;
            if (!provider) {
              provider = state.defaultPaymentProvider;
            }
          }
        }
        const bonus = new Bonus(value ? v.bonuses?.[value] : false);
        const bonusInfo = getBonusInfo(bonus, true, provider, v);

        let amountToSelect = v.depositValue;
        if (v.selectedBonus !== value) {
          const minDeposit = v.selectedBonusInfo?.deposit?.min ?? defaultAmount;
          if ([0, minDeposit, defaultAmount].includes(v.depositValue)) {
            amountToSelect = Math.max(minDeposit, v.depositValue, defaultAmount);
          }
        }
        // calculate current step
        let currentStep = v.currentStep;
        // @ts-ignore
        if (value !== 0 && value !== false /*&& v.selectedBonus !== value*/) {
          if (Object.values(initialStep).includes(v.lastSteps[v.lastSteps.length - 2])) {
            currentStep = v.lastSteps[v.lastSteps.length - 2];
          } else {
            currentStep = initialStep[props.properties.depositType];
          }
        }

        return {
          ...v,
          currentStep: currentStep,
          // paymentProvider: provider,
          selectedBonus: value,
          selectedBonusInfo: bonusInfo,
          depositValue: amountToSelect,
          oldValues: {
            ...v.oldValues,
            [provider]: {
              ...v.oldValues?.[provider],
              selectedBonus: value,
              selectedBonusInfo: bonusInfo,
              depositValue: v.depositValue,
            },
          },
          trigger: 'selectBonus',
        };
      });
    },
    [state],
  );

  const confirmDepositValue = React.useCallback(() => {
    if (state.depositValue > 0 && error == '') {
      switch (state.paymentProvider) {
        case PaymentProvider.bridger:
          switch (state.currentStep) {
            case 'deposit-bridger':
              state.paymentProviderObject?.confirmPayment();
              return;
            case 'deposit-bridger-card':
              state.paymentProviderObject?.setPaymentDetails({
                ...state.paymentProviderObject?.getPaymentDetails(),
                card: false,
                cardInfo: {
                  holderName: state.cardInfo?.name ?? '',
                  cardNumber: state.cardInfo?.number ?? '',
                  expiryMonth: state.cardInfo?.expiry?.month,
                  expiryYear: state.cardInfo?.expiry?.year,
                  cvv: state.cardInfo?.cvv ?? '',
                },
              });
              state.paymentProviderObject?.confirmPayment();
              showLoader();
              return;
            case 'amount':
              if (props.properties.depositType === DEPOSIT_PAYMENT_METHODS_FIRST) {
                state.paymentProviderObject?.setPaymentDetails({
                  ...state.paymentProviderObject?.getPaymentDetails(),
                  card: false,
                  cardInfo: {
                    holderName: state.cardInfo?.name ?? '',
                    cardNumber: state.cardInfo?.number ?? '',
                    expiryMonth: state.cardInfo?.expiry?.month,
                    expiryYear: state.cardInfo?.expiry?.year,
                    cvv: state.cardInfo?.cvv ?? '',
                  },
                });
                state.paymentProviderObject?.confirmPayment();
                showLoader();
                return;
              } else {
                setState((v) => ({
                  ...v,
                  currentStep: 'deposit-bridger',
                  cardsLoading: true,
                  trigger: 'confirmDepositValue_amount!DEPOSIT_PAYMENT_METHODS_FIRST',
                }));
              }
              break;
            case initialStep[DEPOSIT_PAYMENT_METHODS_FIRST]:
              state.paymentProviderObject?.quickDeposit({
                card: state.selectedCard,
                amount: state.depositValue,
                bonusId: state.selectedBonus,
              });
              return;
            default:
              setState((v) => ({
                ...v,
                currentStep: 'deposit-bridger',
                cardsLoading: true,
                trigger: 'confirmDepositValue_default',
              }));
              break;
          }
          break;
        case PaymentProvider.safecharge:
        case PaymentProvider.skrill:
        case PaymentProvider.betlion:
        case PaymentProvider.betlion_unitel:
        case PaymentProvider.betlion_telopay:
        case PaymentProvider.betlion_afrimoney:
        case PaymentProvider.betlion_ekwanza:
        case PaymentProvider.betlion_aki:
        case PaymentProvider.betlion_paypay:
        case PaymentProvider.betlion_pagaso:
        case PaymentProvider.betlion_multicaixa:
          setState((v) => ({
            ...v,
            currentStep: 'deposit',
            trigger: 'confirmDepositValue_betlion',
          }));
          showLoader();
          break;
        case PaymentProvider.betlion_express:
          state.paymentProviderObject?.setPaymentDetails({
            ...state.paymentProviderObject?.getPaymentDetails(),
            alternatePhoneNumber: state.hasAlternativeInfo ? state.alternativeInfo : null,
          });
          setState((v) => ({
            ...v,
            currentStep: 'deposit-express',
            trigger: 'confirmDepositValue_betlion_express',
          }));
          showLoader();
          break;
        case PaymentProvider.viva:
          switch (state.currentStep) {
            case 'deposit-viva':
              state.paymentProviderObject?.confirmPayment();
              return;
            case 'deposit-viva-card':
              state.paymentProviderObject?.setPaymentDetails({
                ...state.paymentProviderObject?.getPaymentDetails(),
                card: true,
                cardInfo: {
                  holderName: state.cardInfo?.name ?? '',
                  cardNumber: state.cardInfo?.number ?? '',
                  expiryMonth: state.cardInfo?.expiry?.month,
                  expiryYear: state.cardInfo?.expiry?.year,
                  cvv: state.cardInfo?.cvv ?? '',
                },
              });
              state.paymentProviderObject?.confirmPayment();
              showLoader();
              return;
            case initialStep[DEPOSIT_PAYMENT_METHODS_FIRST]:
              state.paymentProviderObject?.quickDeposit({
                card: state.selectedCard,
                amount: state.depositValue,
                bonusId: state.selectedBonus,
              });
              return;
            default:
              setState((v) => ({
                ...v,
                currentStep: 'deposit-viva',
                cardsLoading: true,
                trigger: 'confirmDepositValue_viva-default',
              }));
              break;
          }
          break;
        case PaymentProvider.okto:
          setState((v) => ({
            ...v,
            currentStep: 'deposit-okto',
            trigger: 'confirmDepositValue_okto',
          }));
          showLoader();
          break;
        case PaymentProvider.abon:
          setState((v) => ({
            ...v,
            currentStep: 'deposit-abon',
            trigger: 'confirmDepositValue_abon',
          }));
          showLoader();
          break;
        default:
          console.warn(`${state.paymentProvider} ${t('unknown payment provider')}`);
          break;
      }

      state.paymentProviderObject?.confirmDepositValue();
    }
  }, [state]);

  const onDepositButtonClick = React.useCallback(
    (e: any) => {
      const el = e.currentTarget ?? (e.currentTarget as HTMLButtonElement);
      // get data-value from button
      const value = el.getAttribute('data-value');

      if (value) {
        setState((v: any) => {
          let depositValue = Number(value);
          if (window.config?.depositSettings?.presetsType === 'incremental') {
            depositValue = Number(v.depositValue) + Number(value);
          }
          return {
            ...v,
            depositValue: depositValue,
            taxes: null,
            trigger: 'onDepositButtonClick',
          };
        });
      }
    },
    [state],
  );

  const validateCardInfo = React.useCallback(
    (field: string, data: any) => {
      window.parent.postMessage(
        {
          type: 'deposit-card-validation',
          field: field, // cvv, number, expiry
          data: data,
        },
        '*',
      );
      if (field === 'expiry') {
        // @ts-ignore
        setState((v) => ({
          ...v,
          cardInfo: {
            ...v.cardInfo,
            expiry: {
              ...v.cardInfo?.expiry,
              formatted: data.valid ? 'valid' : 'invalid',
            },
          },
          trigger: 'validateCardInfo_expiry',
        }));
      } else {
        // @ts-ignore
        setState((v) => ({
          ...v,
          cardInfo: {
            ...v.cardInfo,
            [field]: data.valid ? 'valid' : 'invalid',
          },
          trigger: 'validateCardInfo_!expiry',
        }));
      }
    },
    [state],
  );

  const getDepositButtons = () => {
    if (!state.paymentProviderObject) {
      changePaymentProvider(state.paymentProvider);
    }
    const buttons: AmountButtonInterface[] = state.paymentProviderObject?.getAmountButtons(state, {
      currentCurrency: currentCurrency,
      setStateCallback: setState,
      onDepositButtonClick: onDepositButtonClick,
    });

    return buttons;
  };

  const onToggleLoading = () => {
    setState((v) => ({
      ...v,
      loading: !v.loading,
      trigger: 'onToggleLoading',
    }));
  };

  const onTCChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setState((v) => ({
        ...v,
        tcAccepted: event.target.checked,
        trigger: 'onTCChange',
      }));
    },
    [state],
  );

  const onPrivacyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((v) => ({
      ...v,
      privacyAccepted: event.target.checked,
      trigger: 'onPrivacyChange',
    }));
  };

  const getDateOfBirth = (nin: string) => {
    if (!nin || nin.length < 13) {
      return '';
    }
    const year = nin.substr(1, 2);
    const month = nin.substr(3, 2);
    const day = nin.substr(5, 2);
    return `${day}.${month}.19${year}`;
  };

  // @ts-ignore
  let error: string = deposit && deposit.error ? deposit.error : '';

  if (state.depositValue > 0) {
    const limits = state.paymentProviderObject?.getLimits() ?? { min: 0, max: 0 };
    // @ts-ignore
    if (state.maxLimit !== false && state.maxLimit < limits.max) {
      // @ts-ignore
      limits.max = state.maxLimit;
    }
    if (state.depositValue < limits.min) {
      // error = `${t('Minimum deposit amount is')} ${getFormattedCurrencyAmount({amount:limits.min, currency:currentCurrency, locale: '', digits: ''})}`;
      error = 'ERROR_MINIMUM_DEPOSIT_AMOUNT_{{MIN}}';
    } else if (state.depositValue > limits.max) {
      // error = `${t('Maximum deposit amount is')} ${getFormattedCurrencyAmount({amount:limits.max, currency:currentCurrency, locale: '', digits: ''})}`;
      error = 'ERROR_MAXIMUM_DEPOSIT_AMOUNT_{{MAX}}';
      // props.properties.text = `Maximum deposit amount is {{MAX}} {{CURRENCY}}`;
      // props.properties.placeholders = { MAX: limits.max, CURRENCY: formatCurrency(currentCurrency) };
    }
  }

  let depositCode = '';
  let depositQRCode = '';
  let depositPaymentLink = '';
  if (state.paymentProviderObject?.getType() == PaymentProvider.okto) {
    depositCode = deposit.okto?.paymentCode;
  } else if (state.paymentProviderObject?.getType() == PaymentProvider.betlion_ekwanza) {
    depositCode = deposit.betLion.ekwanza?.paymentCode;
  } else if (state.paymentProviderObject?.getType() == PaymentProvider.betlion_paypay) {
    depositQRCode = deposit.betLion.paypay?.qrCode;
    depositPaymentLink = deposit.betLion.paypay?.url;
  }

  const skeletonCard = {
    token: '',
    type: 'skeleton',
    number: '',
    expiration_date: 0,
    verified: 0,
    selected: false,
    selectCard: () => null,
  };

  const isDepositDisabled = (paymentProviderId: any) => {
    if (state.focusedInput) {
        return true;
    }
    let depositDisabled = state.depositValue === 0 || error !== '';
    if (!state.privacyAccepted || !state.tcAccepted) {
      depositDisabled = true;
    }

    if (paymentProviderId == PaymentProvider.bridger) {
      if (state.currentStep === 'deposit-bridger-card') {
        depositDisabled =
          state.cardInfo?.cvv !== 'valid' ||
          state.cardInfo?.expiry?.formatted !== 'valid' ||
          state.cardInfo?.number !== 'valid';
      } else if (state.currentStep === 'amount' && props.properties.depositType === DEPOSIT_PAYMENT_METHODS_FIRST) {
        depositDisabled =
          state.cardInfo?.cvv !== 'valid' ||
          state.cardInfo?.expiry?.formatted !== 'valid' ||
          state.cardInfo?.number !== 'valid';
      } else if (
        state.currentStep === 'deposit-bridger' ||
        state.currentStep === initialStep[DEPOSIT_PAYMENT_METHODS_FIRST]
      ) {
        depositDisabled = state.cardInfo?.cvv !== 'valid';
      }
    }
    return depositDisabled;
  };

  const tryAgain = React.useCallback(() => {
    dispatch(resetDeposit());

    dispatch(fetchPlayerPaymentMethods());
    if (state.paymentProviderObject?.getType() == PaymentProvider.okto) {
      dispatch(oktoGetCustomer());
    }
    setState((v) => ({
      ...initialState,
      depositValue: v.depositValue,
      depositValueWithoutTax: v.depositValueWithoutTax ?? v.depositValue,
      taxes: v.taxes ?? 0,
      selectedBonusInfo: v.selectedBonusInfo,
      selectedBonus: v.selectedBonus,
      bonusCategories: v.bonusCategories,
      bonuses: v.bonuses,
      bonusLoading: false,
      cardsLoading: false,
      allSavedPaymentMethods: v.allSavedPaymentMethods,
      lastSteps: [],
      initialized: false,
      trigger: 'tryAgain',
    }));
    hideLoader('tryAgain');
  }, [state]);

  let activeProviderOptions = {};
  const numberCurrency = getCurrencyNumberInfo({locale: '', amount: state.depositValue, currency: currentCurrency,digits: ''});
  const depositValue = state.focusedInput ? state.depositValue : numberCurrency.amount;

  const limits = state.paymentProviderObject?.getLimits() ?? { min: 0, max: 0 };
  // @ts-ignore
  if (state.maxLimit !== false && state.maxLimit < limits.max) {
    // @ts-ignore
    limits.max = state.maxLimit;
  }
  const translatePlaceholders = {
    MAX: `${getFormattedCurrencyAmount({
      locale: '',
      amount: limits.max ?? 0,
      digits: '',
      currency: currentCurrency,
    })}`,
    MIN: `${getFormattedCurrencyAmount({
      locale: '',
      amount: limits.min ?? 0,
      digits: '',
      currency: currentCurrency,
    })}`,
  };
  // @ts-ignore
  const contextValue = {
    ...state,
    bonuses: state.bonusCategories?.[state.paymentProvider],
    depositButtons: getDepositButtons(),
    taxValue: state.taxes,
    selectBonus: selectBonus,
    confirmDepositValue: confirmDepositValue,
    currency: (currentCurrency),
    depositValue: depositValue,
    onDepositValueChange: onInputChange,
    error: error,
    onToggleLoading: onToggleLoading,
    minimumDeposit: state.paymentProviderObject?.getLimits().min,
    amountButtonInfo:
      error == '' && state.depositValue > 0 && !isDepositDisabled(state.paymentProvider)
        ? {
            state: 'default',
            colorMapping: 'accent',
            buttonType: 'filled',
            size: 'large',
            style: 'text',
            shadow: 'depth_3',
            iconLeft: false,
            iconRight: false,
            ctaText: 'Deposit',
            icon: '',
          }
        : {
            state: 'disabled',
            colorMapping: 'inverted',
            buttonType: 'filled',
            size: 'large',
            style: 'text',
            shadow: 'none',
            iconLeft: false,
            iconRight: false,
            ctaText: 'Deposit',
            icon: '',
          },
    paymentProviderList: state.activePaymentProviders.map((provider: any) => {
      const depositDisabled = isDepositDisabled(provider.paymentProviderId);

      let hideTC = provider.paymentProviderId !== PaymentProvider.okto;
      if (provider.paymentProviderId == PaymentProvider.okto) {
        hideTC = !!deposit.okto?.customer?.okto_terms_accepted;
      }
      const applePayProvider = state?.paymentProviderObject?.getApplePay?.();
      let applePayButton = provider.applePayButton ?? { hide: true };
      if (applePayProvider) {
        // copy object to avoid errors;
        applePayButton = {
          ...applePayButton,
          onClick: applePayProvider.onApplePayButtonClicked,
        };
      }

      let options = {};
      switch (provider.paymentProviderId) {
        case PaymentProvider.betlion:
        case PaymentProvider.betlion_unitel:
        case PaymentProvider.betlion_telopay:
        case PaymentProvider.betlion_afrimoney:
        case PaymentProvider.betlion_ekwanza:
        case PaymentProvider.betlion_aki:
        case PaymentProvider.betlion_paypay:
        case PaymentProvider.betlion_pagaso:
        case PaymentProvider.betlion_multicaixa:
          options = {
            ...provider.channel,
            channelDetails: provider?.channel
              ? Object.entries(provider.channel)
                  .map((val) => {
                    if (Array.isArray(val) && val[0] && ALLOWED_CHANNEL_DETAILS_KEYS.includes(val[0])) {
                      return {
                        key: val[0].toLocaleUpperCase() + '_DEPOSIT_PROVIDER_CODE_KEYNAME',
                        value: val[1],
                      };
                    }
                    return null;
                  })
                  .filter((val) => val !== null)
              : [],
          };
          break;
        case PaymentProvider.betlion_express:
          options = {
            ...provider.channel,
            channelDetails: provider?.channel
              ? Object.entries(provider.channel)
                  .map((val) => {
                    if (Array.isArray(val) && val[0] && ALLOWED_CHANNEL_DETAILS_KEYS.includes(val[0])) {
                      return {
                        key: val[0].toLocaleUpperCase() + '_DEPOSIT_PROVIDER_CODE_KEYNAME',
                        value: val[1],
                      };
                    }
                    return null;
                  })
                  .filter((val) => val !== null)
              : [],
            alternativeInfo: state.alternativeInfo,
            changeAlternativeInfo: onAlternativeInfoChange,
            hasAlternativeInfo: state.hasAlternativeInfo,
            toggleHasAlternativeInfo: toggleHasAlternativeInfo,
            phoneNumber: profile?.phone ?? '',
          };
          break;
        case PaymentProvider.okto:
          options = {
            tcAccepted: state.tcAccepted,
            privacyAccepted: state.privacyAccepted,
          };
          break;
        default:
          break;
      }

      if (provider.paymentProviderId === state.paymentProvider) {
        activeProviderOptions = options;
      }

      return {
        id: provider.paymentProviderId,
        currentStep: `amount-${paymentProviderNameById[provider.paymentProviderId]?.toLowerCase()}`,
        buttons: getDepositButtons(),
        selectedBonusInfo: state.selectedBonusInfo ?? getBonusInfo(new Bonus(false), false, provider.paymentProviderId),
        bonusCounter: state.bonusCategories?.[provider.paymentProviderId]?.length ?? 0,
        taxValue: state.taxes,
        currency: (currentCurrency),
        value: depositValue,
        error: error,
        onValueChange: onInputChange,
        onAmountInputFocus: onInputFocus,
        onAmountInputBlur: onInputBlur,
        // currencyPosition: state.currencyPosition,
        changePaymentProvider: () => {
          // asd needs to be fixed?
          changePaymentProvider(provider.paymentProviderId);
        },
        isOpen: state.paymentProvider == provider.paymentProviderId && state.isOpen,
        inputAmountDisabled:
          window.config?.depositSettings?.providers?.[provider.paymentProviderId]?.disabledInputAmount === '1',
        onTCChange: onTCChange,
        onPrivacyChange: onPrivacyChange,
        hideTC: hideTC,
        confirmButton: {
          state: depositDisabled ? 'disabled' : 'default',
          colorMapping: depositDisabled ? 'inverted' : 'primary',
          buttonType: 'filled',
          size: 'small',
          style: 'text',
          shadow: depositDisabled ? 'none' : 'depth_2',
          loading: state.loading,
          iconLeft: false,
          iconRight: false,
          ctaText: 'Deposit',
          icon: '',
          onClick: depositDisabled ? () => 0 : confirmDepositValue,
          hide: provider.mainButton?.hide ?? false,
        },
        applePayButton: applePayButton ?? { hide: true },
        hideProvider: provider.mainButton?.hide && applePayButton?.hide,
        options: options,
        translatePlaceholders: translatePlaceholders,
      };
    }),
    providerWithCard: {
      cards: state.cardsLoading
        ? [
            skeletonCard,
            skeletonCard,
            skeletonCard,
            skeletonCard,
            skeletonCard,
            skeletonCard,
            skeletonCard,
            skeletonCard,
          ]
        : state.paymentProviderObject?.getCards() ?? [],
      isSelectedCard: state.paymentProviderObject?.getPaymentDetails()?.card ?? false,
      emptyFn: () => 0,
      useNewCard: () => {
        // asd needs to be fixed?
        state.paymentProviderObject?.setPaymentDetails({
          ...state.paymentProviderObject?.getPaymentDetails(),
          card: false,
        });
        setState((v) => ({
          ...v,
          currentStep: state.paymentProvider == PaymentProvider.bridger ? 'deposit-bridger-card' : 'deposit-viva-card',
          trigger: 'useNewCard',
        }));
      },
      onCardNumberChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        // asd needs to be fixed?
        const oldValue = state.cardInfo?.number ?? '';
        const value = e.target.value;
        let newValue = oldValue;
        if (value.match(/^[0-9\s]*$/) && value.length <= 19) {
          newValue = value
            .replace(/\D/g, '')
            .replace(/(\d{4})(\d)/, '$1 $2')
            .replace(/(\d{4})(\d)/, '$1 $2')
            .replace(/(\d{4})(\d)/, '$1 $2');
        }
        if (newValue !== oldValue) {
          // @ts-ignore
          setState((v) => ({
            ...v,
            cardInfo: {
              ...v.cardInfo,
              number: newValue,
            },
            trigger: 'onCardNumberChange',
          }));
        }
      },
      onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        // asd needs to be fixed?
        const oldValue = state.cardInfo?.name ?? '';
        const value = e.target.value;
        let newValue = oldValue;
        if (value.length <= 50) {
          // check if value is alphanumeric
          if (value.match(/^[0-9a-zA-Z-\s'.]*$/)) {
            newValue = value;
          } else {
            if (value.length === 1) {
              newValue = '';
            }
          }
        }
        // @ts-ignore
        if (newValue !== oldValue) {
          // @ts-ignore
          setState((v) => ({
            ...v,
            cardInfo: {
              ...v.cardInfo,
              name: newValue,
            },
            trigger: 'onNameChange',
          }));
        }
      },
      onExpiryDateChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        // asd needs to be fixed?
        let value = e.target.value?.replace(/\D/g, '');
        let month = '';
        let year = '';
        switch (value.length) {
          case 1:
            if (value.length === 1 && parseInt(value) > 1) {
              value = '0' + value;
            }
            month = value;
            break;
          case 2:
            if (parseInt(value[0]) === 1 && parseInt(value[1]) > 2) {
              month = '0' + value[0];
              year = value[1];
              value = '0' + value[0] + '/' + value[1];
            } else {
              month = value;
            }
            break;
          case 3:
            month = value[0] + value[1];
            year = value[2];
            value = value[0] + value[1] + '/' + value[2];
            break;
          case 4:
            month = value[0] + value[1];
            year = value[2] + value[3];
            value = value[0] + value[1] + '/' + value[2] + value[3];
            break;
          default:
            month = state.cardInfo?.expiry?.month ?? '';
            value = state.cardInfo?.expiry?.formatted ?? '';
            year = state.cardInfo?.expiry?.year ?? '';
            break;
        }
        // @ts-ignore
        setState((v) => ({
          ...v,
          cardInfo: {
            ...v.cardInfo,
            expiry: {
              month: e.target.value.split('/')[0],
              year: e.target.value.split('/')[1],
              formatted: value,
            },
          },
          trigger: 'onExpiryDateChange',
        }));
      },
      onSecurityCodeChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        // asd needs to be fixed?
        let value = state.cardInfo?.cvv ?? '';
        if (e.target?.value.match(/^[0-9]*$/) && e.target?.value?.length <= 4) {
          value = e.target.value;
        } else {
          value = state.cardInfo?.cvv ?? '';
        }

        // @ts-ignore
        setState((v) => ({
          ...v,
          cardInfo: {
            ...v.cardInfo,
            cvv: value,
          },
          trigger: 'onSecurityCodeChange',
        }));
      },
      cardNumber: state.cardInfo?.number ?? '',
      name: state.cardInfo?.name ?? '',
      expiryDate: state.cardInfo?.expiry?.formatted ?? '',
      securityCode: state.cardInfo?.cvv ?? '',
      year: state.cardInfo?.expiry?.year ?? '',
      month: state.cardInfo?.expiry?.month ?? '',
    },
    toAmount: toAmount,
    toBonuses: toBonuses,
    depositCode: depositCode,
    depositQRCode: depositQRCode,
    depositPaymentLink: depositPaymentLink,
    playerName: profile?.first_name + ' ' + profile?.last_name,
    playerNin: profile?.nin,
    playerDateOfBirth: getDateOfBirth(profile?.nin),
    allSavedPaymentMethods: state.allSavedPaymentMethods.map((method: Card) => {
      return {
        ...method,
        selected: method.token === state.selectedCard?.token,
      };
    }),
    onBack: goBack,
    goToAllSavedMethods: goToAllSavedMethods,
    tryAgain: tryAgain,
    translatePlaceholders: translatePlaceholders,
    activeProviderOptions: activeProviderOptions,
  };
  React.useEffect(() => {
    /**
     * idei:
     * - nu se schimba providerul de plata cand se apeleaza select card;
     * - cand se confirma, in functie de ce payment provider are metoda de plata asignata sa se instantieze noul obiect
     *    - 1. init()
     *    - 2. confirmDepositValue() -> cheama auth in interior
     *    - probabil setPaymentDetails, desi e posibil sa se transfere informatiile la changePaymentProvider;
     *    - probabil confirmPayment();
     *
     */
    // log stack trace
    // console.trace();
  }, [state.currentStep]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Deposit;
