import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';

import { ticketSettledListRequest } from '../../../../modules/bets/store/actions/tickets';
import { buildTicketLists } from '../utils/functions';

import './index.scss';
import { useTranslation } from 'react-i18next';

type BetsSettledProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const BetsSettled = (componentProps: BetsSettledProps) => {
  const dispatch = useAppDispatch();
  const settled = useAppSelector((state) => state.bets.tickets.ticketsSettled);
  const inProgress = useAppSelector((state) => state.bets.tickets.requestInProgress.settled);
  const showMore = useAppSelector((state) => state.bets.tickets.showMore);

  const [page, setPage] = React.useState<number | null>(null);
  const [extended, setExtended] = React.useState<any>({});
  const { i18n, t } = useTranslation();

  const onExtend = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.hash != null && e.currentTarget.dataset.hash !== '') {
      const hash = e.currentTarget.dataset.hash;
      setExtended((prevState: any) => ({
        ...prevState,
        [hash]: prevState[hash] != null ? !prevState[hash] : true,
      }));
    }
  }, []);

  const handleChangePage = React.useCallback(() => {
    setPage((page) => (page ?? 0) + 1);
  }, []);

  React.useEffect(() => {
    setPage(1);
  }, []);

  React.useEffect(() => {
    if (page === null) return;
    dispatch(ticketSettledListRequest({ page }));
  }, [page]); //eslint-disable-line

  const contextValue = React.useMemo(() => {
    const contextValue = {
      tickets: buildTicketLists(settled, extended, i18n.language, t, false, null, {}),
      inProgress,
      onExtend,
      showMore,
      handleChangePage,
    };

    return contextValue;
  }, [settled, inProgress, extended, onExtend, handleChangePage, showMore, page, i18n.language, t]);

  //console.log('BetsSettled[contextValue]: ', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default BetsSettled;
