import * as actionTypes from './actionTypes';

export const loginUsername = (username) => {
  return {
    type: actionTypes.login.USERNAME,
    username: username,
  };
};

export const setLoginUsername = (username, error = false, method = undefined, allowUsernameButton = false) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.login.SET_USERNAME,
      username: username,
      method: method,
      allowUsernameButton: allowUsernameButton,
      error: error,
    });
    dispatch({
      type: actionTypes.requestManager.RESET,
    });
  };
};

export const loginUsernameError = (error = true) => {
  return {
    type: actionTypes.login.ERROR_USERNAME,
    error: error,
  };
};

export const loginPasswordError = (error = true) => {
  return {
    type: actionTypes.login.ERROR_PASSWORD,
    error: error,
  };
};

export const loginError = (loginError = true) => {
  return {
    type: actionTypes.login.ERROR_LOGIN,
    loginError: loginError,
  };
};

export const loginStep = (step = 0) => {
  return {
    type: actionTypes.login.STEP,
    step: step,
  };
};

export const loginMethod = (method) => {
  return {
    type: actionTypes.login.METHOD,
    method: method,
  };
};

export const loginPassword = (password) => {
  return {
    type: actionTypes.login.PASSWORD,
    password: password,
  };
};

export const setLoginPassword = (password, error = false, allowLoginButton = false) => {
  return {
    type: actionTypes.login.SET_PASSWORD,
    password: password,
    allowLoginButton: allowLoginButton,
    errorPassword: error,
  };
};

export const loginStart = (params) => {
  return {
    type: actionTypes.login.REQUEST_AUTHENTICATION,
    params: params,
  };
};

export const inRequest = (value = false) => {
  return {
    type: actionTypes.login.IN_REQUEST,
    value: value,
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.login.LOGOUT_USER,
    });
    dispatch({
      type: actionTypes.requestManager.RESET,
    });
  };
};

export const allowSmsRequest = (allow = false) => {
  return {
    type: actionTypes.login.ALLOW_SMS_REQUEST,
    value: allow,
  };
};

export const smsEtaValue = (eta) => {
  return {
    type: actionTypes.login.SMS_ETA,
    eta: eta,
  };
};

export const startSmsCountdown = (eta) => {
  return {
    type: actionTypes.login.START_SMS_COUNTDOWN,
    eta: eta,
  };
};

export const requestSmsCode = () => {
  return {
    type: actionTypes.login.REQUEST_SMS_CODE,
  };
};

export const needsResetPassword = (data) => {
  return {
    type: actionTypes.login.NEEDS_RESET_PASSWORD,
    data: data,
  };
};
