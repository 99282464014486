import React, { useCallback } from 'react';
import MainEventBus from '../../utils/evbus';
import styled from 'styled-components';

const ZendeskChatElementDiv = styled.div({});

type StateProps = {
    bubbleId: string;
};

const ZendeskChatAgent = () => {
    const [state] = React.useState<StateProps>({
        bubbleId: 'zendesk-chat-' + Math.random().toString(36).substr(2, 9),
    });

    React.useEffect(() => {
        MainEventBus.on('SHOW_ZENDESK_CHAT', openChat);

        return () => {
            MainEventBus.remove('SHOW_ZENDESK_CHAT', openChat);
        };
    }, []);

    const openChat = () => {
        loadChat();
    };

    const isZenDeskLoaded = (): boolean => {
        return (
            // @ts-ignore
            (typeof window.zE?.version !== 'undefined' || typeof window.zE?.widget !== 'undefined')
            // @ts-ignore
            && typeof window.zE === 'function'
        );
    };

    const checkZendeskLoaded = (): Promise<boolean> => {
        return new Promise((resolve) => {
            const zdOnLoad = setInterval(() => {
                if (isZenDeskLoaded()) {
                    hideZELauncherBubble();
                    clearInterval(zdOnLoad);
                    resolve(true);
                }
            }, 50);
             // if is not loaded at all clear interval
            setTimeout(() => {
                clearInterval(zdOnLoad);
                resolve(false);
            }, 5000);
        });
    }

    const hideZELauncherBubble = () => {
        const launcherElement = document.getElementById('launcher');
        if (launcherElement) {
          launcherElement.style.display = 'none';
        }
    };
    const loadChat = async () => {
        const zendeskKey = window.config.customerSupport?.providers.zendesk.key;

        if (!zendeskKey) return;
        const el = document.getElementById('ze-snippet');
        if (!el) {
            const src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
            const lastScript = document.scripts[document.scripts.length - 1];
            const script = document.createElement('script');
            script.id = 'ze-snippet';
            script.async = true;
            script.src = src;
            // @ts-ignore
            script.onload = script.onreadystatechange = function () {
                // @ts-ignore
                if (this.readyState && this.readyState !== 'complete' && this.readyState !== 'loaded') {
                    return;
                }
            };
            lastScript?.parentElement?.insertBefore(script, lastScript.nextSibling);
        }

        const isZendeskLoaded = await checkZendeskLoaded();
        if (isZendeskLoaded) {
            // @ts-ignore
            window.zE('webWidget', 'updateSettings', {
                webWidget: {
                    offset: { horizontal: '0', vertical: '35px' }
                },
            });
            // @ts-ignore
            window.zE('webWidget', 'open');
        }
    };

    return <ZendeskChatElementDiv id={state.bubbleId} className={'zendesk-chat-element'}></ZendeskChatElementDiv>;
};

export default ZendeskChatAgent;
