const microsHost = window.config.templatizationApiUrl;

export const endpoints = {
  templatization: {
    config: window.config.templatizationApiUrl + '/config-api/templatization-config/list',
    meta: window.config.templatizationApiUrl + '/config-api/meta/list',
  },
  popupBonuses: {
    list: window.config.templatizationApiUrl + '/config-api/popup-bonuses/list',
  },
  multiBet: {
    list: window.config.templatizationApiUrl + '/config-api/multi-bet/list',
  },
  bonusRules: {
    list: window.config.templatizationApiUrl + '/config-api/bonus-rules/list',
  },
  freeBetsFilters: {
    list: window.config.templatizationApiUrl + '/config-api/free-bets-filters/list',
  },
  winnerFun: {
    config: window.config.templatizationApiUrl + '/config-api/winner-fun/config',
  },
  marketsSorter: {
    list: window.config.templatizationApiUrl + '/config-api/markets-sorter/list',
  },
  ukSettingsMarkets: {
    list: window.config.templatizationApiUrl + '/config-api/uk-markets/list',
  },
  config: {
    games: window.config.casinoApiUrl + '/config/games/',
    countries: window.config.front_url + '/app/countries',
    maintenance: window.config.templatizationApiUrl + '/config-api/maintenance/list',
  },
  hotspinsJackpot: {
    getGame: window.config.hotspinsJackpotApiUrl + '/player/jackpot-game/get',
  },
  jackpotsMeta: {
    list: window.config.jackpotMetaApiUrl + '/api/jackpot-meta-source/v1/jackpot/list',
  },
  rankSystems: {
    list: window.config.jackpotMetaApiUrl + '/api/loyalty/v1/player/details',
  },

  //https://micros-dev1.play-online.com/api/momentum-adapter/v1/player/award/get
  momentum: {
    awardGet: window.config.momentumApiUrl + '/player/award/get',
    awardAccept: window.config.momentumApiUrl + '/player/award/accept',
    awardReject: window.config.momentumApiUrl + '/player/award/reject',
  },

  airdrop: {
    status: window.config.airdropApiUrl + '/player/status',
    getCollected: window.config.airdropApiUrl + '/public/collected-items',
    collect: window.config.airdropApiUrl + '/player/item/collect',
  },

  challenges: {
    list: window.config.challengesApiUrl + '/tournaments/list',
    enroll: window.config.challengesApiUrl + '/tournament/enroll',
    claim: window.config.challengesApiUrl + '/tournament/claim',
    cancel: window.config.challengesApiUrl + '/tournament/cancel',
  },

  analytics: {
    event: window.config.analyticsApiUrl,
  },

  digitain: {
    taxEval: window.config.front_url + '/api/profile/calculate-taxes',
  },
};
