import { digitainConstants, prematchConstants } from '../actions/constants';

import { application as casinoAppConstants } from '../../../casino/store/actions/actionTypes';
import { produce, freeze } from 'immer';
import moment from 'moment';
import { sortArrayByKey, pushUnique, debug, objectCopyNewKeys } from '../../utils';
import nsoftCats from './categories.json';
import { prematchFetchMatch } from '../actions/prematch';

import * as nsoft from '../../utils/nsoft';
import { applyUpdate, isLive } from '../../utils/digitain';

//const nsoftCatsArray = Object.values(nsoftCats);
const MATCH_DATETIME_OFFSET = 2 * 60 * 1000; // milliseconds
const DEBUG = false;

let removed = {};

const removeMatch = (draft, idMatch, parentIdMatch) => {
  // check if we need to remove a period
  if (parentIdMatch) {
    if (!(parentIdMatch in draft.matches)) {
      //debug(`parent match ${parentIdMatch} not found`);
      return;
    }

    let pm = draft.matches[parentIdMatch];

    if (!(pm && pm.periods && Array.isArray(pm.periods))) {
      return;
    }

    for (let i = 0; i < pm.periods.length; i++) {
      if (pm.periods[i].idMatch === idMatch) {
        pm.periods.splice(i, 1);
        break;
      }
    }

    return;
  }

  const m = draft.matches[idMatch];

  if (!m) {
    //console.log("match", idMatch, "not found");
    return;
  }

  DEBUG && console.log('data', draft.data, 'match', m);

  if (!(m.idSport in draft.data)) {
    //console.log("sport", m.idSport, "not found in data");
    return;
  }

  if (!(m.idCategory in draft.data[m.idSport])) {
    //console.log("category", m.idCategory, "not found for sport", m.idSport);
    return;
  }

  if (!(m.idTournament in draft.data[m.idSport][m.idCategory])) {
    /*
    console.log(
      "tournament",
      m.idTournament,
      "not found for sport",
      m.idSport,
      "category",
      m.idCategory
    );
    */
    return;
  }

  if (!(m.idMatch in draft.data[m.idSport][m.idCategory][m.idTournament])) {
    /*
    console.log(
      "match",
      m.idMatch,
      "not found for sport",
      m.idSport,
      "category",
      m.idCategory,
      "tournament",
      m.idTournament
    );
    */
    return;
  }

  delete draft.data[m.idSport][m.idCategory][m.idTournament][m.idMatch];

  if (Object.keys(draft.data[m.idSport][m.idCategory][m.idTournament]).length === 0) {
    delete draft.data[m.idSport][m.idCategory][m.idTournament];
  }
  if (
    draft.tournamentsMeta &&
    draft.tournamentsMeta[m.idTournament] &&
    draft.tournamentsMeta[m.idTournament].numberOfEvents
  ) {
    if (--draft.tournamentsMeta[m.idTournament].numberOfEvents === 0) {
      delete draft.tournamentsMeta[m.idTournament];
    }
  }

  if (Object.keys(draft.data[m.idSport][m.idCategory]).length === 0) {
    delete draft.data[m.idSport][m.idCategory];
  }
  if (draft.categoriesMeta && draft.categoriesMeta[m.idCategory] && draft.categoriesMeta[m.idCategory].numberOfEvents) {
    if (--draft.categoriesMeta[m.idCategory].numberOfEvents === 0) {
      delete draft.categoriesMeta[m.idCategory];
    }
  }

  if (Object.keys(draft.data[m.idSport]).length === 0) {
    delete draft.data[m.idSport];
  }
  if (draft.sportsMeta && draft.sportsMeta[m.idSport] && draft.sportsMeta[m.idSport].numberOfEvents) {
    if (--draft.sportsMeta[m.idSport].numberOfEvents === 0) {
      delete draft.sportsMeta[m.idSport];
    }
  }

  if (m.idMatch in draft.wpMatches) {
    delete draft.wpMatches[m.idMatch];
  }

  if (m.idMatch in draft.waMatches) {
    delete draft.waMatches[m.idMatch];
  }

  if (m.idMatch in draft.wsMatches) {
    delete draft.wsMatches[m.idMatch];
  }

  if (!(m.idMatch in removed)) {
    removed[m.idMatch] = 0;
  }
  removed[m.idMatch]++;

  const mIdx = draft.matchesBySport[m.idSport].findIndex((am) => am.idMatch === m.idMatch);
  if (mIdx !== -1) {
    draft.matchesBySport[m.idSport].splice(mIdx, 1);
  }

  delete draft.matches[m.idMatch];
};

const updateStateData = (draft, smd) => {
  draft.matches[smd.idMatch] = smd;

  if (!(smd.idSport in draft.matchesBySport)) {
    draft.matchesBySport[smd.idSport] = [smd];
  } else {
    const mIdx = draft.matchesBySport[smd.idSport].findIndex((am) => am.idMatch === smd.idMatch);
    if (mIdx !== -1) {
      draft.matchesBySport[smd.idSport][mIdx] = smd;
    } else {
      draft.matchesBySport[smd.idSport].push(smd);
    }
  }

  if (!(smd.idSport + '' in draft.sports)) {
    draft.sports[smd.idSport] = {
      idSport: '' + smd.idSport,
      sportName: smd.sportName,
      sportShortName: smd.sportShortName,
      sportActive: true,
      sportType: 'REGULAR',
    };
  }
  if (!(smd.idSport + '' in draft.sportsMeta)) {
    draft.sportsMeta[smd.idSport] = {
      idSport: '' + smd.idSport,
      sportName: smd.sportName,
      sportShortName: smd.sportShortName,
      sportActive: true,
      sportType: 'REGULAR',
      numberOfEvents: 1,
    };
  } else {
    if (draft.sportsMeta[smd.idSport].numberOfEvents) {
      draft.sportsMeta[smd.idSport].numberOfEvents++;
    } else {
      draft.sportsMeta[smd.idSport].numberOfEvents = 1;
    }
  }

  if (!(smd.idCategory + '' in draft.categories)) {
    const sc = {
      idCategory: '' + smd.idCategory,
      categoryName: smd.categoryName,
      categoryShortName: smd.categoryShortName,
      //categoryPosition: c.position,
      categoryIsoName: smd.categoryIsoCode || 'UNK',
      categoryActive: true,
      idSport: '' + smd.idSport,
    };

    /*
    if (smd.idCategory in nsoftCats) {
      if (sc.categoryIsoName === 'UNK') {
        sc['categoryIsoName'] = nsoftCats[smd.idCategory].iso3;
      }
      sc['icon'] = nsoftCats[smd.idCategory].icon;
    }
    */

    draft.categories[smd.idCategory] = sc;
  }
  if (!(smd.idCategory + '' in draft.categoriesMeta)) {
    const sc = {
      idCategory: '' + smd.idCategory,
      categoryName: smd.categoryName,
      categoryShortName: smd.categoryShortName,
      //categoryPosition: c.position,
      categoryIsoName: smd.categoryIsoCode || 'UNK',
      categoryActive: true,
      idSport: '' + smd.idSport,
      numberOfEvents: 1,
    };

    /*
    if (smd.idCategory in nsoftCats) {
      if (sc.categoryIsoName === 'UNK') {
        sc['categoryIsoName'] = nsoftCats[smd.idCategory].iso3;
      }
      sc['icon'] = nsoftCats[smd.idCategory].icon;
    }
    */

    draft.categoriesMeta[smd.idCategory] = sc;
  } else {
    if (draft.categoriesMeta[smd.idCategory].numberOfEvents) {
      draft.categoriesMeta[smd.idCategory].numberOfEvents++;
    } else {
      draft.categoriesMeta[smd.idCategory].numberOfEvents = 1;
    }
  }

  if (!(smd.idTournament + '' in draft.tournaments)) {
    draft.tournaments[smd.idTournament] = {
      idTournament: '' + smd.idTournament,
      idCategory: smd.idCategory ? '' + smd.idCategory : null,
      idSport: '' + smd.idSport,
      //abbreviation: t.abbreviation,
      tournamentName: smd.tournamentName,
      tournamentShortName: smd.tournamentShortName,
      //tournamentPosition: t.position,
      tournamentActive: true,
      tournamentPrefix: '',
      brId: smd.sourceTournamentId ? smd.sourceTournamentId : null,
    };
  }
  if (!(smd.idTournament + '' in draft.tournamentsMeta)) {
    draft.tournamentsMeta[smd.idTournament] = {
      idTournament: '' + smd.idTournament,
      idCategory: smd.idCategory ? '' + smd.idCategory : null,
      idSport: '' + smd.idSport,
      //abbreviation: t.abbreviation,
      tournamentName: smd.tournamentName,
      tournamentShortName: smd.tournamentShortName,
      //tournamentPosition: t.position,
      tournamentActive: true,
      tournamentPrefix: '',
      brId: smd.sourceTournamentId ? smd.sourceTournamentId : null,
      numberOfEvents: 1,
    };
  } else {
    if (draft.tournamentsMeta[smd.idTournament].numberOfEvents) {
      draft.tournamentsMeta[smd.idTournament].numberOfEvents++;
    } else {
      draft.tournamentsMeta[smd.idTournament].numberOfEvents = 1;
    }
  }

  /*
  draft.bets[md.sportId] = draft.bets[md.sportId] || {};

  md.markets.forEach(mk => {
    if (!(md.marketId + "" in draft.bets[md.sportId])) {
      draft.bets[md.sportId][mk.marketId + ""] = {
        idBet: "" + mk.marketId,
        betDisplayId: "1",
        betName: mk.name,
        betShortName: mk.shortName,
        idSport: "" + md.sportId,
        betActive: !mk.collapsed,
        betPosition: mk.position,
        betGroupingEnabled: false,
        betType: "REGULAR",
        betOutcomes: {}
      };
    }

    const bd = draft.bets[md.sportId][mk.marketId + ""].betOutcomes;

    mk.outcomes.forEach(mko => {
      if (!(mko.marketOutcomeId + "" in bd)) {
        bd[mko.marketOutcomeId + ""] = {
          idBetOutcome: "" + mko.marketOutcomeId,
          betOutcomeName: mko.name,
          betOutcomeShortName: mko.name,
          betOutcomeActive: true
          //betOutcomePosition: o.position,
          //betOutcomeShortcut: "1"
        };
      }
    });
  });
  */

  if (!(smd.idSport in draft.data)) {
    draft.data[smd.idSport] = {};
  }

  if (!(smd.idCategory in draft.data[smd.idSport])) {
    draft.data[smd.idSport][smd.idCategory] = {};
  }

  if (!(smd.idTournament in draft.data[smd.idSport][smd.idCategory])) {
    draft.data[smd.idSport][smd.idCategory][smd.idTournament] = {};
  }

  draft.data[smd.idSport][smd.idCategory][smd.idTournament][smd.idMatch] = smd;

  if (smd.winnerPlus) {
    draft.wpMatches[smd.idMatch] = smd;
  }

  if (smd.winnerAdv) {
    draft.waMatches[smd.idMatch] = smd;
  }

  if (smd.winnerSpecial) {
    draft.wsMatches[smd.idMatch] = smd;
  }

  // schedule fetch match so we get the root event ID
  // NSoft add rootEventId, sourceTournamentId and categoryIsoName - no longer need this
  //action.asyncDispatch(prematchFetchMatch(smd.idMatch));
};

const mergeMatches = (draft, action, fromWs) => {
  DEBUG && console.log('tempMatches', action, fromWs);

  const mids = action.data ? Object.keys(action.data) : [];

  if (mids.length === 0) {
    return;
  }

  DEBUG && console.time('prematch mergeMatches');

  let sportsToSort = [];
  let toRemove = [];

  mids.forEach((mid) => {
    if (mid in removed) {
      //console.log(`${mid} was already removed: ${removed[mid]}`);
    }

    let smd = action.data[mid];

    if (isLive(smd)) {
      return;
    }

    // only process matches received from websocket if they already exist in state or they are in cached day intervals
    if (fromWs) {
      if (!(smd.idMatch in draft.matches)) {
        // not in state. check day cache intervals
        const ek = Object.keys(draft.eventsDayCache).find((ek) => {
          const [sIdSport, sDayStart] = ek.split('_');

          const idSport = parseInt(sIdSport, 10);
          const dayStart = parseInt(sDayStart, 10);

          if (
            smd.idSport === idSport &&
            dayStart <= smd.matchDateTime &&
            smd.matchDateTime <= dayStart + 24 * 3600 * 1000
          ) {
            return true;
          }

          return false;
        });

        if (!ek) {
          const st = moment(smd.matchDateTime);

          //debug(`ignored prematch ws match ${md.id} - ${md.sportId} - ${st} received from ws but not in state and not in day cache`);
          return;
        }

        //debug(`prematch ws match ${md.id} ${md.startsAt} not found in state but found for day cache ${ek}`)
      } else {
        //debug(`prematch ws match ${md.id} found in state`);
      }
    }

    if (!smd.active) {
      toRemove.push(mid);
      return;
    }

    // const [team1, team2] = md.name.split(" - ");

    // const team1Data = {
    // 	type: 1,
    // 	id: "tbd",
    // 	name: team1,
    // 	shortName: team1
    // };

    // const team2Data = {
    // 	type: 2,
    // 	id: "tdb",
    // 	name: team2,
    // 	shortName: team2
    // };

    // if ("teams" in md) {
    // 	Object.values(md.teams).forEach(td => {
    // 		if (td.name == team1Data.name) {
    // 			team1Data.id = td.id;
    // 			team1Data.shortName = td.shortName;
    // 		}

    // 		if (td.name == team2Data.name) {
    // 			team2Data.id = td.id;
    // 			team2Data.shortName = td.shortName;
    // 		}
    // 	});
    // }

    // md.totalMarkets = md.markets.length;
    // md.position = 9999;

    // md.competitors = [team1Data, team2Data];

    smd = nsoft.stdMatch(smd, draft);

    let pid = smd.parentIdMatch;

    if (pid) {
      if (!(pid in draft.matches)) {
        //debug(`parent match ${pid} not found`);
        return;
      }

      let pm = draft.matches[pid];

      let found = false;

      pm.periods = pm.periods || [];

      for (let i = 0; i < pm.periods.length; i++) {
        if (pm.periods[i].idMatch === smd.idMatch) {
          pm.periods[i] = smd;
          found = true;
          break;
        }
      }

      if (!found) {
        pm.periods.push(smd);

        pm.periods.sort((a, b) => {
          if (a.position !== b.position) {
            return a.position - b.position;
          }
          if (a.partTypeId !== b.partTypeId) {
            return a.partTypeId - b.partTypeId;
          }
          if (a.periodId !== b.periodId) {
            return a.periodId - b.periodId;
          }
          if (a.periodName !== b.periodName) {
            return a.periodName.localeCompare(b.periodName);
          }
          return 0;
        });
      }

      return;
    }

    if (smd.idMatch in draft.matches) {
      /*
      console.log(`prematch match ${md.id} already exists in state`);

      const diff = Object.keys(smd).filter(k => smd[k] !== draft.matches[md.id][k]);

      console.log("diff", diff);
      */

      /*
      diff.forEach(k => {
        console.log("k", k, "smd", smd[k], "m", draft.matches[md.id][k]);
      });
      */

      if (draft.matches[smd.idMatch].matchDateTime !== smd.matchDateTime) {
        draft.matches[smd.idMatch].matchDateTime = smd.matchDateTime;
        pushUnique(sportsToSort, smd.idSport);
      }

      draft.matches[smd.idMatch].DDTTCKS = smd.DDTTCKS;
      draft.matches[smd.idMatch].matchBets = smd.matchBets;
      draft.matches[smd.idMatch].periods = smd.periods;

      // if Ultra odds are loaded later (not as first market) then the match is not detected as
      // being Ultra (winnerAdv). we need to upgrate to winnerAdv when we have Ultra odds
      if (!draft.matches[smd.idMatch].winnerAdv && smd.winnerAdv) {
        draft.waMatches[smd.idMatch] = draft.matches[smd.idMatch];
      }

      draft.matches[smd.idMatch].winnerPlus = smd.winnerPlus;
      draft.matches[smd.idMatch].winnerAdv = smd.winnerAdv;
      draft.matches[smd.idMatch].winnerSpecial = smd.winnerSpecial;

      if (smd._loaded) {
        draft.matches[smd.idMatch]['_loaded'] = true;
      }
    } else {
      DEBUG && console.log(`new prematch match ${smd.idMatch}`);

      pushUnique(sportsToSort, smd.idSport);

      updateStateData(draft, smd);
    }

    //console.log("data after add", draft.data);
  });

  sportsToSort.forEach((k) => sortArrayByKey(draft.matchesBySport[k], 'matchDateTime'));

  DEBUG && console.timeEnd('prematch mergeMatches');

  toRemove.forEach((mid) => removeMatch(draft, mid));
};

const prematchReducer = (state = nsoft.makeInitialState(), action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case casinoAppConstants.REINITIALIZE: {
        draft.eventsDayCache = {};
        break;
      }
      case prematchConstants.SET_MINI_STATE: {
        //console.log("prematch set mini state", action.data);

        /*
        if (!draft.fullStateLoaded) {
          draft.sports = action.data.sports;
          draft.sportsMeta = action.data.sports;
          draft.categories = action.data.categories;
          draft.categoriesMeta = action.data.categories;
          draft.tournaments = action.data.tournaments;
          draft.tournamentsMeta = action.data.tournaments;
          draft.bets = action.data.bets;
          draft.matches = action.data.matches;
          if (action.data.marketGroups) {
            draft.marketGroups = action.data.marketGroups;
          }

          buildData(draft, true);
        }
        */

        let ck = 0;
        let skeys = ['sports', 'categories', 'tournaments', 'bets', 'matches', 'marketGroups'];

        for (const sk of skeys) {
          if (!(sk in action.data && action.data[sk])) {
            continue;
          }

          ck = ck + objectCopyNewKeys(draft[sk], freeze(action.data[sk]));
        }

        if (ck > 0) {
          debug(`ck = ${ck}, rebuilding state`);
          nsoft.buildData(draft, true);
        }

        break;
      }
      case prematchConstants.SET_STATE: {
        debug('prematch set state', action);
        nsoft.buildState(draft, action);

        // set for which days we received events
        for (let i = 0; i < action.dayInfo.days; i++) {
          const dt = action.dayInfo.startDate + i * 24 * 3600 * 1000;

          draft.eventsDayCache[`${action.dayInfo.idSport}_${dt}`] = 1;
        }

        draft.fullStateLoaded = true;
        draft.loadingState = null;

        break;
      }
      case prematchConstants.COPY_STATE: {
        //console.log("prematch copy state", action.data);

        /*
        draft.sports = action.data.sports;
        draft.categories = action.data.categories;
        draft.tournaments = action.data.tournaments;
        draft.bets = action.data.bets;
        draft.wpBets = action.data.wpBets;
        draft.waBets = action.data.waBets;
        draft.data = action.data.data;
        draft.matches = action.data.matches;
        draft.matchesBySport = action.data.matchesBySport;
        draft.waMatches = action.data.waMatches;
        draft.wpMatches = action.data.wpMatches;
        draft.topOffer = action.data.topOffer;
        */

        Object.entries(action.data).forEach(([k, v]) => (draft[k] = v));

        // set for which days we received events
        for (let i = 0; i < action.dayInfo.days; i++) {
          const dt = action.dayInfo.startDate + i * 24 * 3600 * 1000;

          draft.eventsDayCache[`${action.dayInfo.idSport}_${dt}`] = 1;
        }

        draft.fullStateLoaded = true;
        draft.loadingState = null;

        break;
      }
      case prematchConstants.MERGE_STATE: {
        debug('merge state', action.data);

        /*
        draft.sports = action.data.sports;
        draft.categories = action.data.categories;
        draft.tournaments = action.data.tournaments;
        draft.bets = action.data.bets;
        draft.wpBets = action.data.wpBets;
        draft.waBets = action.data.waBets;
        draft.data = action.data.data;
        draft.matches = action.data.matches;
        draft.matchesBySport = action.data.matchesBySport;
        draft.waMatches = action.data.waMatches;
        draft.wpMatches = action.data.wpMatches;
        draft.topOffer = action.data.topOffer;
        */

        Object.entries(action.data).forEach(([k, v]) => {
          // debug("merge state", k, v);

          // for matches, preserve flags
          try {
            if (k === 'matches' && draft[k]) {
              for (const idMatch in draft[k]) {
                if (idMatch in v) {
                  v[idMatch].winnerAdv = v[idMatch].winnerAdv || draft[k][idMatch].winnerAdv;
                  v[idMatch].winnerPlus = v[idMatch].winnerPlus || draft[k][idMatch].winnerPlus;
                  v[idMatch].winnerSpecial = v[idMatch].winnerSpecial || draft[k][idMatch].winnerSpecial;
                }
              }
            }
          } catch (err) { }

          // IMPORTANT: when meta we need to fully overwrite it not merge it with the existent stuff
          const override = ['sportsMeta', 'categoriesMeta', 'tournamentsMeta', 'tournamentGroupsMeta'];
          if (override.includes(k)) {
            draft[k] = v;
          } else {
            if (k === 'matches') {
              const newMatches = {};

              Object.keys(v).forEach((idMatch) => {
                if (!draft.matches[idMatch]?._loaded) {
                  newMatches[idMatch] = v[idMatch];
                }
              });

              draft[k] = Object.assign({}, draft[k] || {}, newMatches);
            } else {
              draft[k] = Object.assign({}, draft[k] || {}, v);
            }
          }
        });
        nsoft.buildData(draft, false);

        // set for which days we received events
        if (action.dayInfo) {
          for (let i = 0; i < action.dayInfo.days; i++) {
            const dt = action.dayInfo.startDate + i * 24 * 3600 * 1000;

            draft.eventsDayCache[`${action.dayInfo.idSport}_${dt}`] = 1;
          }
        }

        draft.fullStateLoaded = true;
        draft.loadingState = null;

        break;
      }
      case prematchConstants.SHOW_TOP_OFFER: {
        draft.showTopOffer = action.show;
        break;
      }
      case prematchConstants.ADD_MATCHES: {
        //console.log("prematch add matches", action.data.data);

        /*
        if (action.data.data !== null) {
          tempMatches[action.data.data.id] = action.data.data;
        }
        */

        break;
      }
      case prematchConstants.MERGE_MATCHES: {
        mergeMatches(draft, action);
        break;
      }
      case prematchConstants.CLEAR_EXPIRED: {
        console.log('starting prematch cleanup');

        //const now = new Date().getTime();
        const now = moment().utc().valueOf();

        const ms = Object.keys(draft.matches);

        ms.forEach((mid) => {
          const m = draft.matches[mid];
          if (m.matchDateTime + MATCH_DATETIME_OFFSET < now) {
            DEBUG &&
              console.log(
                'removed expired',
                mid,
                m.team1Name,
                m.team2Name,
                draft.sports[m.idSport].sportName,
                draft.categories[m.idCategory].categoryName,
                draft.tournaments[m.idTournament].tournamentName,
                //new Date(draft.matches[mid].matchDateTime),
                //new Date(now)
                moment(m.matchDateTime).utc().format(),
                moment(now).utc().format(),
              );
            removeMatch(draft, mid);
            return;
          }

          // check if bets should be enabled
          if (m.DDTTCKS && m.DDTTCKS < now) {
            m.bettingStatus = false;
          }
        });
        break;
      }
      case prematchConstants.SET_MATCH: {
        debug('set match', action.data);

        const idMatch = action.data.idMatch + '';

        /*
        if (!(idMatch in draft.matches)) {
          debug(`prematch ${idMatch} not found`);
        } else {
          if (!action.data.rootEventId) {
            debug(`rootEventId not found`, action.data);
          } else {
            draft.matches[idMatch].brId = action.data.rootEventId;
          }
        }
        */

        mergeMatches(draft, {
          data: {
            [idMatch]: action.data,
          },
        });

        break;
      }
      case prematchConstants.SET_MATCHES: {
        debug('set matches', action.data);

        const data = {};

        action.data.forEach((m) => {
          data[m.idMatch] = m; // changed from id to idMatch
        });

        mergeMatches(draft, { data });

        break;
      }
      case prematchConstants.SET_LOADING_STATE: {
        draft.loadingState = action.data;
        break;
      }
      case digitainConstants.QUEUE_UPDATES: {
        // debug("live update", action.data);
        draft.updatesQueue = draft.updatesQueue.concat(action.data);
        break;
      }
      case digitainConstants.MERGE_QUEUE: {
        if (draft.updatesQueue.length) {
          //debug('prematch merge updates');
          applyUpdate(
            draft,
            (draft, m) => {
              m.match.fromWs = true;
              mergeMatches(draft, { data: { [m.match.idMatch]: m.match } });
            },
            updateStateData,
            removeMatch,
          );
        }
        break;
      }
      case digitainConstants.CLEAR_QUEUE: {
        //debug('clear updates');
        draft.updatesQueue = [];
        break;
      }
      case digitainConstants.PREMATCH_UPDATES: {
        if (action.data && draft.fullStateLoaded && draft.loadingState === null) {
          //debug('prematch updates');
          applyUpdate(
            draft,
            (draft, m) => {
              // m.match.fromWs = true;
              mergeMatches(draft, { data: { [m.match.idMatch]: m.match } }, true);
            },
            removeMatch,
            updateStateData,
            action.data,
          );
        }
        break;
      }
      case 'BETS_DEBUG_UPDATE_PREMATCH_ODD': {
        console.log('BETS_DEBUG_UPDATE_PREMATCH_ODD', action.data);

        const { idMatch, oddId, oddValue } = action.data;

        if (idMatch in draft.matches) {
          const m = draft.matches[idMatch];

          if (!m.matchBets) {
            console.error('matchBets not found', m);
            break;
          }

          let found = false;

          for (const mb of m.matchBets) {
            if (mb.mbOutcomes) {
              for (const bo of mb.mbOutcomes) {
                if (bo.idMbo === oddId + '') {
                  bo.mboOddValue = oddValue;
                  console.log(`odd ${oddId} updated to ${oddValue} for match ${idMatch}`);
                  found = true;
                  break;
                }
              }
            }
          }

          if (!found) {
            console.error(`odd ${oddId} not found for match ${idMatch}`);
          }
        } else {
          console.error('match not found', idMatch);
        }

        // _store.dispatch({ type: "BETS_DEBUG_UPDATE_PREMATCH_ODD", idMatch: xxx, oddId: xxx, oddValue: xxx });

        break;
      }
      default:
        break;
    }
  });

export default prematchReducer;
