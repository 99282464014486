import { logEvent } from '../events/logEvent';
import { FeatureCategory, FeatureSubcategory, ProductData, EventData, FeatureLocation } from '../types/eventData';

// Error-specific product data
const ERROR_PRODUCT_DATA: ProductData = {
  feature_used: 'error_tracking',
  feature_category: FeatureCategory.System,
  feature_subcategory: FeatureSubcategory.SystemError,
  feature_location: FeatureLocation.Unspecified,
};

// Utility function to extract useful information from error objects
const normalizeError = (error: Error | string, context?: string) => {
  const errorObject = error instanceof Error ? error : new Error(error);

  return {
    message: errorObject.message,
    stack: errorObject.stack,
    source: (error as any)?.filename || undefined,
    lineNumber: (error as any)?.lineNumber || undefined,
    columnNumber: (error as any)?.columnNumber || undefined,
    context,
  };
};

// Initialize error tracking
// not yet used or enabled
export const initializeErrorTracking = () => {
  // Store original console methods
  const originalConsole = {
    error: console.error,
    warn: console.warn,
  };

  // Override console.error
  console.error = (...args: any[]) => {
    // Call original console.error
    originalConsole.error.apply(console, args);

    // Track the error
    const errorMessage = args.map((arg) => (arg instanceof Error ? arg.message : String(arg))).join(' ');

    const error = args.find((arg) => arg instanceof Error) || errorMessage;
    const errorData = normalizeError(error, 'console.error');

    logEvent(
      null,
      {
        ...ERROR_PRODUCT_DATA,
        feature_used: 'console_error',
      },
      {
        error_type: 'console_error',
        ...errorData,
      },
    );
  };

  // Override console.warn (optional)
  console.warn = (...args: any[]) => {
    // Call original console.warn
    originalConsole.warn.apply(console, args);

    // Track the warning
    const warningMessage = args.map((arg) => (arg instanceof Error ? arg.message : String(arg))).join(' ');

    const warning = args.find((arg) => arg instanceof Error) || warningMessage;
    const warningData = normalizeError(warning, 'console.warn');

    logEvent(
      null,
      {
        ...ERROR_PRODUCT_DATA,
        feature_used: 'console_warn',
      },
      {
        error_type: 'console_warn',
        ...warningData,
      },
    );
  };

  // Add global error handler
  window.addEventListener('error', (event: ErrorEvent) => {
    event.preventDefault();

    const errorData = normalizeError(event.error || event.message, 'uncaught error');

    logEvent(
      event,
      {
        ...ERROR_PRODUCT_DATA,
        feature_used: 'uncaught_error',
      },
      {
        error_type: 'uncaught_error',
        source_file: event.filename,
        line_number: event.lineno,
        column_number: event.colno,
        ...errorData,
      },
    );

    // Return false to prevent the firing of the default event handler
    return false;
  });

  // Add unhandled promise rejection handler
  window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
    event.preventDefault();

    const error = event.reason instanceof Error ? event.reason : new Error(String(event.reason));
    const errorData = normalizeError(error, 'unhandled promise rejection');

    logEvent(
      null,
      {
        ...ERROR_PRODUCT_DATA,
        feature_used: 'unhandled_rejection',
      },
      {
        error_type: 'unhandled_promise_rejection',
        ...errorData,
      },
    );
  });
};

// Custom error throwing utility
export const throwAndTrackError = (error: Error | string, context?: string) => {
  const errorData = normalizeError(error, context);

  logEvent(
    null,
    {
      ...ERROR_PRODUCT_DATA,
      feature_used: 'tracked_error',
    },
    {
      error_type: 'thrown_error',
      ...errorData,
    },
  );

  throw error instanceof Error ? error : new Error(String(error));
};
