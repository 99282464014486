import React from 'react';
import styled from 'styled-components';

import { useAppSelector, useAppDispatch } from '../../../store';
import { useTranslation } from 'react-i18next';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { ConfigReducer as AllCountriesReducer, setNewCountry } from '@/store/slices/allCountries';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import Flags from '@/modules/lotto/assets/countries';
import { cloneDeep } from 'lodash';
import ResponseErrorMessages from '@/modules/casino/store/errors/ResponseErrorMessages.js';

import './index.scss';
import Axios from 'axios';

type CountrySelectorProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    countryCode: string;
    dsType: string;
    onValidation: any;
  };
};

type ModuleStateProps = {
  currentCountry: any;
  oldCountry: string;
  isValid: boolean;
  isOpen: boolean;
  countriesList: any;
  errorMessage: string;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const CountrySelector = (componentProps: CountrySelectorProps) => {
  let props = componentProps;
  const { t } = useTranslation();
  const allCountries = useAppSelector((state) => state.allCountries as AllCountriesReducer);
  const dataElementContext = React.useContext(DataElementContext);

  [props] = processComponentProps(props, dataElementContext);

  const dispatch = useAppDispatch();

  const [state, setState] = React.useState<ModuleStateProps>({
    currentCountry: '',
    oldCountry: '',
    isValid: false,
    isOpen: false,
    countriesList: [],
    errorMessage: '',
  });

  React.useEffect(() => {
    if (allCountries?.countries && allCountries?.currentCountry) {
      const countries = cloneDeep(allCountries.countries) ?? [];

      countries.forEach((country) => {
        country.countryUrl = (Flags as { [id: string]: string })[country.countryCode.toLowerCase()] ?? '';
      });

      const selectedCountry = countries.find((country) => country.countryCode === allCountries.currentCountry);

      setState((prevState) => ({
        ...prevState,
        currentCountry: selectedCountry,
        countriesList: { countries },
      }));
    }
  }, [allCountries]);

  React.useEffect(() => {
    if (props?.properties?.countryCode) {
      const oldCountry = state.currentCountry;

      if (!(state?.countriesList?.countries && Array.isArray(state?.countriesList?.countries))) return;

      const selectedCountry = state?.countriesList?.countries.find(
        (country: any) => country.countryCode === props?.properties?.countryCode?.toUpperCase?.(),
      );
      setState((prevState) => ({
        ...prevState,
        currentCountry: selectedCountry,
        oldCountry: oldCountry?.countryCode,
      }));
    }
  }, [props?.properties?.countryCode]);

  const onValidatePhone = async (value: string) => {
    if (value) {
      dispatch({ type: 'allCountries/setValidationPhoneErrorLoading', payload: true });
      try {
        const response = await Axios.get(window.config.front_url + '/app/validatePhone', {
          params: {
            phoneNumber: '+' + state?.currentCountry?.phoneCode + value?.replace(/\s/g, ''),
            countryCode: state.currentCountry.countryCode,
          },
        });
        console.log('Validate phone', { isValid: response.data.result.isValid, response });
        if (response.data.result) {
          const isValid = response.data.result.isValid;

          setState((prevState) => ({
            ...prevState,
            isValid: isValid,
            errorMessage: isValid === true ? '' : t('Invalid phone number.'),
          }));
          if (typeof props?.properties?.onValidation === 'function') props.properties.onValidation(isValid);

          dispatch({ type: 'allCountries/setValidationPhoneError', payload: !isValid });
          dispatch({
            type: 'allCountries/setValidationPhoneMessage',
            payload: isValid === true ? '' : t('Invalid phone number.'),
          });
        } else {
          setState((prevState) => ({
            ...prevState,
            isValid: false,
            errorMessage: ResponseErrorMessages.get(1),
          }));

          dispatch({ type: 'allCountries/setValidationPhoneError', payload: false });
          dispatch({
            type: 'allCountries/setValidationPhoneMessage',
            payload: ResponseErrorMessages.get(1),
          });
        }

        dispatch({ type: 'allCountries/setValidationPhoneErrorLoading', payload: false });
      } catch (err: any) {
        dispatch({ type: 'allCountries/setValidationPhoneErrorLoading', payload: false });
        // const errResp = { error: err.toString() };
        console.log('Validate phone error', err);
      }
    }
  };

  const onChangeCountry = (e: React.MouseEvent<HTMLElement>) => {
    const oldCountry = state.currentCountry ?? '';
    const value = e.currentTarget?.dataset?.country ?? null;
    let selectedCountry: any = '';

    if (value) selectedCountry = state?.countriesList?.countries.find((country: any) => country.countryCode === value);

    // console.log('Country Selector', { e, value, selectedCountry });

    setState((prevState) => ({
      ...prevState,
      currentCountry: selectedCountry,
      oldCountry: oldCountry?.countryCode,
    }));

    if (selectedCountry) {
      dispatch({ type: 'allCountries/setNewCountry', payload: selectedCountry.countryCode });
    }
  };

  const onToggleCountrySelector = () => {
    setState((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  // React.useEffect(() => {
  //   console.log('Country Selector', { valid: state.isValid, errorMessage: state.errorMessage });

  //   // if (!state.isValid) {
  //   //   dispatch({ type: 'allCountries/setValidationPhoneError', payload: state.isValid });
  //   // }

  //   dispatch({ type: 'allCountries/setValidationPhoneError', payload: state.isValid });

  //   if (state.errorMessage) {
  //     dispatch({ type: 'allCountries/setValidationPhoneMessage', payload: state.errorMessage });
  //   } else {
  //     dispatch({ type: 'allCountries/setValidationPhoneMessage', payload: '' });
  //   }
  // }, [state]);

  const contextValue = {
    countries: state.countriesList.countries,
    currentCountry: state.currentCountry,
    oldCountry: state.oldCountry,
    onValidatePhone: onValidatePhone,
    isValid: state.isValid,
    errorMessage: state.errorMessage,
    parentContext: dataElementContext,

    onToggleCountrySelector,
    onChangeCountry,
  };

  React.useEffect(() => {
    console.log('Country Selector', { props, contextValue, selectedCountry: state.currentCountry, state });
  }, [state.currentCountry, state.errorMessage, state.isValid]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default CountrySelector;
