import { PageData } from '@/analytics';

/**
 * Returns the full URL of the current page.
 */
export const getPageUrl = (): string => {
  return window.location.href;
};

/**
 * Returns the referrer URL, i.e., the URL of the previous page.
 */
export const getReferrer = (): string => {
  return document.referrer;
};

/**
 * Returns the language of the user's browser.
 */
export const getPageLanguage = (): string => {
  return navigator.language || '';
};

export const getViewport = (): { width: number; height: number } => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

function getScrollDepth() {
  // Get the scroll position of the page
  const scrollY = window.scrollY;
  const pageHeight = document.documentElement.scrollHeight;
  const viewportHeight = window.innerHeight;

  // Calculate the scroll depth as a percentage of the page height
  const scrollDepth = (((scrollY + viewportHeight) / pageHeight) * 100).toFixed(2);

  return scrollDepth + '%';
}

// function getVisitHistory() {
//   // Get the visit history from the session storage
//   const visitHistory = localStorage.getItem('visit_history');

//   // Parse the visit history as an array
//   const visitHistoryArray = visitHistory ? JSON.parse(visitHistory) : [];

//   return visitHistoryArray;
// }

/**
 * Aggregates page information into a single object.
 */
export const getPageData = (referrer: string = getReferrer()): PageData => {
  return {
    url: getPageUrl(),
    title: document.title,
    referrer: referrer,
    language: getPageLanguage(),
    viewport: getViewport(),
    scroll_depth: getScrollDepth(),
    // visit_history: getVisitHistory()
  };
};
