let config = {
  demoLotto: 'https://www.cnbfbtlotto.com/winnerro/games/launch?currency=RON&game_id=lottery&language=RO&mode=demo',
};

if (
  (import.meta.env.MODE === 'development' && typeof window !== 'undefined' && window.config && window.config.environment !== 'production') ||
  (typeof window !== 'undefined' && window.config && window.config.environment !== 'production')
) {
  config = {
    demoLotto: 'https://www.cnbfbtlotto.com/stage/winnerro/games/launch?currency=RON&game_id=lottery&language=RO&mode=demo',
  };
}

export default config;