import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import PhoneNumberValidator from '../../../modules/casino/utils/PhoneNumberValidator';
import { checkPassword, clearPasswordCache, changePassword } from '../../../modules/casino/store/actions/profile';
import ResponseErrorMessages from '../../../modules/casino/store/errors/ResponseErrorMessages';
import PasswordValidator from '@/utils/PasswordValidator';

import './index.scss';

type ChangePasswordProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type ModuleStateProps = {
  isOpen: boolean;
  currentStep: string;
  hasErrors: boolean;
  error: string;
  showPassword: boolean;
  password: string;
  showConfirmPassword: boolean;
  confirmPassword: string;
  currentPassword: string;
  showFinalStepPopup: boolean;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const IS_AUTH_PHONE_ONLY =
  window?.config?.authenticationSettings?.enablePhone === '1' &&
  window?.config?.authenticationSettings?.enableEmail === '0';

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const ChangePassword = (componentProps: ChangePasswordProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.profile);

  const initialState = {
    isOpen: false,
    currentStep: 'step1',
    hasErrors: false,
    error: '',
    showPassword: false,
    password: '',
    showConfirmPassword: false,
    confirmPassword: '',
    currentPassword: '',
    showFinalStepPopup: false,
  };

  const [state, setState] = React.useState<ModuleStateProps>(initialState);

  const validator: any = PasswordValidator(state.password);

  // flow with Final Step Popup displayed
  const onNextStepWithFinalStepPopup = () => {
    if (IS_AUTH_PHONE_ONLY && profile?.phone) {
      dispatch(checkPassword(state?.password, profile?.phone));
    } else {
      dispatch(checkPassword(state?.password, null));
    }

    setState((v) => ({
      ...v,
      showFinalStepPopup: true,
    }));
  };

  const onOpenFinalStepPopup = () => {
    dispatch(changePassword(state.currentPassword, state.password, 'password', profile?.phone));
    setState((v) => ({
      ...v,
      showFinalStepPopup: true,
    }));
  };

  const contextValue = {
    ...state,
    loading: profile.loading,
    loadingCheckPassword: profile.loadingCheckPassword,
    onChangePassword: (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.target ? (e.target as HTMLInputElement).value : '';

      if (window.config.password.restrictSpecialCharacters === '1' && value.match(/[^A-Za-z0-9]/g)) {
        console.log('Special characters are not allowed');
        return;
      }

      setState((v) => ({
        ...v,
        password: value,
        hasErrors: false,
        error: '',
      }));
    },
    onClickShowPassword: () => {
      setState((v) => ({
        ...v,
        showPassword: !v.showPassword,
      }));
    },
    // flow with Final Step Popup displayed
    onOpenFinalStepPopup,
    onNextStepWithFinalStepPopup,
    // flow without Final Step Popup displayed
    onNextStep: () => {
      if (IS_AUTH_PHONE_ONLY && profile?.phone) {
        dispatch(checkPassword(state?.password, profile?.phone));
      } else {
        dispatch(checkPassword(state?.password, null));
      }
    },
    onFinish: () => {
      dispatch(changePassword(state.currentPassword, state.password, 'password', profile?.phone));
    },

    onChangeConfirmPassword: (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.target ? (e.target as HTMLInputElement).value : '';

      setState((v) => ({
        ...v,
        confirmPassword: value,
        hasErrors: false,
        error: '',
      }));
    },
    onClickShowConfirmPassword: () => {
      setState((v) => ({
        ...v,
        showConfirmPassword: !v.showConfirmPassword,
      }));
    },

    onToggle: (e: React.MouseEvent<HTMLElement>) => {
      dispatch(clearPasswordCache());
      setState((v) => ({
        ...initialState,
        isOpen: !v.isOpen,
      }));
    },
    passwordStrength: validator.score,
    passwordShortStrengthLabel: validator.labels.short,
    passwordLongStrengthLabel: validator.labels.long,
    passwordMessage: validator.message,
    passwordAccepted: validator.success,
    passwordConditionsNotMet: validator.conditionsNotMet,
  };

  React.useEffect(() => {
    if (
      state.isOpen &&
      state.currentStep === 'step1' &&
      !profile.loadingCheckPassword &&
      profile.passwordIsValid !== null
    ) {
      if (!profile.passwordIsValid) {
        setState((v) => ({
          ...v,
          hasErrors: true,
          // error: 'Wrong password, try again!',
          error: ResponseErrorMessages.get(profile.errorCode),
        }));
      } else {
        dispatch(clearPasswordCache());
        setState((v) => ({
          ...initialState,
          isOpen: true,
          currentStep: 'step2',
          currentPassword: v.password,
        }));
      }
    } else if (
      state.isOpen &&
      state.currentStep === 'step2' &&
      !profile.loading &&
      profile.receivedChangePassword !== null
    ) {
      if (Array.isArray(profile.receivedChangePassword) && profile.receivedChangePassword.length === 0) {
        // done success
        dispatch(clearPasswordCache());
        setState({
          ...initialState,
          isOpen: state.showFinalStepPopup ?? false,
          showFinalStepPopup: state.showFinalStepPopup ?? false,
          currentStep: state.showFinalStepPopup ? 'final' : 'step1',
        });
      } else {
        setState((v) => ({
          ...initialState,
          isOpen: true,
          currentStep: 'step1',
          hasErrors: true,
          // error: profile.receivedChangePassword.ResponseMessage,
          error: ResponseErrorMessages.get(profile.errorCode),
        }));
      }
    }
  }, [
    state.currentStep,
    state.isOpen,
    profile.loading,
    profile.loadingCheckPassword,
    profile.passwordIsValid,
    profile.receivedChangePassword,
  ]);

  if (state.currentStep === 'step1' && !state.password) {
    contextValue.hasErrors = true;
  } else if (state.currentStep === 'step2' && !state.password) {
    // no error but a warning:
    contextValue.error = validator.message;
  } else if (state.currentStep === 'step2' && state.password) {
    // 6 or config.min ?
    if (validator && validator.success && state.password === state.currentPassword) {
      contextValue.hasErrors = true;
      contextValue.error = 'New password must be different from the previous one';
    } else if (
      validator &&
      validator.success &&
      state.password &&
      state.confirmPassword &&
      state.password !== state.confirmPassword
    ) {
      contextValue.hasErrors = true;
      contextValue.error = 'Passwords are different';
    } else {
      if (validator && validator.success === false) {
        contextValue.hasErrors = true;
        contextValue.error = validator.message;
      }
    }
  }

  console.log('Debug Change Password: ', { contextValue, state, profile, validator, step: state.currentStep });

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default ChangePassword;
