import { FeatureCategory, FeatureLocation, FeatureSubcategory } from '../types/eventData';
import { logEvent } from './logEvent';

// Helper function for logging the event
export const logVisitEvent = (
  event: any,
  routeState: {
    pages: number;
    currentPath: string;
    pagesHistory: never[];
  },
) => {
  try {
    // Still do this outside since it's core functionality
    // that we might want for other purposes
    localStorage.setItem('visit_id', generateVisitId());

    // Bundle all analytics-specific work into the logEvent call
    // so it only runs if analytics are enabled
    logEvent(
      event,
      {
        feature_used: 'visit',
        feature_category: FeatureCategory.NotApplicable,
        feature_subcategory: FeatureSubcategory.NotApplicable,
        feature_location: FeatureLocation.NotApplicable,
      },
      {},
      localStorage.getItem('visit_id') || '',
      routeState.pagesHistory.length > 1 ? routeState.pagesHistory[1] : undefined,
    );
  } catch (error) {
    console.error('Error logging event:', error);
  }
};

/**
 * Generates a new visit ID.
 *
 * @returns A string representing a new visit ID.
 */
const generateVisitId = (): string => {
  // Generate a random session ID
  const sessionId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  return sessionId;
};
