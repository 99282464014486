import React, { useCallback } from 'react';
import MainEventBus from '../../utils/evbus';
import styled from 'styled-components';
import { useAppSelector } from '@/store';
import Utils from '@/modules/casino/utils/Utils';
import { useMediaQuery } from 'usehooks-ts';

const LiveAgentElementDiv = styled.div({
  position: 'fixed',
  zIndex: '9999',
  div: {
    div: {
      bottom: '45px',
    },
  },
});

type StateProps = {
  scriptId: string;
  bubbleId: string;
  renderer: string;
  loaded: boolean;
  initialized: boolean;
  chat?: any;
  chatButton?: any;
};

const LiveAgent = () => {
  const haveMobileNav = useMediaQuery('(max-width: 900px)');
  const [state, setState] = React.useState<StateProps>({
    scriptId: '',
    bubbleId: 'live-agent-' + Math.random().toString(36).substr(2, 9),
    renderer: '',
    loaded: false,
    initialized: false,
    chat: false,
    chatButton: false,
  });
  const profile = useAppSelector((state) => state.profile);
  const { language } = useAppSelector((state) => state.application);

  React.useEffect(() => {
    MainEventBus.on('SHOW_LIVE_CHAT', showChat);
    // MainEventBus.on('OPEN_LIVE_CHAT_WINDOW', openChatWindowHandler);

    return () => {
      MainEventBus.remove('SHOW_LIVE_CHAT', showChat);
      // MainEventBus.remove('OPEN_LIVE_CHAT_WINDOW', openChatWindowHandler);
    };
  }, []);

  React.useEffect(() => {
    loadChat();
  }, [state.scriptId]);

  React.useEffect(() => {
    if (state.loaded) {
      // @ts-ignore
      setState((v) => ({ ...v, chat: window.LiveAgent }));
    }
  }, [state.loaded]);
  React.useEffect(() => {
    if (state.chat) {
      setVisitorValues();
      showBubble();
    }
  }, [state.chat, profile, language]);

  const openChatWindow = useCallback(
    (data: any) => {
      console.log('openChatWindow', data, state.chatButton);
      if (state.chatButton) {
        state.chatButton.onClick();
      }
    },
    [state.chatButton],
  );

  const openChatWindowHandler = (data: any) => {
    openChatWindow(data);
  };

  const showBubble = () => {
    document.getElementById(state.bubbleId + '-inner')?.remove();

    const bubbleInnerElement = document.createElement('div');
    bubbleInnerElement.id = state.bubbleId + '-inner';

    let bubbleElement = document.getElementById(state.bubbleId);
    if (!bubbleElement) {
      // create bubble element
      bubbleElement = document.createElement('div');
      bubbleElement.id = state.bubbleId;
    }

    bubbleElement.innerHTML = '';
    bubbleElement.appendChild(bubbleInnerElement);

    // @ts-ignore
    const button = window.LiveAgent.createButton(state.renderer, bubbleInnerElement);
    button.oldOnClick = button.onClick;
    button.oldOnCloseFunction_ = button.onCloseFunction_;

    button.onClick = function () {
      button.oldOnClick();
      window.parent.postMessage({ type: 'chatOpened' }, '*');
    };
    button.onCloseFunction_ = function () {
      button.oldOnCloseFunction_();
      window.parent.postMessage({ type: 'chatClosed' }, '*');
    };

    setState((v) => ({
      ...v,
      chatButton: button,
    }));
  };

  const getPlatform = () => {
    switch (window.config.platformType) {
      case '1':
        return 'Web';
      case '2':
        return 'Mobile';
      case '3':
        return 'iOS';
      case '4':
        return 'Android';
      default:
        return 'Unknown';
    }
  };

  const setVisitorValues = () => {
    // @ts-ignore
    const chat = window.LiveAgent;
    if (profile) {
      // state.chat.setVisitorValues({
      //   name: profile.first_name + ' ' + profile.last_name,
      //   email: profile.email,
      //   language: language,
      // });
      chat.setUserDetails(profile.email, profile.first_name, profile.last_name, profile.phone);
    } else {
      chat.setUserDetails();
    }

    chat.addTicketField('platform_type', getPlatform());
    chat.addTicketField('brand', window.config.brand_name_short);
    chat.addContactField('brand', window.config.brand_name_short);
    chat.addTicketField('client_player_id', profile.client_player_id);
    chat.addTicketField('language', language);
  };

  const showChat = (data: any) => {
    if (!state.initialized) {
      setState((v) => ({
        ...v,
        scriptId: window.config.customerSupport?.providers.liveAgent.key,
        renderer: window.config.customerSupport?.providers.liveAgent.renderer,
        ...data,
        initialized: true,
      }));
    }
  };

  const init = () => {
    setState((v) => ({ ...v, loaded: true }));
  };

  const loadChat = () => {
    if (state.loaded) return;
    if (!state.scriptId) return;
    const el = document.getElementById(state.scriptId);
    if (el) {
      Utils.delayedFunction('initLA', init, 500);
    } else {
      const src = 'https://playonline.ladesk.com/scripts/track.js';
      const lastScript = document.scripts[document.scripts.length - 1];
      const script = document.createElement('script');
      script.id = state.scriptId;
      script.async = true;
      script.src = src;
      // @ts-ignore
      script.onload = script.onreadystatechange = function () {
        // @ts-ignore
        if (this.readyState && this.readyState !== 'complete' && this.readyState !== 'loaded') {
          return;
        }
        init();
      };
      lastScript?.parentElement?.insertBefore(script, lastScript.nextSibling);
    }
  };

  return <LiveAgentElementDiv id={state.bubbleId} className={'live-agent-element'}></LiveAgentElementDiv>;
};

export default LiveAgent;
