import * as defs from './defs';

export class TaxCalculatorRO extends defs.TaxCalculatorBase {
  public getDepositTax = (req: defs.TaxRequest): defs.TaxResponse => {
    // deposit tax - 2% of total amount deposit;
    const rawTaxAmount = 0.02 * req.totalAmount;
    const taxAmount = Math.round(rawTaxAmount);

    return {
      rawTaxAmount,
      taxAmount,
      originalAmount: req.totalAmount,
      amountAfterTax: req.totalAmount - taxAmount,
      taxedAmount: req.totalAmount,
    };
  };

  public getWithdrawTax = (req: defs.TaxRequest): defs.TaxResponse => {
    // withdraw tax - 0 (progressive tax)
    // 3% for amounts up to 10.000 RON
    // 300.00 RON + 20% of amount over 10,000.00 RON
    // 11,650.00 RON + 40% of amount over 66,750.00 RON
    const taxLevels: defs.TaxLevel[] = [
      { threshold: 1000000, tax: 0.03 },
      { threshold: 6675000, tax: 0.2 },
      { threshold: null, tax: 0.4 },
    ];

    const rawTaxAmount = this.calculateProgressiveTax(req.totalAmount, taxLevels);
    const taxAmount = Math.round(rawTaxAmount / 100);

    return {
      rawTaxAmount,
      taxAmount,
      originalAmount: req.totalAmount,
      amountAfterTax: req.totalAmount - taxAmount,
      taxedAmount: req.totalAmount,
    };
  };

  public getSportsbookBetTax = (req: defs.TaxRequest): defs.TaxResponse => {
    // sport bet tax - 0
    return {
      rawTaxAmount: 0,
      taxAmount: 0,
      originalAmount: req.totalAmount,
      amountAfterTax: req.totalAmount,
      taxedAmount: 0,
    };
  };

  public getSportsbookWinTax = (req: defs.TaxRequest): defs.TaxResponse => {
    // sport win tax - 0
    return {
      rawTaxAmount: 0,
      taxAmount: 0,
      originalAmount: req.totalAmount,
      amountAfterTax: req.totalAmount,
      taxedAmount: 0,
    };
  };
}
