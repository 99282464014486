import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/dist/locale/ro';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import { ticketOpenedListRequest } from '../../../../modules/lotto/store/actions/tickets';
import { lottoDateToString, MONTHS } from '../../../../page-components/utils/functions';

type LottoOpenedProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const LottoOpened = (componentProps: LottoOpenedProps) => {
  const dispatch = useAppDispatch();
  const opened = useAppSelector<any[]>((state) => state.lotto.tickets.ticketsOpened);
  const inProgress = useAppSelector((state) => state.lotto.tickets.requestInProgress.opened);
  const { i18n } = useTranslation();

  React.useEffect(() => {
    dispatch(ticketOpenedListRequest());
  }, []); //eslint-disable-line

  const contextValue = React.useMemo(() => {
    const tickets: any[] = [];
    opened.forEach((ticket) => {
      const dt = moment(ticket.event_time / 1e6).locale(i18n.language === 'ro' ? 'ro' : 'en');
      const event_date = dt.format('YYYY-MM-DD HH:mm:ss');
      const sd = dt.format('D') + ' ' + (i18n.language === 'ro' ? MONTHS[dt.format('M')] : dt.format('MMM'));
      const tm = dt.format('HH:mm');

      const ticket_time_formatted = lottoDateToString(ticket.time / 1e6);

      let lucky_number = 0;
      const lucky_number_won = false;
      let stake_is_free_bet = false;
      let stake_is_redeemable = false;
      let free_bet_name = '';
      let bonuses: any[] = [];
      const free_bet_line = '';

      if (ticket && ticket.bonus_data) {
        bonuses = [];
        let bonus_data = [];
        try {
          bonus_data = JSON.parse(ticket.bonus_data);
        } catch (err) {}

        bonus_data.forEach((b: any) => {
          if (b && b.type === 'lotto_lucky_number') {
            if (b.data) {
              let data: any = {};
              try {
                data = JSON.parse(b.data);
              } catch (err) {}

              if (
                data &&
                typeof data.number !== 'undefined' &&
                ticket.numbers.indexOf(parseInt(data.number, 10)) > -1
              ) {
                lucky_number = parseInt(data.number, 10);
                bonuses.push('Lotto Lucky Number');
              }
            }
          } else if (b && b.type === 'lotto_chance') {
            if (b.data) {
              let data: any = {};
              try {
                data = JSON.parse(b.data);
              } catch (err) {}

              if (data && typeof data.systems !== 'undefined') {
                if (data.systems.indexOf(`${ticket.numbers.length}/${ticket.numbers.length}`) > -1) {
                  bonuses.push('Lotto Chance');
                }
              }
            }
          } else if (b && b.type === 'free_bet') {
            if (b.data) {
              let data: any = {};
              try {
                data = JSON.parse(b.data);
              } catch (err) {}

              if (data && typeof data.consumed !== 'undefined' && data.consumed) {
                stake_is_free_bet = true;
                free_bet_name = data.name ? data.name : 'N/A';
                stake_is_redeemable = typeof data.redeemable !== 'undefined' && data.redeemable === 1 ? true : false;
              }
            }
          }
        });
      }

      tickets.push({
        ...ticket,
        event_date,
        ticket_time_formatted,
        event_time_ms: ticket.event_time / 1e6,
        is_past: ticket.event_time / 1e6 < Date.now(),
        lucky_number,
        lucky_number_won,
        stake_is_free_bet,
        stake_is_redeemable,
        free_bet_name,
        free_bet_line,
        bonuses,
        sd,
        tm,
        numbers: ticket.numbers.map((n: number) => ({
          value: n,
          isLucky: n === lucky_number,
          isWon: ticket?.event_results?.indexOf(n) > -1,
        })),
      });
    });

    const contextValue = {
      tickets: tickets,
      inProgress,
    };

    return contextValue;
  }, [opened, inProgress, i18n]);

  //console.log('LottoOpened[contextValue]: ', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default LottoOpened;
