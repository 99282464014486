import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { DataElementContext } from '../common/DataElementContext';
import { equalWithoutChildren } from '../../utils/fastDeepEqual';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { processTranslatableText } from '@/page-components/utils/functions';

const interceptClickEvent = (onClick) => (e) => {
  const target = e.target || e.srcElement;
  if (target.tagName === 'A' && window.config.cordova) {
    const href = target.getAttribute('href');

    if (href.indexOf('http') > -1) {
      console.log('Opening external link in system browser', href);
      e.preventDefault();
      window.cordova.InAppBrowser.open(href, '_system');
    }
  }
  onClick?.(e);
};

const TextComponent = styled.div((props) => props.$styleText);

export const RichText = (componentProps) => {
  let props = componentProps;

  const { i18n, t } = useTranslation();

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  const text = React.useMemo(() => {
    return processTranslatableText(props.properties.richText, props.properties.placeholders, i18n.language, t);
  }, [props.properties.richText, props.properties.placeholders, i18n.language, t]);

  if (!isVisible) return null;

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  let onClick = props.properties.onClick ?? null;

  if (window.config.cordova === true) {
    onClick = interceptClickEvent(onClick);
  }

  return (
    <TextComponent
      className={props.className ?? ''}
      $styleText={props.styleText}
      style={props.style}
      dangerouslySetInnerHTML={{ __html: text }}
      data-node-id={props.nodeId}
      onClick={onClick}
      {...extraDataProps}
    />
  );
};

export default React.memo(RichText, equalWithoutChildren);
