import * as actionTypes from './../actions/actionTypes';
import { PaymentProvider } from '@/constants/paymentProvider';

const initialOktoState = {
  paymentCode: null,
  qrPayload: null,
  customer: {
    active_auto_exclusion: null,
    amount_can_deposit: null,
    account_verified: null,
    okto_terms_accepted: null,
    okto_terms_modified: null,
    okto_customer_date: null,
  },
  error: false,
};

const initialBridgerState = {
  // cashierKey: null,
  cashierToken: null,
  error: false,
  tokenList: null,
  deposit_id: null,
  status: null,
};

const initialBridgerApplePayState = {
  cashierKey: null,
  cashierToken: null,
  error: false,
};

const initialBetlionState = {
  mobile: {
    status: null,
  },
  ekwanza: {
    paymentCode: null,
  },
  paypay: {
    url: null,
    qrCode: null,
  },
  express: {
    alternativePhoneNumber: null,
  },
  channels: null,
  mno: {
    player: null,
    other: null,
  },
};

const initialState = {
  bridger: {
    ...initialBridgerState,
  },
  bridgerApplePay: {
    ...initialBridgerApplePayState,
  },
  paysafe: {
    iframeUrl: null,
    error: false,
  },
  okto: { ...initialOktoState },
  aircash: {
    loading: false,
    iframeUrl: null,
    error: false,
  },
  viva: {
    loading: false,
    loadingAccessToken: false,
    loadingCardToken: false,
    amount: null,
    iframeLink: null,
    serverResponse: false,
    bonusError: false,
    tokenList: null,
  },
  paymentMethods: {
    loading: false,
    all: [],
    initialized: false,
  },
  betLion: {
    ...initialBetlionState,
  },
  taxes: {}
};

const depositReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.deposit.RESET:
      return {
        ...initialState,
      };
    case actionTypes.deposit.INITIATE_BRIDGER_PAY_DEPOSIT:
      return {
        ...state,
        bridger: {
          ...initialBridgerState,
        },
      };
    case actionTypes.deposit.INITIATE_BRIDGER_PAY_DEPOSIT_SUCCESS:
      return {
        ...state,
        bridger: {
          ...state.bridger,
          ...initialBridgerState,
          cashierToken: action.data.cashier_token,
        },
      };
    case actionTypes.deposit.INITIATE_BRIDGER_PAY_DEPOSIT_FAILED:
      return {
        ...state,
        bridger: {
          ...state.bridger,
          ...initialBridgerState,
          error: true,
        },
      };
    case actionTypes.deposit.BRIDGER_PAY_DEPOSIT_RESPONSE:
      if (action.data?.reset) {
        return {
          ...state,
          bridger: {
            ...initialBridgerState,
          },
        };
      }

      return {
        ...state,
        bridger: {
          ...state.bridger,
          // cashierKey: null,
          cashierToken: null,
          error: !!(action.data?.error ?? action.data.errorCode),
          ...action.data,
        },
      };

    case actionTypes.deposit.INITIATE_BRIDGER_APPLE_PAY_DEPOSIT:
      return {
        ...state,
        bridgerApplePay: {
          ...initialBridgerApplePayState,
        },
      };
    case actionTypes.deposit.INITIATE_BRIDGER_APPLE_PAY_DEPOSIT_SUCCESS:
      return {
        ...state,
        bridgerApplePay: {
          ...state.bridgerApplePay,
          ...initialBridgerApplePayState,
          cashierToken: action.data.cashier_token,
          cashierKey: action.data.cashier_key,
        },
      };
    case actionTypes.deposit.INITIATE_BRIDGER_APPLE_PAY_DEPOSIT_FAILED:
      return {
        ...state,
        bridgerApplePay: {
          ...state.bridgerApplePay,
          ...initialBridgerApplePayState,
          error: true,
        },
      };

    case actionTypes.deposit.BRIDGER_APPLE_PAY_DEPOSIT_RESPONSE:
      if (action.data?.reset) {
        return {
          ...state,
          bridgerApplePay: {
            ...initialBridgerApplePayState,
          },
        };
      }

      return {
        ...state,
        bridgerApplePay: {
          ...state.bridgerApplePay,
          cashierKey: action.data?.cashier_key ?? null,
          cashierToken: action.data?.cashier_token ?? null,
          error: !!(action.data?.error ?? action.data.errorCode),
          ...action.data,
        },
      };
    case actionTypes.paymentCheckout.REQUEST_PAYMENT_LINK:
      return {
        ...state,
        paysafe: {
          ...state.paysafe,
          iframeUrl: null,
          error: false,
        },
      };
    case actionTypes.paymentCheckout.RECEIVED_PAYMENT_LINK:
      return {
        ...state,
        paysafe: {
          ...state.paysafe,
          iframeUrl: action.response.link,
          error: !!(action.response?.error ?? action.response.errorCode),
        },
      };

    case actionTypes.deposit.OKTO_CREATE_PAYMENT_CODE:
      return {
        ...state,
        loading: true,
        okto: { ...initialOktoState },
      };
    case actionTypes.deposit.OKTO_RECEIVED_PAYMENT_CODE:
      return {
        ...state,
        loading: false,
        okto: {
          ...initialOktoState,
          ...action.data,
        },
      };
    case actionTypes.deposit.OKTO_RECEIVED_CUSTOMER:
      return {
        ...state,
        loading: false,
        okto: {
          ...initialOktoState,
          customer: {
            ...initialOktoState.customer,
            ...action.data,
          },
        },
      };
    case actionTypes.deposit.OKTO_GET_CUSTOMER:
      return {
        ...state,
        loading: true,
        okto: {
          ...initialOktoState,
        },
      };
    case actionTypes.deposit.OKTO_RESET:
      return {
        ...state,
        okto: {
          ...initialOktoState,
          // customer: {
          //   ...initialOktoState.customer,
          //   ...state.okto.customer,
          // },
        },
      };

    case actionTypes.paymentCheckout.REQUEST_VIVA_TOKEN:
      return {
        ...state,
        viva: {
          ...state.viva,
          loadingAccessToken: true,
        },
      };
    case actionTypes.paymentCheckout.RECEIVED_VIVA_TOKEN:
      return {
        ...state,
        viva: {
          ...state.viva,
          loadingAccessToken: false,
        },
      };
    case actionTypes.paymentCheckout.REQUEST_VIVA_CARD_TOKEN:
      return {
        ...state,
        viva: {
          ...state.viva,
          loadingCardToken: true,
        },
      };
    case actionTypes.paymentCheckout.RECEIVED_PAYMENT_TOKEN:
      return {
        ...state,
        viva: {
          ...state.viva,
          loadingCardToken: false,
          tokenList: action?.data?.result,
        },
      };
    case actionTypes.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN:
      return {
        ...state,
        viva: {
          ...state.viva,
          loading: true,
        },
      };
    case actionTypes.paymentCheckout.SEND_VIVA_CHARGE_TOKEN:
      let iframeLink = null;
      if (action.data && action.data.redirectToACSForm) {
        iframeLink = action.data.redirectToACSForm;
      }
      return {
        ...state,
        viva: {
          ...state.viva,
          loading: true,
          iframeLink: iframeLink,
        },
      };
    case actionTypes.paymentCheckout.RECEIVED_VIVA_CHARGE_TOKEN:
      let serverResponse = null;
      if (typeof action.data.serverResponse !== 'undefined') {
        serverResponse = action.data.serverResponse;
      } else {
        if (action.data.result && action.data.result.status) {
          serverResponse = action.data.result.status;
        }

        if (action.data.result && action.data.result.ResponseCode) {
          serverResponse = `${action.data.result.ResponseMessage} (${action.data.result.ResponseCode})`;
        }

        if (action.data.result && action.data.result.http) {
          serverResponse = 'Something went wrong (HTTP_FAIL)';
        }

        if (!serverResponse) {
          serverResponse = 'success';
        }
      }
      const bonusError = action?.data?.result?.bonusError ? action.data.result.bonusError : false;

      return {
        ...state,
        viva: {
          ...state.viva,
          loading: false,
          serverResponse: serverResponse,
          bonusError: bonusError,
        },
      };
    case actionTypes.paymentCheckout.RECEIVED_VIVA_CHARGE_TOKEN_WITH_CARD:
      let serverResponseWC = null;
      if (action.data.result && action.data.result.status) {
        serverResponseWC = action.data.result.status;
      }

      if (action.data.response && action.data.response.data && action.data.response.data.message) {
        serverResponseWC = action.data.response.data.message;
        if (action.data.response.status) {
          serverResponseWC = action.data.response.status + ': ' + serverResponseWC;
        }
      }
      const bonusErrorWithCard = action.data.result.bonusError ? action.data.result.bonusError : false;
      return {
        ...state,
        viva: {
          ...state.viva,
          loading: false,
          serverResponse: serverResponseWC,
          bonusError: bonusErrorWithCard,
        },
      };
    case actionTypes.paymentCheckout.RESET_PAYMENT_CHECKOUT:
      return {
        ...state,
        viva: {
          ...state.viva,
          loading: false,
          loadingAccessToken: false,
          loadingCardToken: false,
          access_token: null,
          amount: null,
          iframeLink: null,
          serverResponse: false,
        },
      };
    case actionTypes.deposit.AIRCASH_REQUEST_PAYMENT_LINK:
      return {
        ...state,
        aircash: {
          ...state.aircash,
          loading: true,
          error: false,
        },
      };
    case actionTypes.deposit.AIRCASH_RECEIVED_PAYMENT_LINK:
      return {
        ...state,
        aircash: {
          ...state.aircash,
          loading: false,
          iframeUrl: action.response.url,
          error: !!(action.response?.error ?? action.response.errorCode),
        },
      };
    case actionTypes.deposit.FETCH_PLAYER_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          loading: true,
          all: [],
        },
      };
    case actionTypes.deposit.RECEIVED_PLAYER_PAYMENT_METHODS:
      let paymentMethods = [];
      if (action.data.error) {
        paymentMethods = [];
      } else {
        paymentMethods = action.data;
      }
      const bridgerPM: any = [];
      const vivaPM: any = [];
      paymentMethods.forEach((method: any) => {
        if (parseInt(method.paymentMethodType, 10) === PaymentProvider.bridger) {
          bridgerPM.push(method.paymentMethodDetails);
        } else if (method.paymentMethodType === PaymentProvider.viva) {
          vivaPM.push(method);
        }
      });

      return {
        ...state,
        viva: {
          ...state.viva,
          tokenList: [...vivaPM],
        },
        bridger: {
          ...state.bridger,
          tokenList: [...bridgerPM],
        },
        paymentMethods: {
          ...state.paymentMethods,
          loading: false,
          all: action.data,
          initialized: true,
        },
      };
    case actionTypes.deposit.INITIATE_BETLION_DEPOSIT:
      return {
        ...state,
        betLion: {
          ...initialBetlionState,
          channels: state.betLion.channels,
        },
      };
    case actionTypes.deposit.INITIATE_BETLION_DEPOSIT_PENDING:
      return {
        ...state,
        betLion: {
          ...state.betLion,
          mobile: {
            ...state.betLion.mobile,
            status: 2,
          },
        },
      };
    case actionTypes.deposit.INITIATE_BETLION_DEPOSIT_FAILED:
      return {
        ...state,
        betLion: {
          ...state.betLion,
          mobile: {
            ...state.betLion.mobile,
            status: 0,
          },
        },
      };
    case actionTypes.deposit.BETLION_RECEIVED_CHANNELS:
      if (action.data.channelsType !== 2) {
        return state;
      }

      const other: any = [];
      const mno = action.data.mno;

      for (const key in mno) {
        if (mno[key] !== action.data.player_mno) {
          other.push({
            key: getMnoServiceName(mno[key]), // mno
          });
        }
      }

      return {
        ...state,
        betLion: {
          ...state.betLion,
          channels: action.data.channels,
          mno: {
            ...state.betLion.mno,
            player: getMnoServiceName(action.data.player_mno),
            // mno - player_mno
            other: other,
          },
        },
      };
    case actionTypes.deposit.BETLION_RECEIVED_EKWANZA:
      return {
        ...state,
        betLion: {
          ...state.betLion,
          ekwanza: {
            ...state.betLion.ekwanza,
            paymentCode: action.paymentCode,
          },
        },
      };
    case actionTypes.deposit.BETLION_RECEIVED_PAYPAY:
      return {
        ...state,
        betLion: {
          ...state.betLion,
          paypay: {
            ...state.betLion.paypay,
            url: action.url,
            qrCode: action.qrCode,
          },
        },
      };
    case actionTypes.deposit.BETLION_GET_ALTERNATIVE_PHONE_NUMBER:
      return {
        ...state,
        betLion: {
          ...state.betLion,
          express: {
            ...state.betLion.express,
            alternativePhoneNumber: null,
          },
        },
      };
    case actionTypes.deposit.BETLION_RECEIVED_ALTERNATIVE_PHONE_NUMBER:
      return {
        ...state,
        betLion: {
          ...state.betLion,
          express: {
            ...state.betLion.express,
            alternativePhoneNumber: action.alternativePhoneNumber,
          },
        },
      };
    // case actionTypes.deposit.GET_TAX:
    //   return {
    //     ...state,
    //     taxes: {
    //         ...state.taxes,
    //     },
    //   };
    case actionTypes.deposit.RECEIVED_TAX:
      if (!action.data.deposit) return state;
      const originalAmount = action.data.deposit.originalAmount/100;
      return {
        ...state,
        taxes: {
          ...state.taxes,
          [originalAmount]: action.data.deposit.taxAmount/100,
        },
      };
    default:
      return state;
  }
};

export default depositReducer;

/**
 * Get MNO service name based on currency and mno name
 * @param mnoName
 */
function getMnoServiceName(mnoName: string) {
  try {
    mnoName = mnoName?.toLowerCase()?.replaceAll(' ', '-');
    const defaultCurrency = window.config?.defaultCurrency;
    switch (defaultCurrency) {
      case 'MZN': {
        if (mnoName === 'vodacom') {
          mnoName = 'm-pesa_red';
        } else if (mnoName === 'movitel') {
          mnoName = 'e-mola';
        }
        break;
      }
      case 'KES': {
        if (mnoName === 'safaricom') {
          mnoName = 'm-pesa_green';
        }
        break;
      }
      default:
        break;
    }
  } catch (e) {
    mnoName = 'unknown';
  }

  return mnoName ?? 'unknown';
}
