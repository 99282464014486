import { paymentProviderNameById } from '@/constants/paymentProvider';
import { FeatureCategory, FeatureLocation, FeatureSubcategory } from '../types/eventData';
import { logEvent } from './logEvent';

export const logDepositEvent = (featureUsed: string, state: any) => {
  try {
    logEvent(
      null,
      {
        feature_used: featureUsed,
        feature_category: FeatureCategory.Wallet,
        feature_subcategory: FeatureSubcategory.Deposit,
        feature_location: FeatureLocation.MainContent,
      },
      {
        deposit_details: {
          bonus_used: {
            bonus_id: state.selectedBonusInfo?.bonusId,
            bonus_name: state.selectedBonusInfo?.name,
          },
          deposit_amount: state.depositValue,
          deposit_payment_provider: state.paymentProvider,
          deposit_payment_provider_name: paymentProviderNameById[state.paymentProvider],
          game_url: getGameUrl(),
        },
      },
    );
  } catch (error) {
    console.error('Error logging event:', error);
  }
};

const getGameUrl = () => {
  const urlParams = new URLSearchParams(location.search);

  if (urlParams.has('from_game')) {
    return urlParams.get('from_game');
  }

  const gameUrl = localStorage.getItem('fgm');

  if (gameUrl) {
    return gameUrl;
  }

  return undefined;
};
