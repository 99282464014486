import React from 'react';
import styled from 'styled-components';
import { useNavigate, useMatches } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { appToggleCompetitions } from '@/modules/bets/store/actions/app';

//import './index.scss';

type ResolveUrlProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const ResolveUrl = (componentProps: ResolveUrlProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const uriMatches = useMatches();
  const dispatch = useAppDispatch();
  const prematch: any = useAppSelector((state) => state.bets.prematch);
  const live: any = useAppSelector((state) => state.bets.live);
  const showCompetitions = useAppSelector<any>((state) => state.bets.app.showCompetitions);

  const uriMatch = uriMatches[uriMatches.length - 1];
  const pathName = uriMatch?.pathname ?? '';

  let mType: string | null = null;
  if (uriMatch.pathname.indexOf('/bets/pre-match') === 0) {
    mType = 'prematch';
  } else if (uriMatch.pathname.indexOf('/bets/live-match') === 0) {
    mType = 'live';
  } else if (uriMatch.pathname.indexOf('/bets/top-leagues') === 0) {
    mType = 'prematch';
  }

  let idSport: any = false;
  let sportName: any = null;
  if (uriMatch?.params.idSport) {
    idSport = uriMatch.params.idSport;
  }

  if (mType && idSport) {
    if (mType === 'prematch') {
      sportName = prematch?.sports?.[idSport]?.sportName;
    } else if (mType === 'live') {
      sportName = live?.sports?.[idSport]?.sportName;
    }
  }

  const defaultSport: any = prematch?.sports?.[1]?.sportName;

  let idCategory: any = false;
  let categoryName: any = null;
  if (uriMatch?.params.idCategory) {
    idCategory = uriMatch.params.idCategory;
  }

  if (mType && idCategory) {
    if (mType === 'prematch') {
      categoryName = prematch?.categories?.[idCategory]?.categoryName;
    } else if (mType === 'live') {
      categoryName = live?.categories?.[idCategory]?.categoryName;
    }
  }

  let idTournament: any = false;
  let tournamentName: any = null;
  if (uriMatch?.params.idTournament) {
    idTournament = uriMatch.params.idTournament;
  }

  if (mType && idTournament) {
    if (mType === 'prematch') {
      tournamentName = prematch?.tournaments?.[idTournament]?.tournamentName;
    } else if (mType === 'live') {
      tournamentName = live?.tournaments?.[idTournament]?.tournamentName;
    }
  }

  const onToggleCompetitions = React.useCallback(() => {
    dispatch(appToggleCompetitions());
  }, []);

  const contextValue = React.useMemo(() => {
    let label: any = '';

    if (tournamentName) {
      label = tournamentName;
    } else if (categoryName) {
      label = categoryName;
    } else if (sportName) {
      label = sportName;
    }

    if (!label && pathName) {
      switch (pathName) {
        case '/bets/top-leagues':
          label = 'Top Leagues';
          break;
        case '/bets/pre-match':
          label = defaultSport;
          break;
        case '/bets/live-match':
          label = defaultSport;
          break;
        default:
          label = defaultSport;
          break;
      }
    }

    return {
      mType,
      idSport,
      sportName,
      idCategory,
      categoryName,
      idTournament,
      tournamentName,
      label,
      onToggleCompetitions,
      showCompetitions,
    };
  }, [
    dataElementContext,
    componentProps,
    mType,
    idSport,
    idCategory,
    idTournament,
    sportName,
    categoryName,
    tournamentName,
    onToggleCompetitions,
    pathName,
    defaultSport,
    showCompetitions,
  ]);

  if (!contextValue.label && !contextValue.sportName && !contextValue.categoryName && !contextValue.tournamentName) {
    return null;
  }

  //console.log('ResolveUrl[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default ResolveUrl;
