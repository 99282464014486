import moment from 'moment';

export const encodeTagsClient = (str: string): string => {
  const parsed = new DOMParser().parseFromString(str, 'text/html');
  const text = parsed.body.textContent ?? '';

  if (/(<([^>]+)>)/gi.test(str)) {
    return encodeTagsClient(text);
  }

  return text || '';
};

export const formatDuration = (timestamp: string, fromNow: boolean = false) => {
  const createdAt = moment(timestamp);

  if (fromNow) return createdAt.fromNow();

  const now = moment();
  const isToday = now.isSame(createdAt, 'day');
  //   const isYesterday = now.clone().subtract(1, 'days').isSame(createdAt, 'day');

  if (isToday) {
    return createdAt.format('HH:mm');
    //   } else if (isYesterday) {
    // return `Yesterday, ${createdAt.format('HH:mm')}`;
  } else {
    return createdAt.format('DD.MM HH:mm');
  }
};
