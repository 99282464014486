import React, { useContext } from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

import craftJsParser from '../../components/utils/craftJsParser';
import { PageDataContext, getProjectDataFromContext } from '../../components/utils/PageDataProvider';
import {
  Dropdown as DropdownComponent,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';

export const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    isOpen: false,
    direction: 'down',
    caret: true,
    flip: true,
  },
  visibility: {},
};

const DropdownDiv = styled.div((props) => props.$styleText);

export const Dropdown = (componentProps) => {
  let props = componentProps;

  const pageDataContext = React.useContext(PageDataContext);

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  const [isOpen, setIsOpen] = React.useState(props.properties.isOpen ?? false);
  React.useEffect(() => {
    if (props.properties.isOpen != null)
      setIsOpen(props.properties.isOpen);
  }, [props.properties.isOpen]);

  const onToggle = React.useCallback((e) => {
    if (e?.target?.dataset?.ignoreDropdownToggle != null) return;

    if (typeof props.properties.onToggle === 'function') {
      props.properties.onToggle(e);
      if (props.properties.isOpen == null) { // if onToggle returns true then it means it's uncontrolled and we need to 
        setIsOpen(v => !v);
      }
    } else {
      setIsOpen(v => !v);
    }
  }, [props.properties.onToggle, props.properties.isOpen]);

  const pValue = React.useMemo(() => {
    const empty = { hasError: false, buttonContent: null, menu: null };

    if (!isVisible) return empty;

    let buttonContent = null;
    let menu = null;
    let hasError = false;

    try {
      const data = getProjectDataFromContext(pageDataContext);

      const buttonNodeId = props.linkedNodes['dropdown-button'];

      if (!buttonNodeId) return empty; // something is wrong; the referenced node to render this selected layout doesn't exists

      const menuNodeId = props.linkedNodes['dropdown-menu'];

      if (!menuNodeId) return empty;

      buttonContent = craftJsParser({
        craftState: data,
        rootNodeId: buttonNodeId,
        pageId: props.refType === 'layout' ? null : props.refId,
        pageLayoutId: props.refType === 'layout' ? props.refId : null,
        pageType: props.refType,
      });
      menu = craftJsParser({
        craftState: data,
        rootNodeId: menuNodeId,
        pageId: props.refType === 'layout' ? null : props.refId,
        pageLayoutId: props.refType === 'layout' ? props.refId : null,
        pageType: props.refType,
        extendProps: props.properties.autoClose ? {
          onClick: (e) => {
            onToggle(e);
          }
        } : null,
      });
    } catch (err) {
      console.error(err);
      hasError = true;
    }

    return {
      buttonContent,
      menu,
      hasError,
    };
  }, [isVisible, pageDataContext,
    props.linkedNodes, props.refId, props.refType, props.properties.autoClose, onToggle]);

  if (!isVisible) return null;
  if (pValue.hasError) return null;
  if (!pValue.buttonContent) return null;
  if (!pValue.menu) return null;

  return (
    <DropdownDiv className={props.className ?? ''} $styleText={props.styleText} style={props.style}>
      <DropdownComponent isOpen={isOpen} toggle={onToggle} direction={props.properties.direction}>
        <DropdownToggle caret={props.properties.caret} tag="div">
          {pValue.buttonContent}
        </DropdownToggle>
        <DropdownMenu
          flip={props.properties.flip}
          end={props.properties.end}
        >
          {pValue.menu}
        </DropdownMenu>
      </DropdownComponent>
    </DropdownDiv>
  );
};
