import {
  PaymentDetailsInterface,
  PaymentProviderInterface
} from '@/components/classes/PaymentProviders/Deposit/Interfaces/PaymentProviderInterface';
import PaymentProvider, {
    PaymentProviderConstructor,
} from '@/components/classes/PaymentProviders/Deposit/PaymentProvider';
import {
  betLionGetAlternativePhoneNumber,
  betLionGetChannels,
  initiateBetLionDeposit
} from '@/modules/casino/store/actions/deposit';
import {PaymentProvider as PaymentProviderConstants} from '@/constants/paymentProvider';

/**
 * Betlion class
 * @extends PaymentProvider
 * @implements PaymentProviderInterface
 * this class is used to handle Africa payments
 */
class Betlion extends PaymentProvider implements PaymentProviderInterface {
  channelId: string = 'mobile';
  channels: any = [];
  alternativePhoneNumber: string | null = null;
  constructor(data: PaymentProviderConstructor) {
    super(data);
    this.setType(data.paymentProvider ?? 0);
    this.channels = data.settings?.channels;
    this.setChannelId(data.paymentProvider ?? 0);
  }
  setChannelId(type: number | undefined): void {
    if (!type) {
      type = this.type;
    }

    switch (type) {
      case PaymentProviderConstants.betlion:
        this.channelId = 'mobile';
        break;
      case PaymentProviderConstants.betlion_unitel:
        this.channelId = 'unitel';
        break;
      case PaymentProviderConstants.betlion_telopay:
        this.channelId = 'telopay';
        break;
      case PaymentProviderConstants.betlion_afrimoney:
        this.channelId = 'afrimoney';
        break;
      case PaymentProviderConstants.betlion_ekwanza:
        this.channelId = 'ekwanza';
        break;
      case PaymentProviderConstants.betlion_aki:
        this.channelId = 'aki';
        break;
      case PaymentProviderConstants.betlion_paypay:
        this.channelId = 'paypay';
        break;
      case PaymentProviderConstants.betlion_pagaso:
        this.channelId = 'pagaso';
        break;
      default:
        this.channelId = 'mobile';
        break;
    }
  }
  getChannelId(): string {
    return this.channelId;
  }

  fetchChannels(): void {
    this.dispatch(betLionGetChannels());
  }

  fetchAlternativePhoneNumber(): void {
    this.dispatch(betLionGetAlternativePhoneNumber());
  }

  init(data: any): void {
    super.init(data);
  }

  setPaymentDetails(paymentDetails: PaymentDetailsInterface, setPaymentDetailsCallBack?: () => void): any {
    if (this.type === PaymentProviderConstants.betlion_express) {
      if (paymentDetails.alternatePhoneNumber) {
        this.alternativePhoneNumber = paymentDetails.alternatePhoneNumber;
      }
    }
    return super.setPaymentDetails(paymentDetails, setPaymentDetailsCallBack);
  }

  confirmPayment(): void {
    const data:any = {
        amount: this.paymentDetails?.amount ?? 0,
        bonusId: this.paymentDetails?.bonusId ?? null,
        channelId: this.channelId,
    };
    if (this.type === PaymentProviderConstants.betlion_express) {
      data.alternateMsisdn = this.alternativePhoneNumber;
    }
    this.dispatch(
      initiateBetLionDeposit(data),
    );
  }
}

export default Betlion;
