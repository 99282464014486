interface PaymentProviderInterface {
  [key: string]: number;
}

interface PaymentProviderNameByIdInterface {
  [key: number]: string;
}

export const PaymentProvider: PaymentProviderInterface = {
  // multiplied by 100000 for different versions of the same provider and incremented by 1
  cash: 1,
  safecharge: 2,
  viva: 3,
  okto: 4,
  skrill: 5,
  abon: 6,
  bridger: 7,
  betlion: 8, // channel_id = mobile
  betlion_unitel: 800001,
  betlion_telopay: 800002,
  betlion_afrimoney: 800003,
  betlion_ekwanza: 800004,
  betlion_aki: 800005,
  betlion_paypay: 800006,
  betlion_pagaso: 800007,
  betlion_multicaixa: 800008,
  betlion_express: 800009,

};

export const PaymentProviderWithdraw: PaymentProviderInterface = {
  // needs matching with core
  viva: 3,
  iban: 4,
  skrill: 5,
  bridger: 7,
  betlion: 8,
  betlion_unitel: 800001,
  betlion_telopay: 800002,
  betlion_afrimoney: 800003,
  betlion_ekwanza: 800004,
  betlion_aki: 800005,
  betlion_paypay: 800006,
  // betlion_pagaso: 800007,
  // betlion_multicaixa: 800008,
  // betlion_express: 800009,
  betlion_bai_account: 800010,
  betlion_other_bank_account: 800011,
};

export const paymentProviderNameById: PaymentProviderNameByIdInterface = {
  [PaymentProvider.cash]: 'Cash',
  [PaymentProvider.safecharge]: 'Safecharge',
  [PaymentProvider.viva]: 'Viva',
  [PaymentProvider.bridger]: 'Bridger',
  [PaymentProvider.okto]: 'Okto',
  [PaymentProvider.abon]: 'Abon',
  [PaymentProvider.skrill]: 'Skrill',
  [PaymentProvider.betlion]: 'Betlion',
  [PaymentProvider.betlion_unitel]: 'Betlion-Unitel',
  [PaymentProvider.betlion_telopay]: 'Betlion-Telopay',
  [PaymentProvider.betlion_afrimoney]: 'Betlion-Afrimoney',
  [PaymentProvider.betlion_ekwanza]: 'Betlion-Ekwanza',
  [PaymentProvider.betlion_aki]: 'Betlion-Aki',
  [PaymentProvider.betlion_paypay]: 'Betlion-PayPay',
  [PaymentProvider.betlion_pagaso]: 'Betlion-Pagaso',
  [PaymentProvider.betlion_multicaixa]: 'Betlion-Multicaixa',
  [PaymentProvider.betlion_express]: 'Betlion-Express',
};

export const paymentProviderNameByWithdrawId: PaymentProviderNameByIdInterface = {
  [PaymentProviderWithdraw.viva]: 'Viva',
  [PaymentProviderWithdraw.skrill]: 'Skrill',
  [PaymentProviderWithdraw.iban]: 'IBAN',
  [PaymentProviderWithdraw.bridger]: 'Bridger',
  [PaymentProviderWithdraw.betlion]: 'Betlion',
  [PaymentProviderWithdraw.betlion_unitel]: 'Betlion-Unitel',
  [PaymentProviderWithdraw.betlion_telopay]: 'Betlion-Telopay',
  [PaymentProviderWithdraw.betlion_afrimoney]: 'Betlion-Afrimoney',
  [PaymentProviderWithdraw.betlion_ekwanza]: 'Betlion-Ekwanza',
  [PaymentProviderWithdraw.betlion_aki]: 'Betlion-Aki',
  [PaymentProviderWithdraw.betlion_paypay]: 'Betlion-PayPay',
  [PaymentProviderWithdraw.betlion_bai_account]: 'Betlion-Atlantico',
  [PaymentProviderWithdraw.betlion_other_bank_account]: 'Betlion-Other-Bank-Account',
};

export const DEFAULT_AMOUNT: number = 20;

// this have styling hard coded in websources/src/components/modules/deposit/index.scss
export const HTML_3DS_IFRAME_ID: string = 'validation3ds';
export const HTML_3DS_IFRAME_WRAPPER: string = 'deposit-module'; /// fallback to document.body
export const BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY = 'bridgerApplePayDeposit';

export const TAX_TYPE_DEPOSIT: number = 2;
export const TAX_TYPE_WITHDRAW: number = 1;