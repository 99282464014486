import store from '@/store';
import { FeatureCategory, FeatureLocation, FeatureSubcategory } from '../types/eventData';
import { logEvent } from './logEvent';

interface NavigationTypeConfig {
  feature_used: (properties?: any, dataElementContext?: any) => string;
  category: (properties?: any, dataElementContext?: any) => FeatureCategory;
  subcategory: (properties?: any, dataElementContext?: any) => FeatureSubcategory;
  location: (properties?: any, dataElementContext?: any) => FeatureLocation;
  getPayload: (properties: any, dataElementContext: any) => any;
}

const parseAndMatchUrl = (href: string) => {
  // Split on first question mark to separate path and query
  const [path, queryString] = href.split('?');

  // Remove any trailing slashes from path
  const basePath = path.replace(/\/+$/, '');

  // If no query string, return early
  if (!queryString) {
    return { basePath, queryParams: {} };
  }

  // query params into object
  const queryParams = Object.fromEntries(
    queryString.split('&').map((param) => {
      const [key, value] = param.split('=');
      return [decodeURIComponent(key), decodeURIComponent(value || '')];
    }),
  );

  return { basePath, queryParams };
};

// Types
interface SportMatchPath {
  sport: {
    id: string;
  };
  type: 'live' | 'pre-match';
  tournamentId?: string;
  competitionId?: string;
  matchId?: string;
}

interface SlotGamePath {
  gameId: string;
  gameName: string;
}

interface ParsedUrl {
  basePath: string;
  queryParams: Record<string, string>;
  fullPath: string;
  pathData: SportMatchPath | SlotGamePath | null;
}

// URL parsing utilities
class UrlParser {
  static parse(href: string): ParsedUrl {
    const [path, queryString] = href.split('?');
    const pathParts = path.split('/').filter(Boolean);

    // Handle /slot-game/1818/big-bass-mission-fishin pattern
    if (pathParts[0] === 'slot-game') {
      return {
        basePath: '/slot-game',
        pathData: {
          gameId: pathParts[1],
          gameName: pathParts[2],
        },
        queryParams: this.parseQueryParams(queryString),
        fullPath: path,
      };
    }

    // Handle /bets/match/pre-match/1/2336/18471/23346946 pattern
    if (pathParts[0] === 'bets' && pathParts[1] === 'match') {
      return {
        basePath: '/bets/match',
        pathData: {
          type: pathParts[2] as 'live' | 'pre-match',
          sport: {
            id: pathParts[3],
          },
          tournamentId: pathParts[4],
          competitionId: pathParts[5],
          matchId: pathParts[6],
        },
        queryParams: this.parseQueryParams(queryString),
        fullPath: path,
      };
    }

    // Default case
    return {
      basePath: '/' + pathParts[0],
      pathData: null,
      queryParams: this.parseQueryParams(queryString),
      fullPath: path,
    };
  }

  private static parseQueryParams(queryString?: string): Record<string, string> {
    if (!queryString) return {};

    return Object.fromEntries(
      queryString.split('&').map((param) => {
        const [key, value] = param.split('=');
        return [decodeURIComponent(key), decodeURIComponent(value || '')];
      }),
    );
  }
}

const HREF_TYPE_HANDLERS: Record<string, NavigationTypeConfig> = {
  // currently story comes from the same dataElementTypeId as banner
  // we do the check in the subcategory function
  '/deposit': {
    feature_used: (properties) => identifyFeatureUsed('deposit_navigation_' + properties['data-feature-used']),
    category: (properties) => identifyFeatureCategory(properties['data-feature-category'] || 'navigation'),
    subcategory: (properties) => identifyFeatureSubcategory(properties['data-feature-sub-category'] || 'unspecified'),
    location: (properties) => identifyFeatureLocation(properties['data-feature-location'] || 'main'),
    getPayload: (properties, dataElementContext) => {
      const walletState = store.getState().wallet;

      return {
        // just add the wallet state to the payload
        navigate_to: properties.href,
        wallet: walletState,
        queryParams: dataElementContext.queryParams,
        'data-evt-id': properties['data-evt-id'],
        'data-evt-source': properties['data-evt-source'],
      };
    },
  },
  '/bets/match': {
    feature_used: (properties) =>
      identifyFeatureUsed(
        (properties['data-mtype'] || 'match') + '_navigation_' + (properties['data-feature-used'] || 'unspecified'),
      ),
    category: (properties, dataElementContext) => {
      // count as bets recommendation if brmId is present
      if (dataElementContext?.brmId) {
        return FeatureCategory.BetsRecommendation;
      }

      return identifyFeatureCategory(properties['data-feature-category'] || 'navigation');
    },
    subcategory: (properties) =>
      identifyFeatureSubcategory(properties['data-feature-sub-category'] || 'not applicable'),
    location: (properties) => identifyFeatureLocation(properties['data-feature-location'] || 'main'),
    getPayload: (properties, dataElementContext) => {
      return {
        navigate_to: properties.href,
        queryParams: dataElementContext.queryParams,
        recommended_bet_id: dataElementContext?.brmId,
      };
    },
  },
};

const identifyFeatureUsed = (feature_used: string): string => {
  return feature_used;
};

const identifyFeatureCategory = (feature_used: string): FeatureCategory => {
  if (!feature_used) {
    return FeatureCategory.Unspecified;
  }

  const keywordCategoryMap = [
    { category: FeatureCategory.Promotions, keywords: ['promo', 'promotion'] },
    { category: FeatureCategory.Navigation, keywords: ['navigation', 'nav'] },
    // Add more categories and their keywords as needed
  ];

  // Helper function to check if any keyword is found in a string
  const containsKeyword = (str: string, keywords: string[]): boolean => {
    return keywords.some((keyword) => str.toLowerCase().includes(keyword));
  };

  for (const { category, keywords } of keywordCategoryMap) {
    if (containsKeyword(feature_used, keywords)) {
      return category;
    }
  }

  // Default case if no matches
  return FeatureCategory.Unspecified;
};

const identifyFeatureLocation = (feature_location: string): FeatureLocation => {
  if (!feature_location) {
    return FeatureLocation.Unspecified;
  }

  const keywordLocationMap = [
    { location: FeatureLocation.MainContent, keywords: ['main', 'content', 'main page'] },
    { location: FeatureLocation.Header, keywords: ['header'] },
    { location: FeatureLocation.Footer, keywords: ['footer'] },
    { location: FeatureLocation.Sidebar, keywords: ['sidebar'] },
    { location: FeatureLocation.Modal, keywords: ['modal'] },
    { location: FeatureLocation.GameInterface, keywords: ['game'] },
    { location: FeatureLocation.InGameNotification, keywords: ['inGameNotification'] },
    { location: FeatureLocation.Betslip, keywords: ['betslip'] },
    // Add more locations and their keywords as needed
  ];

  // Helper function to check if any keyword is found in a string
  const containsKeyword = (str: string, keywords: string[]): boolean => {
    return keywords.some((keyword) => str.toLowerCase().includes(keyword));
  };

  for (const { location, keywords } of keywordLocationMap) {
    if (containsKeyword(feature_location, keywords)) {
      return location;
    }
  }

  // Default case if no matches
  return FeatureLocation.Unspecified;
};

const identifyFeatureSubcategory = (feature_subcategory: string): FeatureSubcategory => {
  if (!feature_subcategory) {
    return FeatureSubcategory.Unspecified;
  }

  const keywordSubcategoryMap = [
    { subcategory: FeatureSubcategory.Carousel, keywords: ['carousel', 'slider', 'slides'] },
    { subcategory: FeatureSubcategory.Stories, keywords: ['stories', 'story'] },
    { subcategory: FeatureSubcategory.Banner, keywords: ['banner'] },
    { subcategory: FeatureSubcategory.GameSearch, keywords: ['game search'] },
    { subcategory: FeatureSubcategory.WalletControls, keywords: ['wallet', 'wallet controls'] },
    { subcategory: FeatureSubcategory.NotApplicable, keywords: ['n/a', 'not applicable'] },
    // Add more subcategories and their keywords as needed
  ];

  // Helper function to check if any keyword is found in a string
  const containsKeyword = (str: string, keywords: string[]): boolean => {
    return keywords.some((keyword) => str.toLowerCase().includes(keyword));
  };

  for (const { subcategory, keywords } of keywordSubcategoryMap) {
    if (containsKeyword(feature_subcategory, keywords)) {
      return subcategory;
    }
  }

  // Default case if no matches
  return FeatureSubcategory.Unspecified;
};

export const logNavigationEvent = (event: any, properties: any, dataElementContext: any): void => {
  try {
    const parsedUrl = UrlParser.parse(properties.href);
    const handler = HREF_TYPE_HANDLERS[parsedUrl.basePath];

    if (!handler) {
      console.debug(`Skipping logging for href type: ${parsedUrl.basePath}`);
      return;
    }

    // Add query parameters to the dataElementContext
    const enrichedContext = {
      ...dataElementContext,
      ...parsedUrl,
    };

    logEvent(
      event,
      {
        // feature_used: properties['data-feature-used'],
        feature_used: handler.feature_used(properties, enrichedContext),
        feature_category: handler.category(properties, enrichedContext),
        feature_subcategory: handler.subcategory(properties, enrichedContext),
        feature_location: handler.location(properties, enrichedContext),
      },
      handler.getPayload(properties, enrichedContext),
    );
  } catch (error) {
    console.error('Error logging navigation event:', error);
  }
};
