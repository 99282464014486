import * as defs from './defs';

export class TaxCalculatorMW extends defs.TaxCalculatorBase {
  public getDepositTax = (req: defs.TaxRequest): defs.TaxResponse => {
    // deposit tax - 0;
    return {
      rawTaxAmount: 0,
      taxAmount: 0,
      originalAmount: req.totalAmount,
      amountAfterTax: req.totalAmount,
      taxedAmount: 0,
    };
  };

  public getWithdrawTax = (req: defs.TaxRequest): defs.TaxResponse => {
    // withdraw tax - 0
    return {
      rawTaxAmount: 0,
      taxAmount: 0,
      originalAmount: req.totalAmount,
      amountAfterTax: req.totalAmount,
      taxedAmount: 0,
    };
  };

  public getSportsbookBetTax = (req: defs.TaxRequest): defs.TaxResponse => {
    // sport bet tax - 0
    return {
      rawTaxAmount: 0,
      taxAmount: 0,
      originalAmount: req.totalAmount,
      amountAfterTax: req.totalAmount,
      taxedAmount: 0,
    };
  };

  public getSportsbookWinTax = (req: defs.TaxRequest): defs.TaxResponse => {
    // sport win tax: 5% of winnings above K100,000
    // winnings = (ticketWin - ticketBet)
    let taxedAmount = req.totalAmount - (req.notTaxableAmount ?? 0) - 10000000;
    let rawTaxAmount = 0;
    let taxAmount = 0;
    if (taxedAmount > 0) {
      rawTaxAmount = 0.05 * taxedAmount;
      taxAmount = Math.round(rawTaxAmount);
    } else {
      taxedAmount = 0;
    }

    return {
      rawTaxAmount,
      taxAmount,
      originalAmount: req.totalAmount,
      amountAfterTax: req.totalAmount - taxAmount,
      taxedAmount,
    };
  };
}
