// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { endpoints } from '../../api/endpoints';

type FetchConfigProps = {
  timezone: string;
};

type FetchConfigResult = {
  data: any;
  success: boolean;
};

type FetchConfigError = {
  rejectValue: {
    error: string;
  };
};

export const fetchAllCountriesConfig = createAsyncThunk<FetchConfigResult, FetchConfigProps, FetchConfigError>(
  'allCountries/list',
  async ({ timezone }, { rejectWithValue }) => {
    try {
      const response = await axios.get(endpoints.config.countries, {
        params: { timezone: timezone },
      });

      if (response.data.result) {
        return { data: response.data.result, success: true };
      }

      return rejectWithValue({
        error: "Couldn't fetch games config",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

export interface ConfigReducer {
  countries: any[];
  currentCountry: string;
  loading: boolean;
  error: any;
  validationPhoneError: boolean;
  phoneValidationMessage: string;
}

export const allCountriesSlice = createSlice({
  name: 'allCountries',
  initialState: <ConfigReducer>{
    countries: [],
    currentCountry: '',
    loading: false,
    error: null,
    validationPhoneError: false,
    phoneValidationMessage: '',
  },
  reducers: {
    setNewCountry: (state, action) => {
      state.currentCountry = action.payload;
    },

    setValidationPhoneErrorLoading: (state, action) => {
      state.loading = action.payload;
    },

    setValidationPhoneError: (state, action) => {
      state.validationPhoneError = action.payload;
    },

    setValidationPhoneMessage: (state, action) => {
      state.phoneValidationMessage = action.payload;
    },

    reset: (_state) => {
      /* example */
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCountriesConfig.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.success) {
          // console.log('REDUCER fetchAllCountriesConfig.fulfilled', action.payload);
          const enabledCountries = window.config.authenticationSettings?.enabledCountries ?? [];
          const restrictedCountries = window.config.authenticationSettings?.restrictedCountries ?? [];
          let countries = [];

          if (action.payload.data?.countries && Array.isArray(action.payload.data.countries)) {
            // filter countries based on enabledCountries
            if (enabledCountries.length > 0) {
              for (let i = 0; i < enabledCountries.length; i++) {
                const country = action.payload.data.countries.find(
                  (country: any) => country.countryCode === enabledCountries[i],
                );
                if (country) {
                  countries.push(country);
                }
              }
            } else {
              countries = [...action.payload.data.countries];
            }

            // remove any restricted countries
            if (restrictedCountries.length > 0) {
              for (let i = 0; i < restrictedCountries.length; i++) {
                const cIndex = countries.findIndex((country: any) => country.countryCode === restrictedCountries[i]);

                if (cIndex > -1) {
                  countries.splice(cIndex, 1);
                }
              }
            }
          }

          if (countries.length === 1) {
            state.currentCountry = countries[0].countryCode ?? action.payload.data.currentCountry;
          } else {
            state.currentCountry = action.payload.data.currentCountry;
          }

          state.countries = countries;
        }
      })
      .addCase(fetchAllCountriesConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllCountriesConfig.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

export const { reset, setNewCountry } = allCountriesSlice.actions;

export default allCountriesSlice.reducer;
