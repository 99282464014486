import React from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import store, { useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import { getMatchData } from '../utils/hooks';
import { testValues, liveScore, formatDate, formatOddName, formatBetTitle, getTr } from '../utils/functions';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

import crests from '../../../../modules/bets/utils/crests';

import { CategoryType, MatchType, SportType, TournamentType } from '../utils/types';

type MatchCardProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const extractUriParams = (match: any, props: any) => {
  let mType = props.properties.mType;
  let idSport = props.properties.idSport;
  let idCategory = props.properties.idCategory;
  let idTournament = props.properties.idTournament;
  let idMatch = props.properties.idMatch;
  let betName = props.properties.betName;

  let paramMType = props.properties.paramMType ?? 'mType';
  if (paramMType[0] === ':') paramMType = paramMType.substr(1);
  if (match.params && paramMType && match.params[paramMType] != null) {
    mType = match.params[paramMType];
  }

  let paramIdSport = props.properties.paramIdSport ?? 'idSport';
  if (paramIdSport[0] === ':') paramIdSport = paramIdSport.substr(1);
  if (match.params && paramIdSport && match.params[paramIdSport] != null) {
    idSport = match.params[paramIdSport];
  }

  let paramIdCategory = props.properties.paramIdCategory ?? 'idCategory';
  if (paramIdCategory[0] === ':') paramIdCategory = paramIdCategory.substr(1);
  if (match.params && paramIdCategory && match.params[paramIdCategory] != null) {
    idCategory = match.params[paramIdCategory];
  }

  let paramIdTournament = props.properties.paramIdTournament ?? 'idTournament';
  if (paramIdTournament[0] === ':') paramIdTournament = paramIdTournament.substr(1);
  if (match.params && paramIdTournament && match.params[paramIdTournament] != null) {
    idTournament = match.params[paramIdTournament];
  }

  let paramIdMatch = props.properties.paramIdMatch ?? 'idMatch';
  if (paramIdMatch[0] === ':') paramIdMatch = paramIdMatch.substr(1);
  if (match.params && paramIdMatch && match.params[paramIdMatch] != null) {
    idMatch = match.params[paramIdMatch];
  }

  let paramBetName = props.properties.paramBetName ?? 'idMatch';
  if (paramBetName[0] === ':') paramBetName = paramBetName.substr(1);
  if (match.params && paramBetName && match.params[paramBetName] != null) {
    betName = match.params[paramBetName];
  }
  return [mType, idSport ?? '1', idCategory, idTournament, idMatch, betName];
};

export const buildOutcomes = (match: MatchType, selectedBets: any, language: string, betName: string) => {
  if (!match?.bettingStatus) return [];

  const matchBets = match.matchBets.filter((market) => {
    if (!market.mbActive) return false;
    if (market.mbOutcomes.length === 0) return false;

    const hasActive = market.mbOutcomes.find((outcome) => outcome.mboActive === true);
    return !!hasActive;
  });

  matchBets.sort((a, b) => {
    const tv = testValues(a.mbPosition, b.mbPosition);
    if (tv !== 0) {
      return tv;
    }

    return testValues(a.mbSpecialValue, b.mbSpecialValue);
  });

  if (matchBets.length === 0) return [];

  let market = matchBets[0];
  if (betName) {
    const tmp = matchBets.find((market) => {
      const displayName = market.mbDisplayName;
      if (displayName && typeof displayName === 'string') {
        return displayName === betName;
      }
      return displayName && (displayName[2] === betName || displayName[0] === betName);
    });
    if (tmp) {
      market = tmp;
    }
  }
  const marketName = formatBetTitle(market, match, null, language);

  const res: any[] = [];

  const selectedHash: any = {};
  selectedBets.forEach((bet: any) => {
    selectedHash[bet.idMbo] = true;
  });

  market.mbOutcomes.forEach((outcome) => {
    if (outcome.mboActive) {
      let oddName = formatOddName(market.idBet, outcome, match, null, language);
      if ((match.idSport === '1' || match.idSport === '3') && (oddName === '1' || oddName === 'Win1')) {
        oddName = getTr(match.team1Name, language);
        oddName = oddName?.replace?.('/', ' / ');
      } else if ((match.idSport === '1' || match.idSport === '3') && (oddName === '2' || oddName === 'Win2')) {
        oddName = getTr(match.team2Name, language);
        oddName = oddName?.replace?.('/', ' / ');
      }

      const data = {
        mType: match.mType,
        idSport: match.idSport,
        idMatch: match.idMatch,
        idBet: market.idBet,
        idMb: market.idMb,
        idBo: outcome.idBo,
        idMbo: outcome.idMbo,
        oddValue: outcome.mboOddValue,
        changeDir: outcome.changeDir,
        oddName: oddName,
        marketName: marketName,
        selected: !!selectedHash[outcome.idMbo],
      };

      res.push(data);
    }
  });

  return res;
};

export const canCashout = (match: MatchType) => {
  let canCashout = false;
  if (match) {
    for (const mb of match.matchBets) {
      for (const mbo of mb.mbOutcomes) {
        if (mbo.isCashoutEnabled) {
          canCashout = true;
          break;
        }
      }

      if (canCashout) {
        break;
      }
    }
  }

  return canCashout;
};

const MatchCard = (componentProps: MatchCardProps) => {
  let props = componentProps;

  const { i18n, t } = useTranslation();

  const uriMatches = useMatches();
  const dispatch = useAppDispatch();

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  // try to initialize gameId from the properties object
  let mType = props.properties.mType;
  let idSport = props.properties.idSport;
  let idCategory = props.properties.idCategory;
  let idTournament = props.properties.idTournament;
  let idMatch = props.properties.idMatch;
  let betName = props.properties.betName;

  if (props.properties.fullMatchId) {
    const parts = props.properties.fullMatchId.split('/');
    if (parts?.[0] === '') parts.shift();

    [mType, idSport, idCategory, idTournament, idMatch, betName] = parts;
  }

  if (props.properties.targetIdFromPath) {
    if (uriMatches && uriMatches.length) {
      // there is no gameId set as props; maybe were in a route that includes a :gameId param
      const match = uriMatches[0];

      [mType, idSport, idCategory, idTournament, idMatch, betName] = extractUriParams(match, props);
    }
  }

  if (mType === 'live-match') {
    mType = 'live';
  } else {
    mType = 'prematch';
  }

  const { sport, category, tournament, match, inPageIdMatch, selectedBets, loadingState, fullStateLoaded } =
    getMatchData({
      mType: mType ?? 'prematch',
      idSport,
      idCategory,
      idTournament,
      idMatch,
    });

  let displayList: {
    index?: number;
    sport: SportType;
    tournament: TournamentType;
    category: CategoryType;
    matches: MatchType[];
    totalMatches?: number;
  }[] = [];

  if (sport && category && tournament && match) {
    displayList.push({
      sport: sport,
      category: category,
      tournament: tournament,
      matches: [{ ...match }],
    });
  }

  displayList = displayList.filter((item) => item.matches.length > 0);

  displayList.forEach((item, index) => {
    item.index = index;
    item.totalMatches = item.matches.length;

    item.matches.sort((a, b) => {
      if (!a && !b) return 0;
      if (!a) return 1;
      if (!b) return -1;

      return a.matchDateTime - b.matchDateTime;
    });

    item.matches.forEach((match) => {
      match.inPageSelected = inPageIdMatch === match.idMatch;

      match.canCashout = canCashout(match);

      match.matchDateTimeString = formatDate(match.matchDateTime, i18n.language, false);
      if (match.mType === 'live') match.scoreInfo = liveScore(match, match.currentStatus);

      match.outcomes = buildOutcomes(match, selectedBets, i18n.language, betName);

      match.team1LogoUrl = crests(match.team1Name, match, 1);
      match.team2LogoUrl = crests(match.team2Name, match, 2);
    });
  });

  const contextValue = {
    list: displayList,
    listTotal: displayList.length,
    loading: loadingState != null,
    fullStateLoaded: fullStateLoaded,
  };

  //console.log('MatchCard[contextValue]', contextValue, { mType, idSport, idCategory, idTournament, idMatch, betName });

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default MatchCard;
