import React from 'react';
import { createSelector } from 'reselect';

import { useAppSelector, store, useAppDispatch } from '@/store';
import { prematchFetchMatches } from '@/modules/bets/store/actions/prematch';
import { liveFetchMatches } from '@/modules/bets/store/actions/live';
import { testValues, formatDate, canCashout, liveScore } from '@/components/modules/bets/utils/functions';
import { buildOutcomes } from '@/components/modules/bets/prematch/sport';
import crests from '@/modules/bets/utils/crests';
import { isBetsRecommendationDataSource } from '@/components/modules/bets-recommendation/recommended-bets';

import { createMemoizeSelector } from './reselect-utils';
import _ from 'lodash';

const emptyData: any = {};
const emptyArray: any[] = [];
const requested: any = {};

const buildMatches = (matches: any, matchLists: any[]) => {
  if (!matchLists) return null;

  const ret: any = {};
  matchLists?.forEach(({ idMatch }) => {
    if (!matches[idMatch]) return;
    ret[idMatch] = matches[idMatch];
  });

  return ret;
};

type DataSourceList = {
  data: any[];
  element_type: {
    type_id: string;
  };
};

const prematchBetsSelector = createSelector(
  [
    (fromSource: string, dsId: string, bets: any) => bets.prematch.matches,
    (fromSource: string, dsId: string, bets: any, dataSourceList: DataSourceList) => dataSourceList?.data ?? null,
  ],
  buildMatches,
);

const liveBetsSelector = createSelector(
  [
    (fromSource: string, dsId: string, bets: any) => bets.live.matches,
    (fromSource: string, dsId: string, bets: any, dataSourceList: DataSourceList) => dataSourceList?.data ?? null,
  ],
  buildMatches,
);

type ProcessListReturn = any;

const inputLists = [
  () => 'from-processData',
  (fromSource: string, dsId: string) => dsId,
  (fromSource: string, dsId: string, bets: any, dataSourceList: DataSourceList) => dataSourceList,
  prematchBetsSelector,
  liveBetsSelector,
  (fromSource: string, dsId: string, bets: any, dataSourceList: DataSourceList) => bets.prematch.fullStateLoaded,
  (fromSource: string, dsId: string, bets: any, dataSourceList: DataSourceList) => bets.live.fullStateLoaded,
  (fromSource: string, dsId: string, bets: any, dataSourceList: DataSourceList, language: any) => language,
  (fromSource: string, dsId: string, bets: any, dataSourceList: DataSourceList, language: any, maxItems: number) => {
    return maxItems;
  },
];

const processData = (
  fromSource: string,
  dsId: string,
  dataSourceList: any,
  prematchMatches: any,
  liveMatches: any,
  prematchFullStateLoaded: any,
  liveFullStateLoaded: any,
  language: any,
  maxItems: number,
) => {
  if (!dataSourceList) return null;
  if (!dataSourceList?.data || !Array.isArray(dataSourceList?.data)) return null;

  const isBetsMatches = dataSourceList?.element_type?.type_id === 'bets.data.match';
  const isBetsLeagues = dataSourceList?.element_type?.type_id === 'bets.data.tournament';

  if (!(isBetsMatches || isBetsLeagues)) return null;
  if (!(prematchFullStateLoaded && liveFullStateLoaded)) return null;

  if (isBetsMatches) {
    let matches = dataSourceList?.data;

    matches = matches.filter((match: any) => !!match?.idMatch);

    const hasLive = matches?.some((match: any) => match?.mType === 'live');
    const stateMatches = hasLive ? liveMatches : prematchMatches;

    if (!stateMatches) return null;

    const now = Date.now();

    matches = matches.filter((match: any) => {
      if (match.matchDateTime) {
        if (hasLive) {
          if (now - match.matchDateTime < 1000 * 60 * 60 * 5) {
            // if there are at least 5 hours since the games has started
            return true;
          }
        } else {
          if (match.matchDateTime - now > 0) {
            // the game has not started yet so still a valid prematch
            return true;
          }
        }
      }
      return false;
    });

    const maxToFetch = maxItems ?? matches?.length ?? 0;
    matches = maxToFetch ? matches.slice(0, maxToFetch) : matches;

    //console.log(`dataSourceList[${dsId}]`, maxToFetch, matches);
    let exists = 0;
    const matchesIds = matches?.map((match: any) => match?.idMatch);
    let betsRecommendationMatches = 0;
    matches?.forEach((match: any) => {
      if (match?.brmId) {
        betsRecommendationMatches++;
      }
    });

    let notExistentMatches: any[] = [];
    if (!hasLive && !betsRecommendationMatches) {
      notExistentMatches = matchesIds?.filter((id: any) => {
        if (requested[id]) return false;
        requested[id] = true;
        if (stateMatches[id]) exists++;
        return !stateMatches[id];
      });
    }

    let notLoadedMatches: any[] = [];
    if (hasLive || betsRecommendationMatches) {
      notLoadedMatches = matchesIds?.filter((id: any) => {
        if (requested[id]) return false;
        requested[id] = true;
        if (stateMatches[id] && stateMatches[id]?._loaded) exists++;
        return !stateMatches[id]?._loaded || !stateMatches[id];
      });
    }

    if (exists >= maxToFetch) {
      notExistentMatches = [];
      notLoadedMatches = [];
    } else {
      const limit = maxToFetch - exists;
      if (notExistentMatches.length > limit) {
        notExistentMatches = notExistentMatches.slice(0, limit);
      }
      if (notLoadedMatches.length > limit) {
        notLoadedMatches = notLoadedMatches.slice(0, limit);
      }
    }

    if (!hasLive && notExistentMatches?.length > 0) {
      setTimeout(() => {
        store.dispatch(prematchFetchMatches(notExistentMatches, null, 1));
      }, 0);
    } else if (hasLive && notLoadedMatches?.length > 0) {
      setTimeout(() => {
        store.dispatch(liveFetchMatches(notLoadedMatches, null, 1));
      }, 0);
    } else if (betsRecommendationMatches && notLoadedMatches?.length > 0) {
      setTimeout(() => {
        store.dispatch(prematchFetchMatches(notLoadedMatches));
      }, 0);
    }

    const finalMatches: any[] = [];
    matches.forEach((match: any) => {
      let newMatch = hasLive ? null : match;
      if (stateMatches[match?.idMatch]) {
        newMatch = stateMatches[match?.idMatch];
      } else {
        if (hasLive) {
          return;
        } else if (newMatch.matchDateTime < Date.now()) {
          return;
        }
      }

      if (!newMatch) {
        finalMatches.push(null);
        return;
      }

      finalMatches.push(newMatch);
    });
    return finalMatches;
  } else if (isBetsLeagues) {
    return emptyArray;
  }
};

const getBetsData = createMemoizeSelector(inputLists, processData);
const getSelectedBets = createMemoizeSelector(
  [
    (stateBetsSlip: any, dataSourceList: DataSourceList) => dataSourceList?.element_type?.type_id === 'bets.data.match',
    (stateBetsSlip: any) => stateBetsSlip.tickets[stateBetsSlip.currentTicket].prematch.selected,
    (stateBetsSlip: any) => stateBetsSlip.tickets[stateBetsSlip.currentTicket].live.selected,
  ],
  (isBets, selectedPrematchBets: any, selectedLiveBets: any) => {
    if (!isBets) return null;

    const selectedBets: any[] = [];
    selectedPrematchBets?.forEach((bet: any) => {
      selectedBets.push(bet);
    });
    selectedLiveBets?.forEach((bet: any) => {
      selectedBets.push(bet);
    });

    return selectedBets;
  },
);

const areMatchesEqual = (a: any, b: any, selectedBets: any[], isBetsRecommendation: boolean = false) => {
  if (a === b) return true;
  if (!a && !b) return true;
  if (!a || !b) return false;
  if (a.length !== b.length) return false;

  const sb: any = {};
  selectedBets?.forEach((bet: any) => {
    sb[`${bet.idSport}-${bet.idMbo}`] = true;
  });

  for (let i = 0; i < a.length; i++) {
    if (a[i]?.idMatch !== b[i]?.idMatch) return false;
    if (a[i]?.active !== b[i]?.active) return false;
    if (a[i]?.bettingStatus !== b[i]?.bettingStatus) return false;
    if (!a[i]?.matchBets || !b[i]?.matchBets) return false;
    if (a[i]?.matchBets?.[0]?.idBet !== b[i]?.matchBets?.[0]?.idBet) return false;
    if (a[i]?.matchBets?.[0]?.idMb !== b[i]?.matchBets?.[0]?.idMb) return false;
    if (a[i]?.matchBets?.[0]?.active !== b[i]?.matchBets?.[0]?.active) return false;
    if (a[i]?.matchBets?.[0]?.mbActive !== b[i]?.matchBets?.[0]?.mbActive) return false;
    if (a[i]?.matchBets?.[0]?.changed !== b[i]?.matchBets?.[0]?.changed) return false;
    if (a[i]?.matchBets?.[0]?.mbChanged !== b[i]?.matchBets?.[0]?.mbChanged) return false;

    if (a[i]?.matchBets?.[0]?.mbOutcomes?.length !== b[i]?.matchBets?.[0]?.mbOutcomes?.length) return false;

    for (let j = 0; j < a[i]?.matchBets?.[0]?.mbOutcomes?.length; j++) {
      const aOutcome = a[i]?.matchBets?.[0]?.mbOutcomes[j];
      const bOutcome = b[i]?.matchBets?.[0]?.mbOutcomes[j];

      if (aOutcome?.idMbo !== bOutcome?.idMbo) return false;
      if (aOutcome?.mboActive !== bOutcome?.mboActive) return false;
      if (aOutcome?.mboOddValue !== bOutcome?.mboOddValue) return false;
    }

    for (let j = 0; j < a[i]?.outcomes?.length; j++) {
      const outcome = a[i]?.outcomes[j];
      if (!sb[`${outcome.idSport}-${outcome.idMbo}`] && !outcome?.selected) continue;
      if (sb[`${outcome.idSport}-${outcome.idMbo}`] && outcome?.selected) continue;
      if (sb[`${outcome.idSport}-${outcome.idMbo}`] && !outcome?.selected) return false;
      if (!sb[`${outcome.idSport}-${outcome.idMbo}`] && outcome?.selected) return false;
    }

    if (isBetsRecommendation) {
      if (a[i]?.matchBets?.length !== b[i]?.matchBets?.length) return false;
    }
  }

  return true;
};

export const useProcessList = (
  dataSourceList: any,
  language: string,
  dsId: string,
  maxItems: number,
): ProcessListReturn => {
  const getBetsRelatedData: any = useAppSelector((state: any) =>
    getBetsData(
      'from-useProcessList',
      dsId ?? dataSourceList?.id ?? null,
      state.bets,
      dataSourceList,
      language,
      maxItems,
    ),
  );
  const getSelectedBetsData: any = useAppSelector((state: any) => getSelectedBets(state.bets.betsSlip, dataSourceList));

  const [state, setState] = React.useState<any>({
    data: [],
    dsId: dsId ?? dataSourceList?.id ?? null, // unique key for memoization; ideally every instance of this hook should have a unique dsId
    changed: 1,
    processed: false,
  });

  React.useEffect(() => {
    if (Array.isArray(dataSourceList?.data)) {
      if (dataSourceList?.element_type?.type_id === 'bets.data.match') {
        setState((v: any) => {
          if (getBetsRelatedData?.length === 0) {
            return {
              dsId: v.dsId,
              data: [],
              changed: v.changed + 1,
              processed: true,
            };
          }

          if (areMatchesEqual(v.data, getBetsRelatedData, getSelectedBetsData, isBetsRecommendationDataSource(dsId))) {
            return v;
          }

          /*
          console.log(
            'useProcessList[getBetsRelatedData]',
            dsId,
            v.dsId,
            v.data,
            getBetsRelatedData,
            dataSourceList?.element_type?.type_id,
            dataSourceList,
          );
          */

          let changed = v.changed;
          if (v.data.length !== getBetsRelatedData?.length) changed += 1;

          const matches: any[] = [];

          getBetsRelatedData?.forEach((m: any, index: number) => {
            if (m?.idMatch) {
              const match = { ...m };

              const matchBets = match.matchBets.filter((market: any) => {
                if (!market.mbActive) return false;
                if (market.mbOutcomes.length === 0) return false;

                const hasActive = market.mbOutcomes.find((outcome: any) => outcome.mboActive === true);
                return !!hasActive;
              });

              matchBets.sort((a: any, b: any) => {
                const tv = testValues(a.mbPosition, b.mbPosition);
                if (tv !== 0) {
                  return tv;
                }

                return testValues(a.mbSpecialValue, b.mbSpecialValue);
              });

              if (matchBets.length > 0) {
                match.matchBets = isBetsRecommendationDataSource(dsId) ? matchBets : [matchBets[0]];
              } else {
                match.matchBets = [];
              }

              match.canCashout = canCashout(match);
              match.matchDateTimeString = formatDate(match.matchDateTime, language, false);

              if (match.mType === 'live') {
                const ls = liveScore(match, match.currentStatus);
                if (Array.isArray(ls.intervals) && ls.intervals.length > 0) {
                  ls.intervals = ls.intervals.map((interval) => {
                    return { percent: interval };
                  });
                }
                match.scoreInfo = ls;
              }

              const mType = match.mType === 'live' ? 'live' : 'prematch';
              const bets = store.getState().bets[mType].bets;

              match.outcomes = buildOutcomes(match, bets, getSelectedBetsData, language, '', false);

              match.team1LogoUrl = crests(match.team1Name, match, 1);
              match.team2LogoUrl = crests(match.team2Name, match, 2);

              const originalMatch = dataSourceList?.data?.[index];

              if (originalMatch) {
                if (originalMatch.brmId) match.brmId = originalMatch.brmId;
                if (originalMatch.brmIdMb) match.brmIdMb = originalMatch.brmIdMb;
                if (originalMatch.brmIdMbo) match.brmIdMbo = originalMatch.brmIdMbo;
                if (originalMatch.brmMotivations) match.brmMotivations = originalMatch.brmMotivations;
                if (originalMatch.brmNumber) match.brmNumber = originalMatch.brmNumber;
                if (originalMatch.brmPeriodIdMatch) match.brmPeriodIdMatch = originalMatch.brmPeriodIdMatch;
              }

              matches.push(match);
            }
          });

          return {
            dsId: v.dsId,
            data: matches,
            changed: changed,
            processed: true,
          };
        });
        return;
      }
      /*
      if (dataSourceList?.element_type?.type_id === 'bets.data.tournament') {
        
        const finalList: any[] = [];

        const tournaments = dataSourceList?.data;
        tournaments.forEach((tournament: any) => {
          if (tournament.idSport && tournament.idCategory && tournament.idTournament) {
            if (liveData?.[tournament.idSport]?.[tournament.idCategory]?.[tournament.idTournament]) {
              const total = Object.keys(
                liveData[tournament.idSport][tournament.idCategory][tournament.idTournament],
              ).length;
              finalList.push({ ...tournament, numberOfLiveEvents: total });
            } else {
              finalList.push({ ...tournament, numberOfLiveEvents: 0 });
            }
          }
        });

        setState((v: any) => {
          if (finalList.length === 0)
            return {
              data: [],
              changed: v.changed + 1,
              processed: true,
            };
          if (isEqual(v.data, finalList)) return v;
          let changed = v.changed;
          if (v.data.length !== finalList.length) changed += 1;
          return {
            data: finalList,
            changed: changed,
            processed: true,
          };
        });
      } else {
        setState((v: any) => ({
          data: dataSourceList?.data,
          changed: v.changed + 1,
          processed: true,
        }));
      }
      */
      setState((v: any) => ({
        dsId: v.dsId,
        data: dataSourceList?.data,
        changed: v.changed + 1,
        processed: true,
      }));
    }
  }, [dataSourceList, getBetsRelatedData, getSelectedBetsData, language]);

  return state;
};
