import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { fetchGameId } from '../slot-game-launcher';
import config from './utils/config';

type OmniplayLottoProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

/*
  Tickets Omniplay Lotto

  https://www.cnbfbtlotto.com/stage/winnerro/external/lottery/tickets?start_time=2024-11-01&end_time=2024-11-14&language=ro&player_id=2_244&offset=0&limit=30&total_count=1

  {"status":"success","total_count":3,"tickets":[{"ticketid":524,"currency":"RON","money_in":12.98,"money_in_tax":0,"money_out":0,"money_out_tax":0,"potential_win":150000,"odds":11556.24,"lottery_name":"Grecia Kino 20\/80","ticket_time":"2024-11-11 06:22:29","round_time":"2024-11-11 06:25:00","player_numbers":"4,5,6,13,14,15,22,23,24","round_numbers":"4,8,11,12,13,14,15,16,27,32,35,37,44,46,50,68,72,74,75,78","system":"8\/9","status":"Pierzator"},{"ticketid":523,"currency":"RON","money_in":1,"money_in_tax":0,"money_out":0,"money_out_tax":0,"potential_win":310,"odds":310,"lottery_name":"Grecia Kino 20\/80","ticket_time":"2024-11-11 06:22:03","round_time":"2024-11-11 06:25:00","player_numbers":"4,5,13,14","round_numbers":"4,8,11,12,13,14,15,16,27,32,35,37,44,46,50,68,72,74,75,78","system":"4\/4","status":"Pierzator"},{"ticketid":522,"currency":"RON","money_in":1,"money_in_tax":0,"money_out":0,"money_out_tax":0,"potential_win":21,"odds":21,"lottery_name":"Italia Keno 10eLotto 20\/90","ticket_time":"2024-11-11 05:55:21","round_time":"2024-11-11 06:00:00","player_numbers":"5,14","round_numbers":"8,13,25,33,35,40,42,44,47,52,53,62,63,69,78,79,81,82,83,88","system":"2\/2","status":"Pierzator"}]}
  {"status":"success","total_count":3,"tickets":[{"ticketid":524,"currency":"RON","money_in":12.98,"money_in_tax":0,"money_out":0,"money_out_tax":0,"potential_win":150000,"odds":11556.24,"lottery_name":"Kino Greece 20\/80","ticket_time":"2024-11-11 06:22:29","round_time":"2024-11-11 06:25:00","player_numbers":"4,5,6,13,14,15,22,23,24","round_numbers":"4,8,11,12,13,14,15,16,27,32,35,37,44,46,50,68,72,74,75,78","system":"8\/9","status":"Lost"},{"ticketid":523,"currency":"RON","money_in":1,"money_in_tax":0,"money_out":0,"money_out_tax":0,"potential_win":310,"odds":310,"lottery_name":"Kino Greece 20\/80","ticket_time":"2024-11-11 06:22:03","round_time":"2024-11-11 06:25:00","player_numbers":"4,5,13,14","round_numbers":"4,8,11,12,13,14,15,16,27,32,35,37,44,46,50,68,72,74,75,78","system":"4\/4","status":"Lost"},{"ticketid":522,"currency":"RON","money_in":1,"money_in_tax":0,"money_out":0,"money_out_tax":0,"potential_win":21,"odds":21,"lottery_name":"Italy Keno 10eLotto 20\/90","ticket_time":"2024-11-11 05:55:21","round_time":"2024-11-11 06:00:00","player_numbers":"5,14","round_numbers":"8,13,25,33,35,40,42,44,47,52,53,62,63,69,78,79,81,82,83,88","system":"2\/2","status":"Lost"}]}

  In asteptare, returnare miza, anulat, castigator, promo
*/

const OmniplayLotto = (componentProps: OmniplayLottoProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);
  const history = useNavigate();

  const { i18n } = useTranslation();
  const authentication = useAppSelector((state) => state.authentication);
  const [height, setHeight] = React.useState(800);

  const { link } = fetchGameId(
    window.config.omniplayLottoGameId,
    'user' === authentication.auth_type && window.config.omniplayLottoEnabled === '1',
  );

  const handlePostMessages = (e: MessageEvent) => {
    //console.log('OmniplayLotto[PostMessage]', e);
    if (e.origin === 'https://www.cnbfbtlotto.com') {
      let data: any = {};
      if (e.data) {
        try {
          const tmp = JSON.parse(e.data);
          if (tmp.type) {
            data = tmp;
          }
        } catch (err) {
          /**/
        }
      }

      if (data.type === 'new_resize' && data.data && data.data.height != null) {
        setHeight(data.data.height);
      } else if (data.type === 'login_popup') {
        history('/login');
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('message', handlePostMessages);

    return () => {
      window.removeEventListener('message', handlePostMessages);
    };
  }, []); // eslint-disable-line

  const contextValue = React.useMemo(() => {
    let gameUrl = '';
    if (link) {
      if (!link.includes('&language=' + i18n.language.toUpperCase())) {
        gameUrl = link.replace(/&language=[A-Z]{2}/i, '&language=' + i18n.language.toUpperCase());
      } else {
        gameUrl = link;
      }
    } else {
      gameUrl = config.demoLotto;
    }

    return {
      gameUrl,
      height,
      style: {
        display: 'block',
      },
    };
  }, [dataElementContext, componentProps, link, i18n.language, height]);

  if (window.config.omniplayLottoEnabled !== '1') return null;

  console.log('OmniplayLotto[contextValue]', contextValue, link);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <iframe
        style={contextValue.style}
        src={contextValue.gameUrl}
        className="lotto-iframe"
        height={height}
        width="100%"
        title="Lotto"
        scrolling="no"
        allowFullScreen={true}
      />
    </ModuleElementDiv>
  );
};

export default OmniplayLotto;
