import React, { useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, Link as NavigateLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { handleActions } from '../../components/action-handler/actions';
import { DataElementContext } from '../common/DataElementContext';
import { equalWithoutChildren } from '../../utils/fastDeepEqual';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { processTranslatableText } from '@/page-components/utils/functions';

const LinkComponent = styled.a((props) => props.$styleText);
const NavigateLinkComponent = styled(NavigateLink)((props) => props.$styleText);

export const Link = (componentProps) => {
  let props = componentProps;

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);


  let { href, target, actionType, eventName } = props.properties;

  const text = React.useMemo(() => {
    return processTranslatableText(props.properties.text, props.properties.placeholders, i18n.language, t);
  }, [props.properties.text, props.properties.placeholders, i18n.language, t]);

  if (!isVisible) return null;

  const handleClick = (e) => {
    if (actionType === 'event' && eventName) {
      handleActions(eventName, props, navigate, dataElementContext, e);
      return;
    }

    if (target === '_self' && href.indexOf('/') === 0) {
      navigate(href);
    }
  };

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      } else if (key === 'ariaLabel' || key === 'preventScrollReset') {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  if (actionType === 'react-router' || (actionType === 'event' && eventName === 'show-match-details')) {
    return (
      <NavigateLinkComponent
        to={href}
        className={props.className ?? ''}
        $styleText={props.styleText}
        style={props.style}
        dangerouslySetInnerHTML={{ __html: text }}
        onClick={props.properties.onClick}
        {...extraDataProps}
      />
    );
  }

  if (props?.properties?.title) {
    extraDataProps['title'] = props?.properties?.title;
  }

  let onClick = props.properties.onClick ?? handleClick;

  if (actionType === 'external-url' && window.config.cordova === true) {
    onClick = (e) => {
      const internalOnClick = props.properties.onClick ?? handleClick;

      try {
        internalOnClick(e);
      } catch (e) {}

      e.preventDefault();
      try {
        window.cordova.InAppBrowser.open(href, '_system');
      } catch (e) {}
    };
  }

  return (
    <LinkComponent
      className={props.className ?? ''}
      href={href}
      target={target !== '_self' ? target : null}
      $styleText={props.styleText}
      style={props.style}
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: text }}
      {...extraDataProps}
    />
  );
};

export default React.memo(Link, equalWithoutChildren);
