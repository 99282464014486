import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import urlonStringify from '@/utils/urlon';

import { RootState } from '../index';
import { fetchSources } from './dataSources';

const DEBUG = false;

type SlotGamesOrderMap = Record<string | 'length', number>;

interface SlotGame {
  id: number | string;
  name: string;
  provider_id: string;
}

type FetchConfigProps = any;

type FetchConfigResult = {
  data: SlotGamesOrderMap;
  success: boolean;
};

type FetchConfigError = {
  rejectValue: {
    error: string;
  };
};

const apiUrl = window.config.dataSourceApiUrl;
const dataSourceId = window.config.dataSourceAllPlayerGames;

export const fetchPlayerGamesOrder = createAsyncThunk<FetchConfigResult, FetchConfigProps, FetchConfigError>(
  'fetchPlayerGamesOrder/list',
  async (props, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      const response = await axios.get(`${apiUrl}/resolve/sources`, {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
        params: {
          q: urlonStringify({ ids: [dataSourceId] }),
        },
      });

      const idsMap = mapGamesToIdsMap(response.data[dataSourceId].data);

      return { data: idsMap, success: true };
    } catch (err: any) {
      const errResp = { error: err.toString() };

      console.log(errResp);
      return rejectWithValue(errResp);
    }
  },
);

interface PlayerGamesOrder {
  items: SlotGamesOrderMap;
  loading: boolean;
  loaded: boolean;
  error?: string;
}

export const playerGamesOrderSlice = createSlice({
  name: 'playerGamesOrder',
  initialState: <PlayerGamesOrder>{
    items: {
      length: 0,
    },
    loading: false,
    loaded: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlayerGamesOrder.fulfilled, (state, action) => {
        if (action.payload.success && action.payload.data && Array.isArray(action.payload.data)) {
          state.loading = false;
          state.loaded = true;
          state.items = action.payload.data;
        }

        DEBUG && console.log('fetchPlayerGamesOrder.fulfilled', action.payload);
      })
      .addCase(fetchPlayerGamesOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPlayerGamesOrder.rejected, (state, action) => {
        state.loading = false;
        state.loaded = false;
        state.error = action.payload?.error;

        DEBUG && console.log('fetchPlayerGamesOrder.rejected', action.payload);
      })
      .addCase(fetchSources.fulfilled, (state, action) => {
        if (action.payload.success && action.payload.data && action.payload.data[dataSourceId]) {
          state.loading = false;
          state.loaded = true;
          state.items = mapGamesToIdsMap(action.payload.data[dataSourceId].data);
        }
      });
  },
});

function isGameDataSource(dataSource: any): boolean {
  return dataSource && dataSource?.element_type?.type_id === 'slot_game';
}

function mapGamesToIdsMap(data: SlotGame[]): SlotGamesOrderMap {
  const map: SlotGamesOrderMap = {};

  data.forEach(({ id }, index) => (map[id] = index));

  map.length = data.length;

  return map;
}

const BLACKLIST: string[] = ['slot-games-cold', 'slot-games-hot', 'slot-games-popular-now'];

export function sortGamesDataSources(
  dataSources: Record<string, any>,
  orderMap: SlotGamesOrderMap,
): Record<string, any> {
  const gamesDataSources: Record<string, any> = {};

  if (!orderMap.length && dataSources[dataSourceId]) {
    orderMap = mapGamesToIdsMap(dataSources[dataSourceId].data);
  }

  if (!orderMap.length) {
    return gamesDataSources;
  }

  Object.keys(dataSources).forEach((sourceId) => {
    if (sourceId === dataSourceId || BLACKLIST.includes(sourceId)) {
      return;
    }

    const dataSource = dataSources[sourceId];

    if (isGameDataSource(dataSource)) {
      gamesDataSources[sourceId] = {
        ...dataSource,
        data: dataSource.data.sort((a: SlotGame, b: SlotGame) => {
          // If not found in order map, treat it as the last item
          const firstItem = orderMap[a.id] ?? orderMap.length;
          const secondItem = orderMap[b.id] ?? orderMap.length + 1;

          return firstItem - secondItem;
        }),
      };
    }
  });

  return gamesDataSources;
}

export function listHasGamesDataSources(ids: string[]): boolean {
  return ids.some((id) => id.toLowerCase().includes('games'));
}

export function responseHasGamesDataSources(dataSources: Record<string, any>): boolean {
  let hasGameDataSources = false;

  Object.keys(dataSources).every((sourceId) => {
    const dataSource = dataSources[sourceId];

    if (isGameDataSource(dataSource)) {
      hasGameDataSources = true;

      return false;
    }

    return true;
  });

  return hasGameDataSources;
}

export default playerGamesOrderSlice.reducer;
