import { FeatureCategory, FeatureLocation, FeatureSubcategory } from '../types/eventData';
import { logEvent } from './logEvent';

// Helper function for logging the event
export const logBetRecommendationEvent = (event: any, betRecomandationState: any, betRecomandationProps: any) => {
  try {
    // Bundle all analytics-specific work into the logEvent call
    // so it only runs if analytics are enabled
    logEvent(
      event,
      {
        // feature_used: identifyFeatureUsed(betDetails),
        feature_used: identifyFeatureUsed(betRecomandationState, betRecomandationProps),
        feature_category: FeatureCategory.BetsRecommendation,
        feature_subcategory: identifyFeatureSubcategory(betRecomandationState, betRecomandationProps),
        feature_location: identifyFeatureLocation(betRecomandationState, betRecomandationProps),
      },
      {
        skipped_tickets: betRecomandationState.currentList.map((match: any) => {
          return {
            brmId: match?.brmId,
            brmMotivations: match?.brmMotivations,
            mType: match?.mType,
            idSport: match?.idSport,
            idMatch: match?.idMatch,
            idBet: match?.matchBets[0]?.idBet,
            idMb: match?.matchBets[0]?.idMb,
          };
        }),
      },
    );
  } catch (error) {
    console.error('Error logging event:', error);
  }
};

/**
 * Identify the feature used based on the bet details.
 *
 * @returns A string representing the feature used.
 */
const identifyFeatureUsed = (betRecomandationState: any, betRecomandationProps: any): string => {
  // if betRecomandationProps?.properties?.filterByIdMatch is undefined and length ===1 it might be the bet recommendation from the betslip

  if (betRecomandationState?.currentList?.length === 1) {
    if (betRecomandationProps?.properties?.filterByIdMatch) {
      return 'Single match other recommendation';
    }

    return 'Single match other recommendations from betslip';
  }

  return 'Multiple matches other recommendation';
};

/**
 * Identify the feature subcategory based on the bet details.
 *
 * @returns A string representing the feature subcategory.
 */
const identifyFeatureSubcategory = (betRecomandationState: any, betRecomandationProps: any): FeatureSubcategory => {
  return FeatureSubcategory.Unspecified;
};

/**
 * Identify the feature location based on the bet details.
 *
 * @returns A string representing the feature location.
 */
const identifyFeatureLocation = (betRecomandationState: any, betRecomandationProps: any): FeatureLocation => {
  if (betRecomandationState?.currentList?.length === 1) {
    return FeatureLocation.Betslip;
  }

  return FeatureLocation.MainContent;
};
