// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { RootState } from '../index';
import { endpoints } from '../../api/endpoints';

export interface HotspinsJackpotGame {
  id: string;
  game_type: string;
  game_data: {
    width: number;
    height: number;
  };
  status: string;
  created_at: string;
  updated_at: string;
  expires_at: string;
  tiles: string;
  jackpot_values: {
    a: string;
    b: string;
    c: string;
    d: string;
  };
}

type FetchHJGameResult = {
  data: HotspinsJackpotGame;
  success: boolean;
};

type FetchHJError = {
  rejectValue: {
    error: string;
  };
};

export const fetchHotspinsJackpotGame = createAsyncThunk<FetchHJGameResult, void, FetchHJError>(
  'hotspinsJackpotGame/list',
  async (_params, { rejectWithValue, getState }) => {
    const state: RootState = getState() as RootState;

    try {
      if (!state.authentication.access_token) {
        return rejectWithValue({
          error: 'No access token',
        });
      }

      const response = await axios.get(`${endpoints.hotspinsJackpot.getGame}`, {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
      });

      if (response.data) {
        return { data: response.data, success: true };
      }

      return rejectWithValue({
        error: "Couldn't fetch jackpot game data",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

export interface HotspinsJackpotGameReducer {
  game: HotspinsJackpotGame | null;
  loading: boolean;
  error: any;
}

export const hotspinsJackpotGameSlice = createSlice({
  name: 'hotspinsJackpotGame',
  initialState: <HotspinsJackpotGameReducer>{
    game: null,
    loading: false,
    error: null,
  },
  reducers: {
    reset: (state) => {
      state.game = null;
      state.loading = false;
      state.error = null;
    },
    setGame: (state, action) => {
      state.game = action.payload.data;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHotspinsJackpotGame.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.success) {
          state.game = action.payload.data;
        }
      })
      .addCase(fetchHotspinsJackpotGame.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHotspinsJackpotGame.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        console.log('fetchHotspinsJackpotGame.rejected', action.payload);
      });
  },
});

export const { reset, setGame } = hotspinsJackpotGameSlice.actions;

export default hotspinsJackpotGameSlice.reducer;
