const PasswordValidator = (
  password: string,
  config?: {
    // changed all to string for easy Consul editing
    // minimum_length: number;
    // maximum_length: number;
    // medium_length: number;
    // long_length: number;
    // uppercase: boolean;
    // lowercase: boolean;
    // digit: boolean;
    // special: boolean;
    // letter: boolean;
    // labels: any[];
    minimum_length: string;
    maximum_length: string;
    medium_length: string;
    long_length: string;
    uppercase: string;
    lowercase: string;
    digit: string;
    special: string;
    letter: string;
    labels: any[];
    restrictSpecialCharacters: string;
  },
) => {
  if (!config) {
    config = window.config.password;
  }
  const {
    labels,
    minimum_length,
    maximum_length,
    uppercase,
    lowercase,
    digit,
    special,
    medium_length,
    long_length,
    letter,
    restrictSpecialCharacters,
  } = config;

  const parsedMinimumLength = parseInt(minimum_length, 10);
  const parsedMaximumLength = parseInt(maximum_length, 10);
  const parsedMediumLength = parseInt(medium_length, 10);
  const parsedLongLength = parseInt(long_length, 10);
  const parsedUppercase = uppercase === '1' ? true : false;
  const parsedLowercase = lowercase === '1' ? true : false;
  const parsedDigit = digit === '1' ? true : false;
  const parsedSpecial = special === '1' ? true : false;
  const parsedLetter = letter === '1' ? true : false;
  const parsedRestrictSpecialCharacters = restrictSpecialCharacters === '1' ? true : false;

  // score based on required fields from config
  let score = 0;
  // total score based on all possible fields from config
  let totalScore = 0;

  // maximum score represents the total number of possible requirements, not only the required ones
  // TODO: increase XXX in (Object.keys(config).length - XXX) when you add a new config key to the password object that should not be counted as a requirement
  // const maxScoreLimit = Object.keys(config).length - 2; // ignore labels and maximum_length
  const maxScoreLimit = Object.keys(config).length - 3; // ignore labels , maximum_length and restrictSpecialCharacters
  // list of conditions not met
  const conditionsNotMet = [];
  // ignore minimum_length and medium_length; this will decrease in the next lines of code
  let minimumScoreRequired = maxScoreLimit - 2;
  // check length
  if (password.length >= parsedMinimumLength && password.length <= parsedMaximumLength) {
    score++;
    totalScore++;
    if (password.length >= parsedMediumLength) {
      totalScore++;
    }
    if (password.length >= parsedLongLength) {
      totalScore++;
    }
  } else {
    conditionsNotMet.push('length');
  }

  // check uppercase
  if (!parsedUppercase) {
    minimumScoreRequired--;
  }
  if (/[A-Z]/.test(password)) {
    totalScore++;
    if (parsedUppercase) {
      score++;
    }
  } else {
    if (parsedUppercase) {
      conditionsNotMet.push('uppercase');
    }
  }

  // check letter
  if (!parsedLetter) {
    minimumScoreRequired--;
  }
  if (/[A-Za-z]/.test(password)) {
    totalScore++;
    if (parsedLetter) {
      score++;
    }
  } else {
    if (parsedLetter) {
      conditionsNotMet.push('letter');
    }
  }

  // check lowercase
  if (!parsedLowercase) {
    minimumScoreRequired--;
  }
  if (/[a-z]/.test(password)) {
    totalScore++;
    if (parsedLowercase) {
      score++;
    }
  } else {
    if (parsedLowercase) {
      conditionsNotMet.push('lowercase');
    }
  }

  // check digit
  if (!parsedDigit) {
    minimumScoreRequired--;
  }
  if (/[0-9]/.test(password)) {
    totalScore++;
    if (parsedDigit) {
      score++;
    }
  } else {
    if (parsedDigit) {
      conditionsNotMet.push('digit');
    }
  }

  // check special characters
  if (!parsedSpecial || parsedRestrictSpecialCharacters) {
    minimumScoreRequired--;
  }

  if (/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) {
    totalScore++;
    if (parsedSpecial) {
      score++;
    }
  } else {
    if (parsedSpecial && !parsedRestrictSpecialCharacters) {
      conditionsNotMet.push('special');
    }
  }

  let finalScore = Math.floor((totalScore / maxScoreLimit) * 100);

  // minimum score should not be 0
  // this is to prevent the password strength meter from showing the CMS default values
  if (finalScore == 0) {
    finalScore = 0.001;
  }

  const passwordAccepted = score >= minimumScoreRequired;
  let shortLabel = labels?.[0]?.label ?? 'Labels not found.';
  labels.forEach((label) => {
    if (finalScore >= label.minimum_score) {
      shortLabel = label.label;
    }
  });
  const longLabel = `Password strength is ${shortLabel.toLowerCase()}.`; // used as placeholder for the message

  let message = 'Password must contain:';

  for (const cond of conditionsNotMet) {
    if (cond === 'letter') message += ' a letter,';
    if (cond === 'lowercase') message += ' a lowercase letter,';
    if (cond === 'uppercase') message += ' an uppercase letter,';
    if (cond === 'digit') message += ' a digit,';
    if (cond === 'special') message += ' a special character,';
    if (cond === 'length') message += ` ${parsedMinimumLength} to ${parsedMaximumLength} characters,`;
  }

  if (message.endsWith(',')) {
    message = message.slice(0, -1);
    message += '.';
  } else {
    message = longLabel;
  }

  return {
    score: finalScore,
    success: passwordAccepted,
    conditionsNotMet: conditionsNotMet,
    message: message,
    labels: {
      short: shortLabel,
      long: longLabel,
    },
  };
};

export default PasswordValidator;
