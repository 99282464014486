import { takeEvery, takeLatest, put, cancelled } from 'redux-saga/effects';
import axios from 'axios';
import getStore from '../../store';
import { debug } from '../../utils';

import { betsSlipConstants } from '../actions/constants';
import { betsSlipSetStakeTax, betsSlipSetWinTax } from '../actions/betsSlip';

import { endpoints } from '@/api/endpoints';

function* computeStakeTaxSaga(action) {
  yield debug('computeStakeTaxSaga: request', action);

  const state = getStore().getState();
  const { authentication } = state;

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    throw Error('not authenticated');
  }

  const { data: st } = yield axios.post(
    endpoints.digitain.taxEval,
    {
      taxes: [
        {
          taxType: 3, // const SPORT_STAKE = 3;
          totalAmount: Math.round(action.taxableAmount * 100),
          key: 'stake',
        },
      ],
    },
    {
      headers: {
        Authorization: 'Bearer ' + state.authentication.access_token,
      },
    },
  );

  debug('computeStakeTaxSaga: response', st);

  if (st.status === 'OK' && st.result && st.result.stake) {
    yield put(
      betsSlipSetStakeTax(
        action.totalAmount,
        st.result.stake.originalAmount / 100,
        st.result.stake.amountAfterTax / 100,
        st.result.stake.taxAmount / 100,
      ),
    );
  }
}

function* computeWinTaxSaga(action) {
  yield debug('computeWinTaxSaga', action);

  const state = getStore().getState();
  const { authentication } = state;

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    throw Error('not authenticated');
  }

  const { data: st } = yield axios.post(
    endpoints.digitain.taxEval,
    {
      taxes: [
        {
          taxType: 4, // const SPORT_WIN = 4;
          totalAmount: Math.round(action.maxAmount * 100),
          notTaxableAmount: Math.round((action.nonTaxableMaxAmount ?? 0) * 100),
          key: 'maxWin',
        },
        {
          taxType: 4, // const SPORT_WIN = 4;
          totalAmount: Math.round(action.minAmount * 100),
          notTaxableAmount: Math.round((action.nonTaxableMinAmount ?? 0) * 100),
          key: 'minWin',
        },
      ],
    },
    {
      headers: {
        Authorization: 'Bearer ' + state.authentication.access_token,
      },
    },
  );

  debug('computeStakeTaxSaga: response', st);

  let maxAmount = 0,
    taxedMaxAmount = 0,
    maxTax = 0;

  if (st.status === 'OK' && st.result && st.result.maxWin) {
    maxAmount = st.result.maxWin.originalAmount / 100;
    taxedMaxAmount = st.result.maxWin.amountAfterTax / 100;
    maxTax = st.result.maxWin.taxAmount / 100;
  } else {
    console.error('computeWinTaxSaga: maxWin not found', st);
  }

  let minAmount = 0,
    taxedMinAmount = 0,
    minTax = 0;

  if (st.status === 'OK' && st.result && st.result.minWin) {
    minAmount = st.result.minWin.originalAmount / 100;
    taxedMinAmount = st.result.minWin.amountAfterTax / 100;
    minTax = st.result.minWin.taxAmount / 100;
  } else {
    console.error('computeWinTaxSaga: minWin not found', st);
  }

  yield put(betsSlipSetWinTax(maxAmount, taxedMaxAmount, maxTax, minAmount, taxedMinAmount, minTax));
}

export default function* watchTaxSaga() {
  yield takeEvery(betsSlipConstants.COMPUTE_STAKE_TAX, computeStakeTaxSaga);
  yield takeEvery(betsSlipConstants.COMPUTE_WIN_TAX, computeWinTaxSaga);
}
