const mergeCfg = (cfg1, cfg2) => {
    for (const k in cfg2) {
        if (k in cfg1) {
            continue;
        }
        cfg1[k] = cfg2[k];
    }
    return cfg1;
};

const digitainDevConfig = (config) => ({
    wsUrl: `${config.digitainStagingApiUrl}`,
    wsPath: '/api/digitain-fetcher/v2/live/ws',
    apiURL: `${config.digitainStagingApiUrl}/api/digitain-fetcher/v2/public`,

    ticketsWsUrl: `${config.digitainStagingApiUrl}`,
    ticketsWsPath: '/api/digitain-tickets/v1/ws',
    ticketsUrl: `${config.digitainStagingApiUrl}/api/digitain-tickets/v1/player`,
    ticketsPublicUrl: `${config.digitainStagingApiUrl}/api/digitain-tickets/v1/public`,
    searchUrl: `${config.digitainStagingApiUrl}/api/digitain-fetcher/v2/public/search`,

    widgetUrl: `https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=${config.digitainStagingPartnerId}&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true`,
    widgetLiveTvUrl: `https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=${config.digitainStagingPartnerId}&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]`,
    widgetCheckUrl: `https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=${config.digitainStagingPartnerId}&responseTypeId=1&langIsoCode=en&ids=[MATCHID]`,
    betBuilderUrl: `${config.digitainStagingApiUrl}/api/digitain-tickets/v1/public/bet-builder`,

    defaultSportId: '1',
    marketCountNsoft: 3,

    statsUrl: `${config.digitainStagingApiUrl}/api/stats/v1/public/sports/event`,
    leagueStatsUrl: `${config.digitainStagingApiUrl}/api/stats/v1/public/sports/euro-2024/team`,
});

const digitainMicrosConfig = (config) => ({
    wsUrl: `${config.digitainProductionApiUrl}`,
    wsPath: '/api/digitain-fetcher/v2/live/ws',
    apiURL: `${config.digitainProductionApiUrl}/api/digitain-fetcher/v2/public`,

    ticketsWsUrl: `${config.digitainProductionApiUrl}`,
    ticketsWsPath: '/api/digitain-tickets/v1/ws',
    ticketsUrl: `${config.digitainProductionApiUrl}/api/digitain-tickets/v1/player`,
    ticketsPublicUrl: `${config.digitainProductionApiUrl}/api/digitain-tickets/v1/public`,
    searchUrl: `${config.digitainProductionApiUrl}/api/digitain-fetcher/v2/public/search`,

    widgetUrl: `https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=${config.digitainProductionPartnerId}&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true`,
    widgetLiveTvUrl: `https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=${config.digitainProductionPartnerId}&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]`,
    widgetCheckUrl: `https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=${config.digitainProductionPartnerId}&responseTypeId=1&langIsoCode=en&ids=[MATCHID]`,
    betBuilderUrl: `${config.digitainProductionApiUrl}/api/digitain-tickets/v1/public/bet-builder`,

    defaultSportId: '1',
    marketCountNsoft: 3,

    statsUrl: `${config.digitainProductionApiUrl}/api/stats/v1/public/sports/event`,
    leagueStatsUrl: `${config.digitainProductionApiUrl}/api/stats/v1/public/sports/euro-2024/team`,
});

const digitainWinnerFunDevConfig = (config) => ({
    wsUrl: `${config.digitainStagingApiUrlWF}`,
    wsPath: '/api/digitain-fetcher/v2/live/ws',
    apiURL: `${config.digitainStagingApiUrlWF}/api/digitain-fetcher/v2/public`,

    ticketsWsUrl: `${config.digitainStagingApiUrlWF}`,
    ticketsWsPath: '/api/digitain-tickets-wf/v1/ws',
    ticketsUrl: `${config.digitainStagingApiUrlWF}/api/digitain-tickets-wf/v1/player`,
    ticketsPublicUrl: `${config.digitainStagingApiUrlWF}/api/digitain-tickets-wf/v1/public`,
    searchUrl: `${config.digitainStagingApiUrlWF}/api/digitain-fetcher/v2/public/search`,

    widgetUrl: `https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=${config.digitainStagingPartnerIdWF}&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true`,
    widgetLiveTvUrl: `https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=${config.digitainStagingPartnerIdWF}&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]`,
    widgetCheckUrl: `https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=${config.digitainStagingPartnerIdWF}&responseTypeId=1&langIsoCode=en&ids=[MATCHID]`,
    betBuilderUrl: `${config.digitainStagingApiUrlWF}/digitain-tickets-wf/public/bet-builder`,

    defaultSportId: '1',
    marketCountNsoft: 3,

    statsUrl: `${config.digitainStagingApiUrlWF}/api/stats/v1/public/sports/event`,
    leagueStatsUrl: `${config.digitainStagingApiUrlWF}/api/stats/v1/public/sports/euro-2024/team`,
});

const digitainWinnerFunMicrosConfig = (config) => ({
    wsUrl: `${config.digitainProductionApiUrlWF}`,
    wsPath: '/api/digitain-fetcher/v2/live/ws',
    apiURL: `${config.digitainProductionApiUrlWF}/api/digitain-fetcher/v2/public`,

    ticketsWsUrl: `${config.digitainProductionApiUrlWF}`,
    ticketsWsPath: '/api/digitain-tickets-wf/v1/ws',
    ticketsUrl: `${config.digitainProductionApiUrlWF}/api/digitain-tickets-wf/v1/player`,
    ticketsPublicUrl: `${config.digitainProductionApiUrlWF}/api/digitain-tickets-wf/v1/public`,
    searchUrl: `${config.digitainProductionApiUrlWF}/api/digitain-fetcher/v2/public/search`,

    widgetUrl: `https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=${config.digitainProductionPartnerIdWF}&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true`,
    widgetLiveTvUrl: `https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=${config.digitainProductionPartnerIdWF}&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]`,
    widgetCheckUrl: `https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=${config.digitainProductionPartnerIdWF}&responseTypeId=1&langIsoCode=en&ids=[MATCHID]`,
    betBuilderUrl: `${config.digitainProductionApiUrlWF}/api/digitain-tickets-wf/v1/public/bet-builder`,

    defaultSportId: '1',
    marketCountNsoft: 3,

    statsUrl: `${config.digitainProductionApiUrlWF}/api/stats/v1/public/sports/event`,
    leagueStatsUrl: `${config.digitainProductionApiUrlWF}/api/stats/v1/public/sports/euro-2024/team`,
});

// we need to do it like this because worker doesn't have access to window object
const getGlobalConfig = () => {
    try {
        if (typeof window !== 'undefined' && window.config) {
            return window.config;
        }
    } catch (e) {
    }

    try {
        if (globalThis?.global?.config) {
            return globalThis.global.config;
        }
    } catch (e) {
    }

    return {};
};

let digitainConfig = () => {
    let config = getGlobalConfig();

    if (config.environment === 'staging') {
        return mergeCfg(digitainDevConfig(config), digitainMicrosConfig(config));
    }
    return digitainMicrosConfig(config);
};

let digitainWinnerFunConfig = () => {
    let config = getGlobalConfig();

    if (config.environment === 'staging') {
        return mergeCfg(digitainWinnerFunDevConfig(config), digitainWinnerFunMicrosConfig(config));
    }
    return digitainWinnerFunMicrosConfig(config);
};

export { digitainConfig, digitainWinnerFunConfig };
