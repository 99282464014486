import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { appOddLimiterToggle, appOddLimiterValue } from '@/modules/bets/store/actions/app';

//import './index.scss';

type OddLimiterProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const OddLimiter = (componentProps: OddLimiterProps) => {
  let props = componentProps;

  const dispatch = useAppDispatch();
  const oddLimiterEnabled = useAppSelector<any>((state) => state.bets.app.oddLimiterEnabled);
  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  React.useEffect(() => {
    const value = parseFloat(props?.properties?.oddValue ?? 0);

    if (!isNaN(value)) {
      // @ts-ignore
      dispatch(appOddLimiterValue(value));
    }
  }, [props?.properties?.oddValue]);

  const onChange = React.useCallback(() => {
    dispatch(appOddLimiterToggle());
  }, []);

  const contextValue = React.useMemo(() => {
    const oddValue = props?.properties?.oddValue ?? 0;
    return {
      oddValue,
      enabled: oddLimiterEnabled,
      onChange,
    };
  }, [dataElementContext, componentProps, onChange, oddLimiterEnabled]);

  //console.log('OddLimiter[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default OddLimiter;
