// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { endpoints } from '../../api/endpoints';

export type Maintenance = {
  maintenance: any[];
  ip: string;
  slots: any[];
  products: any[];
  providers: any[];
};

type FetchMaintenanceProps = {
  tenantId: string;
  siteId: string;
};

type FetchMaintenanceResult = {
  data: any[];
  success: boolean;
};

type FetchMaintenanceError = {
  rejectValue: {
    error: string;
  };
};

export const fetchMaintenance = createAsyncThunk<FetchMaintenanceResult, FetchMaintenanceProps, FetchMaintenanceError>(
  'maintenance/list',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${endpoints.config.maintenance}?tenantId=${window.config.clientId}&siteId=${window.config.siteId}`,
      );

      if (response.data) {
        return { data: response.data, success: true };
      }

      return rejectWithValue({
        error: "Couldn't fetch maintenance data",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

export interface MaintenanceReducer {
  config: Maintenance[];
  loading: boolean;
  error: any;
}

export const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState: <MaintenanceReducer>{
    config: [],
    loading: false,
    error: null,
  },
  reducers: {
    reset: (_state) => {
      /* example */
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMaintenance.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.success) {
          state.config = action.payload.data;
        }
      })
      .addCase(fetchMaintenance.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMaintenance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        console.log('fetchMaintenance.rejected', action.payload);
      });
  },
});

export const { reset } = maintenanceSlice.actions;

export default maintenanceSlice.reducer;
