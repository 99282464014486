import { takeEvery, put } from 'redux-saga/effects';
import {
  loadingLeaderBoard,
  loadedLeaderBoard,
  loadingPlayerLeaderBoard,
  loadedPlayerLeaderBoard,
} from '../actions/leader_board';
import { leaderBoardConstants } from '../actions/constants';
import getStore from '../../store';
import axios from 'axios';

import { isEqual } from 'lodash-es';

//let apiUrl = window.config.betsApiUrl + "/casinoapi/echo-service";
let apiUrl = window.config.betsApiUrl + '/missions';

const l_request_in_progress = {};

function* loadLeaderBoardSaga(action) {
  if (l_request_in_progress[action.tournamentId]) return;

  const { authentication } = getStore().getState();
  const top = getStore().getState().tournamentsMissions.leaderboard.top;

  const tournamentId = action.tournamentId;
  if (!tournamentId) return;
  l_request_in_progress[action.tournamentId] = true;

  yield put(loadingLeaderBoard());

  let headers = {};
  let withAuthentication = false;

  if (authentication.access_token) {
    headers = {
      Authorization: 'Bearer ' + authentication.access_token,
    };
  } else {
    return;
  }

  if (authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1) {
    withAuthentication = true;
  }

  try {
    const resp = yield axios.get(apiUrl + '/tournaments/leaderboard/' + tournamentId, { headers });

    const data = { ...resp.data, withAuthentication };

    if (!top[tournamentId] || !isEqual(top[tournamentId], data)) {
      yield put(loadedLeaderBoard({ tournamentId, data }));
    }

    delete l_request_in_progress[action.tournamentId];
  } catch (err) {
    console.log('loadLeaderBoardSaga[ERR]:', err);
    delete l_request_in_progress[action.tournamentId];
  }
}

const pl_request_in_progress = {};

function* loadPlayerLeaderBoardSaga(action) {
  if (pl_request_in_progress[action.tournamentId]) return;

  const { authentication } = getStore().getState();
  const player = getStore().getState().tournamentsMissions.leaderboard.player;

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  const tournamentId = action.tournamentId;
  if (!tournamentId) return;
  pl_request_in_progress[tournamentId] = true;

  yield put(loadingPlayerLeaderBoard());

  const headers = {
    Authorization: 'Bearer ' + authentication.access_token,
  };

  try {
    const resp = yield axios.get(
      apiUrl + '/tournament/position/' + tournamentId + `?in_game=${action.inGame ? 'true' : ''}`,
      { headers },
    );

    if (!player[tournamentId] || !isEqual(player[tournamentId], resp.data)) {
      yield put(loadedPlayerLeaderBoard({ tournamentId, data: resp.data }));
    }

    delete pl_request_in_progress[tournamentId];
  } catch (err) {
    console.log('loadPlayerLeaderBoardSaga[ERR]:', err);
    delete pl_request_in_progress[tournamentId];
  }
}

export default function* watchLeaderBoardSaga() {
  yield takeEvery(leaderBoardConstants.LOAD, loadLeaderBoardSaga);
  yield takeEvery(leaderBoardConstants.LOAD_PLAYER, loadPlayerLeaderBoardSaga);
}
