import React, { useContext } from 'react';
import styled from 'styled-components';
import Logger from '../../utils/logger';

import { DataElementContext } from '../common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

const logger = Logger('HtmlTagFactory');

export const defaultProps = {
  className: '',
  styleText: '',
};

type HtmlTag = {
  className: string;
  $styleText: string;
  style: any;
  children?: React.ReactNode; // best, accepts everything React can render
};

const HtmlTagDiv = styled.div<HtmlTag>((props) => props.$styleText);
const HtmlTagInput = styled.input<HtmlTag>((props) => props.$styleText);
const HtmlTagTextarea = styled.textarea<HtmlTag>((props) => props.$styleText);
const HtmlTagLabel = styled.label<HtmlTag>((props) => props.$styleText);
const HtmlTagCanvas = styled.canvas<HtmlTag>((props) => props.$styleText);
const HtmlTagIframe = styled.iframe<HtmlTag>((props) => props.$styleText);

export const HtmlTagFactory = (tag: string) =>
  function HtmlTag(componentProps: HtmlTag) {
    let props: any = componentProps;
    const dataElementContext = useContext(DataElementContext);
    let isVisible = true;

    [props, isVisible] = processComponentProps(props, dataElementContext);

    if (!isVisible) return null;

    const extra: any = {};

    if (props?.properties?.id) extra.id = props.properties.id;

    if (tag === 'label') {
      if (props?.properties?.for) extra.htmlFor = props.properties?.for;
    }

    if (tag === 'canvas') {
      if (props?.properties?.width) extra.width = props.properties.width;
      if (props?.properties?.height) extra.height = props.properties.height;
    }

    if (tag === 'iframe') {
      if (props?.properties?.width) extra.width = props.properties.width;
      if (props?.properties?.height) extra.height = props.properties.height;
      if (props?.properties?.src) extra.src = props.properties.src;
      if (props?.properties?.allow) extra.allow = props.properties.allow;
      if (props?.properties?.title) extra.title = props.properties.title;
      if (props?.properties?.loading) extra.loading = props.properties.loading;
      if (props?.properties?.name) extra.name = props.properties.name;
      if (props?.properties?.referrerpolicy) extra.referrerpolicy = props.properties.referrerpolicy;
      if (props?.properties?.sandbox) extra.sandbox = props.properties.sandbox;
      if (props?.properties?.scrolling) extra.scrolling = props.properties.scrolling;
    }

    //logger.debug(`create tag ${props.tag}`);

    if (tag === 'div') return <HtmlTagDiv {...props}>{componentProps.children}</HtmlTagDiv>;
    if (tag === 'input') return <HtmlTagInput {...props}>{componentProps.children}</HtmlTagInput>;
    if (tag === 'textarea') return <HtmlTagTextarea {...props}>{componentProps.children}</HtmlTagTextarea>;
    if (tag === 'label')
      return (
        <HtmlTagLabel $styleText={props.styleText} style={props.style} className={props.className} {...extra}>
          {componentProps.children}
        </HtmlTagLabel>
      );
    if (tag === 'canvas')
      return <HtmlTagCanvas $styleText={props.styleText} style={props.style} className={props.className} {...extra} />;
    if (tag === 'iframe')
      return <HtmlTagIframe $styleText={props.styleText} style={props.style} className={props.className} {...extra} />;

    logger.error(`unknown tag ${props.tag}`);
    return null;
  };
