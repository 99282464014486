import React from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

type ListIterationProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const ListIteration = (componentProps: ListIterationProps) => {
  let props = componentProps;
  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const [index, setIndex] = React.useState(0);

  // Reset index when list is updated through props
  React.useEffect(() => {
    setIndex(0);
  }, [props.properties.list]);

  const onNext = React.useCallback(() => {
    setIndex((prevIndex) => {
      const nextStep = prevIndex + 1;

      return nextStep < props.properties.list.length ? nextStep : 0;
    });
  }, [props.properties.list]);

  const onPrev = React.useCallback(() => {
    setIndex((prevIndex) => {
      const prevStep = prevIndex - 1;

      return prevStep > 0 ? prevStep : props.properties.list.length - 1;
    });
  }, [props.properties.list]);

  const contextValue = React.useMemo(() => {
    return {
      index,
      currentStep: index + 1,
      totalSteps: props.properties.list.length,
      list: props.properties.list,
      showControls: props.properties.list.length > 1,
      onNext,
      onPrev,
    };
  }, [dataElementContext, componentProps, index, onNext, onPrev, props]);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default ListIteration;
