import { InteractionData } from '../types/eventData';

/**
 * Tries to get various information about the element by traversing the parents.
 *
 * @param element The HTML element.
 * @returns An object containing various information about the element.
 */
const tryGetElementInfo = (element: HTMLElement): Record<string, string> => {
  const element_info: Record<string, string> = {};
  let parent = element;

  while (parent) {

    const data = parent.dataset;

    if (data.evtCollectionId) {
      if (!element_info['element_category']) {
        element_info['element_category'] = data.evtCollectionId;
      }
    }

    if (data.evtId) {
      if (!element_info['element_id']) {
        element_info['element_id'] = data.evtId;
      }
    }

    if (element_info['element_category'] && element_info['element_id']) {
      break;
    }

    parent = parent.parentElement as HTMLElement;
  }

  return element_info;

};

export const getInteractionData = (
  event: Event | MouseEvent | TouchEvent | KeyboardEvent | null
): InteractionData => {

  // identify type of event
  switch (event?.type) {
    case 'click':
      return getClickInteractionData(event as MouseEvent);
    case 'animationstart':
      return getClickInteractionData(event as MouseEvent);

    // case 'touchstart':
    // return getClickInteractionData(event as MouseEvent);

    default:
      return {
        interaction_type: 'unknown',
        element_id: '',
        element_type: '',
        element_data_source: '',
        element_text: '',
        element_position: '',
        element_state: '',
        click_position: { x: 0, y: 0 },
        element_category: null,
        element_label: ''
      };
  };
};

const getClickInteractionData = (
  event: MouseEvent
): InteractionData => {
  // Extract click-specific data
  const element = event.target as HTMLElement || document.body;
  const element_info = tryGetElementInfo(element);

  return {
    interaction_type: 'click',
    element_id: element.id || '',
    element_type: element.tagName.toLowerCase(), // e.g., 'button', 'a'
    element_data_source: element.getAttribute('data-tracking') || '',
    element_text: element.textContent || '',
    element_position: getElementPosition(element),
    element_state: element.getAttribute('aria-disabled') || 'enabled', // Example for state
    click_position: getClickCoordinates(event),

    // Get the containing category of the element
    element_category: element_info['element_category'] || null,

    // element label
    element_label: element_info['element_label'] || ''
  };
};

/**
 * Gets the position of an element relative to the viewport.
 *
 * @param element The HTML element.
 * @returns A string representing the position (e.g., 'top: 100px; left: 50px;')
 */
const getElementPosition = (element: HTMLElement): string => {
  const rect = element.getBoundingClientRect();
  return `top: ${rect.top}px; left: ${rect.left}px;`;
};


function getClickCoordinates(event: MouseEvent): { x: number, y: number; } {
  // Get the click coordinates relative to the whole visible page in percentage
  return { x: event.pageX, y: event.pageY };
}