import { logEvent } from '../events/logEvent';
import { ProductData } from '../types/eventData';

export const trackFunction = <T extends (...args: any[]) => any>(
  func: T,
  product: ProductData,
  eventDetails: Record<string, any> | string = '',
): T => {
  const wrappedFunc = (...args: Parameters<T>): ReturnType<T> => {
    // Prepare the event details
    const trackingDetails = typeof eventDetails === 'string' ? { event_details: eventDetails } : eventDetails;
    // Call the tracking function
    logEvent(args[0], product, trackingDetails);

    return func(...args);
  };
  return wrappedFunc as T;
};
