import { FeatureCategory, FeatureLocation, FeatureSubcategory } from '../types/eventData';
import { logEvent } from './logEvent';
// Helper function for logging the event
export const logSearchBarEvent = (event: any, searchBarAction: string, props: any, state: any, searched_keyword: any) => {
  try {
    logEvent(
      event,
      {
        feature_used: `search-bar_${searchBarAction}`,
        feature_category: FeatureCategory.Search,
        feature_subcategory: identifyFeatureSubcategory(props),
        // currently, the search bar is only in the header
        feature_location: FeatureLocation.Header,
      },
      extractSearchData(event, props, state, searched_keyword),
    );
  } catch (error) {
    console.error('Error logging search event:', error);
  }
};

/**
 * Identify the feature subcategory based on the search bar action.
 *
 * @returns A string representing the feature subcategory.
 */
const identifyFeatureSubcategory = (props: any): FeatureSubcategory => {
  if (props?.properties?.searchType === 'all') {
    return FeatureSubcategory.SmartSearch;
  }
  if (props?.properties?.searchType === 'casino') {
    return FeatureSubcategory.GameSearch;
  }
  if (props?.properties?.searchType === 'bets') {
    return FeatureSubcategory.BetsSearch;
  }

  // not yet defined
  return FeatureSubcategory.SmartSearch;
};

/**
 * Extract the search data from the event.
 *
 * @returns An object containing the search data.
 */
const extractSearchData = (event: any, props: any, state: any, searched_keyword: any) => {
  // params means the user clicked on something inside the search results
  if (!event || !event.currentTarget) {
    return {
      search_keyword: searched_keyword,
    };
  }

  // if load more button is clicked
  if (event.currentTarget.dataset?.type) {
    return {
      search_keyword: searched_keyword,
      'show-more-type': event.currentTarget.dataset?.type,
    };
  }

  // user clicked on a search result, extract the data
  let params: any = {};

  const tmp = event.currentTarget.dataset.params ? JSON.parse(event.currentTarget.dataset.params) : {};
  if (typeof tmp === 'object') params = tmp;

  if (Object.keys(params).length > 0) {
    switch (params.type) {
      case 'game':
        return {
          search_keyword: searched_keyword,
          search_type: params?.type,
          search_result_data: (() => {
            for (const [index, x] of state.resCasino.entries()) {
              if (x.id === params.id) {
                return {
                  game_id: x.id,
                  game_index: index,
                  game_name: x.name,
                  game_provider_id: x.provider_id,
                  game_rtp: x.rtp,
                  game_players: x.players,
                  game_metadata_rpid: x.metadata_rpid,
                  game_search_source: x.search_source,
                  game_url: x.url,
                };
              }
            }
            return null;
          })(),
        };
      case 'match':
        return {
          search_keyword: searched_keyword,
          search_type: params?.type,
          search_result_data: params,
        };
      default:
        return {
          search_keyword: searched_keyword,
          search_type: params?.type,
          search_result_data: params,
        };
    }
  }

  return {
    search_keyword: searched_keyword,
  };
};
