import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { BetslipBetType, MatchType } from '@/components/modules/bets/utils/types';
import { formatBetTitle, formatOddName } from '@/components/modules/bets/utils/functions';
import { getBetData } from '@/components/modules/bets-recommendation/recommended-bets/utils';

type RecommendedBetProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const RecommendedBet = (componentProps: RecommendedBetProps) => {
  let props = componentProps;
  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const { periodIdMatch, idMatch, idMb, idMbo, idSport, mType, brmId } = props.properties ?? {};

  const { i18n } = useTranslation();
  const prematchMatches = useAppSelector((state) => state.bets.prematch.matches);
  const betsSlip = useAppSelector((state) => state.bets.betsSlip);

  const { match, matchBet, matchBetOutcome } = React.useMemo(() => {
    return getBetData(prematchMatches, periodIdMatch, idMatch, idMb, idMbo);
  }, [prematchMatches, periodIdMatch, idMatch, idMb, idMbo]);

  const isBetAdded = React.useMemo(() => {
    const currentTicket = betsSlip.tickets[betsSlip.currentTicket];

    const isAddedToPrematch = currentTicket.prematch.selected.find(
      (bet: BetslipBetType) => bet.idMatch === idMatch && bet.idMbo === idMbo,
    );
    const isAddedToLive = currentTicket.live.selected.find(
      (bet: BetslipBetType) => bet.idMatch === idMatch && bet.idMbo === idMbo,
    );

    return !!isAddedToPrematch || !!isAddedToLive;
  }, [betsSlip, idMatch, idMbo]);

  const contextValue = React.useMemo(() => {
    return {
      // Display Props
      marketName: match && matchBet ? formatBetTitle(matchBet, match, null, i18n.language) : '',
      betName: match && matchBetOutcome ? formatOddName(idMatch, matchBetOutcome, match, null, i18n.language) : '',
      oddValue: matchBetOutcome?.mboOddValue ?? '',
      isBetAdded,
      // Data Atttributes
      brmId,
      idBet: matchBet?.idBet,
      idBo: matchBetOutcome?.idBo,
      idMatch,
      idMb,
      idMbo,
      idSport,
      mType,
    };
  }, [
    dataElementContext,
    componentProps,
    match,
    matchBet,
    matchBetOutcome,
    i18n.language,
    isBetAdded,
    brmId,
    idMatch,
    idMb,
    idMbo,
    idSport,
    mType,
  ]);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default RecommendedBet;
