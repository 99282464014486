import React from 'react';

import styled from 'styled-components';
import * as EmailValidator from 'email-validator';

import { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { getMarketingOffer } from '@/modules/casino/store/actions/marketing_offers';

import PhoneNumberValidator from '@/modules/casino/utils/PhoneNumberValidator';
import {
  validateField,
  sendRegisterSMS,
  register,
  // updateRegisterFields,
  setPartialAccount,
} from '@/modules/casino/store/actions/register';
import ResponseErrorMessages from '@/modules/casino/store/errors/ResponseErrorMessages';
import PasswordValidator from '@/utils/PasswordValidator';
import { loginMethod, loginPassword, loginStart, loginUsername } from '@/modules/casino/store/actions/login';
import { IS_EMAIL } from '@/modules/casino/utils/LoginMethods';
import NINValidator from '@/modules/casino/utils/NINValidator';
import ExpiringLocalStorage from '@/modules/casino/utils/ExpiringLocalStorage';
import PlayOnline from '@/utils/marketing/Providers/PlayOnline';
import MarketingEvents from '@/utils/marketing/MarketingEvents';
import { requestDocumentsMaxFileSize } from '@/modules/casino/store/actions';
import { scanDocument } from '@/modules/casino/store/actions/druid';
import { useTranslation } from 'react-i18next';
import Utils from '@/modules/casino/utils/Utils';
import { getText } from '@/modules/lobby/utils/functions';
import i18n from '@/utils/i18n';

type RegisterProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type ModuleStateProps = {
  currentStep: string;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);


const RegisterStepper = (componentProps: RegisterProps) => {
  const { t } = useTranslation();


  /************************   TODO  ****************/

  const listener = (e:any) => {
    console.log(e); // TODO: change step
  };
  const refListener = listener;

  React.useEffect(() => {
    window.addEventListener('storage', refListener, false);
    return () => {
      window.removeEventListener('storage', refListener);
    };
  }, []);

  
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;


  const initialState = {
    currentStep: 'step1',
    
  };
  
  const [state, setState] = React.useState<ModuleStateProps>(initialState);

  const contextValue = {
    ...state,
  
  };

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default RegisterStepper;
