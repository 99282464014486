import ClientDetection from '@/modules/casino/utils/ClientDetection';
import { UserData } from '../types/eventData';

/**
* Retrieves user data from the global state or other sources.
* 
* @param profileState The global application state from which user data can be extracted.
* @returns An object containing user-related data.
*/
export const getUserData = (profileState: any): UserData => {
  // Extract data from global state
  const user_id = profileState?.client_player_id || null;
  const user_global_id = profileState?.global_player_id || null;

  // User authentication status
  const user_auth = profileState?.client_player_id !== null && profileState.global_player_id !== null;

  // Return the user data
  return {
    user_id,
    user_auth,
    user_global_id,
    device: ClientDetection.getAllInfo(),
    location: { ip_address: '', city: '', country: '', region: '' } // Placeholder
  };

};

