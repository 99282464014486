import { calendarConstants } from '../actions/constants';
import { application as casinoAppConstants } from '../../../casino/store/actions/actionTypes';
import moment from 'moment';

const getCurrentDay = () => {
  const url = new URL(window.location.href);
  if (url.searchParams.has('sd')) {
    const sd = url.searchParams.get('sd');
    if (sd !== '0' && moment(parseInt(sd, 10)).isValid()) {
      return moment(parseInt(sd, 10)).startOf('day').valueOf() + '';
    } else if (sd === '0') {
      return 0;
    }
  }

  return moment().startOf('day').valueOf() + '';
};

const initialState = {
  filterBy: getCurrentDay(),
  range: '24h',
};

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case casinoAppConstants.REINITIALIZE:
      if (state.filterBy === null) {
        return state;
      }

      const ftb = moment(parseInt(state.filterBy, 10)).startOf('day').valueOf();
      const now = moment().startOf('day').valueOf();

      if (ftb < now) {
        return {
          ...state,
          filterBy: moment().startOf('day').valueOf() + '',
        };
      }

      return state;
    case calendarConstants.SAVE:
      return {
        filterBy: action.filterBy,
        range: action.range,
      };
    default:
      return state;
  }
};

export default calendarReducer;
