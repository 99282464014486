/**
 * Gets the existing session ID from localStorage or generates a new one if it has expired or doesn't exist.
 *
 * @param globalState - The application's global state
 * @returns A string representing the session ID.
 */
export const getOrCreateSessionId = (): string => {
  const SESSION_TIMEOUT = 1800000; // 30 minutes in milliseconds
  const storedSessionId = localStorage.getItem('session_id');
  const lastActivity = localStorage.getItem('last_activity');

  const currentTime = Date.now();

  if (storedSessionId && lastActivity) {
    const timeElapsed = currentTime - parseInt(lastActivity, 10);

    if (timeElapsed < SESSION_TIMEOUT) {
      // Session ongoing, update last activity timestamp
      localStorage.setItem('last_activity', currentTime.toString());
      return storedSessionId;
    }
  }

  // Session expired or doesn't exist, generate a new session ID
  const newSessionId = generateSessionId();
  localStorage.setItem('session_id', newSessionId);
  localStorage.setItem('last_activity', currentTime.toString());

  return newSessionId;
};

/**
 * Generates a new session ID.
 *
 * @returns A string representing a new session ID.
 */
const generateSessionId = (): string => {
  // Generate a random session ID
  const sessionId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  return sessionId;
};
