import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';

import './index.scss';
import {
  requestDocuments,
  requestDocumentsMaxFileSize,
  requestDocumentsUpload,
} from '../../../modules/casino/store/actions';

type DocumentsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type ModuleStateProps = {
  documentTypeSelected: number;
  modalOpen: boolean;
  modalScreen: string;
  uploadedFile: any;
  file: any;
  docid: number;
};

type Document = {
  comment: string;
  expires: number;
  id: number;
  name: string;
  reason: string;
  status: number;
  type: number;
  layout_type: string;
  layout_status: string;
  showUploadScreen: (e: React.ChangeEvent<HTMLInputElement>) => void;
  uploadDocument: (e: React.ChangeEvent<HTMLInputElement>) => void;
  openCamera: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectFile: (e: any) => void;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const document_type = ['id_card', 'utilities_bill', 'bank_card', 'passport', 'custom'];
const document_status: any = {
  2: 'pending',
  4: 'rejected',
  6: 'requested',
  8: 'approved',
  9: 'rejected',
  10: 'rejected',
  16: 'rejected',
  17: 'pending',
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Documents = (componentProps: DocumentsProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();

  const initialState = {
    documentTypeSelected: 1,
    modalOpen: false,
    modalScreen: '',
    uploadedFile: null,
    file: null,
    docid: 0,
    documentsOk: true,
    documentUploadError: false,
  };

  const [state, setState] = React.useState<ModuleStateProps>(initialState);

  const profile = useAppSelector<any>((state) => state.profile);
  const documents = useAppSelector<any>((state) => state.documents);

  useEffect(() => {
    if (!documents.documentsMaxFileSize || documents.documentsMaxFileSize === 0) {
      dispatch(requestDocumentsMaxFileSize());
    }
  }, []);

  useEffect(() => {
    if (!documents.loadingDocuments && !documents.loadingDocumentsUpload) {
      dispatch(requestDocuments());
    }
  }, [documents.loadingDocumentsUpload]);

  const showUploadScreen = (e: any) => {
    const el = e.currentTarget ?? (e.currentTarget as HTMLElement);
    const value = el.getAttribute('data-documenttype') ?? '0';
    const docid = el.getAttribute('data-docid') ?? '0';
    if (!documents.documentsMaxFileSize || documents.documentsMaxFileSize === 0) {
      requestDocumentsMaxFileSize();
    }

    setState((v) => ({
      ...state,
      modalOpen: true,
      modalScreen: 'upload',
      documentTypeSelected: parseInt(value, 10),
      docid: parseInt(docid, 10),
      documentUploadError: false,
    }));
  };

  const toggleUploadScreen = () => {
    setState({
      ...state,
      modalOpen: !state.modalOpen,
    });
  };

  const uploadDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      modalScreen: 'upload-loading',
    });

    // upload document
    dispatch(requestDocumentsUpload(state.file, state.documentTypeSelected, state.docid));
    setState({
      ...state,
      modalOpen: false,
    });
  };
  const openCamera = (e: any) => {
    // open camera
    onSelectFile(e);
  };

  const onSelectFile = (e: any) => {
    // get files from input event e
    const files = e?.target?.files;
    setState((v) => ({ ...v, documentUploadError: false }));
    const size = documents.documentsMaxFileSize ? documents.documentsMaxFileSize.replace('Mb', '') : '2';
    Object.keys(files).forEach((attr) => {
      if (files[attr].size / 1048576 < size) {
        // check if file is image
        const ALLOWED_TYPES = ['jpeg', 'jpg', 'png', 'pdf'];
        if (ALLOWED_TYPES.some((type) => files[attr].type.includes(type))) {
          // use file reader
          const reader = new FileReader();
          reader.onload = (e: any) => {
            setState({
              ...state,
              uploadedFile: e.target.result,
              file: files[attr],
              modalScreen: 'confirm-upload',
            });
          };
          return reader.readAsDataURL(files[attr]);
        } else {
          setState((v) => ({ ...v, documentUploadError: 'DOCUMENT_UPLOAD_FILE_TYPE_NOT_SUPPORTED' }));
        }
        return;
      } else {
        console.warn('File size is too big');
        setState((v) => ({ ...v, documentUploadError: 'DOCUMENT_UPLOAD_FILE_TOO_BIG' }));
      }
    });
  };

  const documentsHandler = () => {
    const document: Document[] = [];
    if (documents.documentsRequest && documents.documentsRequest.length > 0) {
      documents.documentsRequest.map((item: any) => {
        const status: number = item.status;
        let oldReasons: any[] = [];
        let reasonsList: any[] = [];
        if (item?.reason?.length > 0) {
          oldReasons = item?.reason?.map((reason: string) => `DOCUMENT_REJECT_REASON_${reason.trim().toUpperCase()}`);
          reasonsList = item?.reason?.map((reason: string) => {
            return { reason: `DOCUMENT_REJECT_REASON_${reason.trim().toUpperCase()}` };
          });
        }
        document.push({
          ...item,
          status: status,
          reason: oldReasons ? oldReasons.join('<br/>') : '', // backwards compatibility with old websites
          reasonsList: reasonsList ?? [],
          layout_type: document_type[item.type - 1] + '_' + document_status[item.status],
          showUploadScreen: showUploadScreen,
          uploadDocument: uploadDocument,
          openCamera: openCamera,
          onSelectFile: onSelectFile,
        });
      });
    }
    return document;
  };

  React.useEffect(() => {
    // if any document is not approved, set documentsOk to false
    if (
      documents &&
      documents.documentsRequest &&
      Array.isArray(documents.documentsRequest) &&
      documents.documentsRequest.length > 0
    ) {
      documents.documentsRequest.map((item: Document) => {
        const status = item.status;
        if (status && status !== 8) {
          setState((v) => ({ ...v, documentsOk: false }));
        }
      });
    }
  }, [documents.documentsRequest]);

  const contextValue = {
    ...state,
    first_name: profile.first_name,
    last_name: profile.last_name,
    phone: profile.phone,
    email: profile.email,
    email_verified: profile.email_verified,
    loading_profile: profile.loading,
    documents_max_file_size: documents.documentsMaxFileSize,
    documents_data: documentsHandler(),
    loading_documents: documents.loadingDocuments,
    showUploadScreen: showUploadScreen,
    uploadDocument: uploadDocument,
    openCamera: openCamera,
    onSelectFile: onSelectFile,
    uploadedFile: state.uploadedFile ? state.uploadedFile : '',
    toggleUploadScreen: toggleUploadScreen,
    document_name: state?.file?.name ? state?.file?.name : '',
  };

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Documents;
