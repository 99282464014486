import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import evBusMain from '@/utils/evbus';
import resolveGameInfo from '@/utils/game-info-api-cache';
import Providers from '@/common/providers';

//import './index.scss';

type SlotGameLauncherInfoProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const SlotGameLauncherInfo = (componentProps: SlotGameLauncherInfoProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const authentication = useAppSelector((state) => state.authentication);
  const [state, setState] = React.useState({
    gameId: null,
  });

  const { data: gameData, loaded: gameDataLoaded } = resolveGameInfo({
    dsId: window.config.dataSourceAllGames,
    gameId: state.gameId ?? '',
    authenticationToken: authentication?.access_token,
  });

  const handleEvent = React.useCallback((data: { id: string; slug: string }, event: string) => {
    if (data.id) {
      setState((prev: any) => {
        return {
          ...prev,
          gameId: data.id,
        };
      });
    }
  }, []);

  const closeGame = React.useCallback(() => {
    setState((prev: any) => {
      return {
        ...prev,
        gameId: null,
      };
    });
  }, []);

  React.useEffect(() => {
    evBusMain.on('launch-slot-game-info', handleEvent);

    return () => {
      evBusMain.remove('launch-slot-game-info', handleEvent);
    };
  }, []);

  const contextValue = React.useMemo(() => {
    let provider = null;
    if (gameData?.data?.provider_id) {
      Providers.find((p) => {
        if (p.id.toString() === gameData?.data?.provider_id?.toString()) {
          provider = p;
          return true;
        }
        return false;
      });
    }

    return {
      notifyWillOpen: () => {},
      notifyWillClose: () => {},
      open: state.gameId != null && state.gameId !== '',
      closeGame: closeGame,
      state,
      gameData: state.gameId ? gameData : null,
      gameDataLoaded,
      provider: provider,
    };
  }, [dataElementContext, componentProps, state, gameData, gameDataLoaded, closeGame]);

  // console.log('SlotGameLauncherInfo[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default SlotGameLauncherInfo;
