import React from 'react';
import styled from 'styled-components';
import { useMatches, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { setInGameHeaderSelection } from '@/modules/casino/store/actions/application';
import { fetchSources } from '@/store/slices/dataSources';
import { fetchHotspinsJackpotGame, reset as hotspinsJackpotGameReset } from '@/store/slices/hotspinsJackpotGame';
import evBusMain from '@/utils/evbus';
import { getNumberInfo } from '@/page-components/utils/functions';

//import './index.scss';

type HotspinsJackpotHookProps = {
  gameId: string;
  sourceId: string;
  jackpotGroup: string;
};

type HotspinsJackpotState = {
  isRunning: boolean;
  data: any;
  texts: string[];
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

export const useHotspinsJackpot = (props: HotspinsJackpotHookProps) => {
  const { gameId, sourceId, jackpotGroup } = props;
  const [state, setState] = React.useState<HotspinsJackpotState>({
    isRunning: false,
    data: {},
    texts: [],
  });
  const dispatch = useAppDispatch();
  const dataSourcesItems = useAppSelector((state) => state.dataSources.items);

  React.useEffect(() => {
    const loadSrc = [];
    if (sourceId && !dataSourcesItems[sourceId]) {
      loadSrc.push(sourceId);
    }

    if (jackpotGroup && !dataSourcesItems[`jackpot_games_${jackpotGroup}`]) {
      loadSrc.push(`jackpot_games_${jackpotGroup}`);
    }

    if (loadSrc.length) dispatch(fetchSources({ ids: loadSrc }));
  }, []);

  React.useEffect(() => {
    if (dataSourcesItems[sourceId]?.data?.length) {
      const jackpots = dataSourcesItems[sourceId].data;
      const jackpot = jackpots.find((j: any) => j.group === jackpotGroup);

      if (jackpot && dataSourcesItems[`jackpot_games_${jackpotGroup}`]) {
        const games = dataSourcesItems[`jackpot_games_${jackpotGroup}`].data;

        if (games) {
          const exists = games.find((g: any) => g.id?.toString?.() === gameId?.toString?.());

          const texts = [];
          let total = 0;

          jackpot?.jackpots?.data?.forEach((j: any) => {
            total += j.accumulator_states?.data?.value;
          });

          if (total > 0) {
            const formatted = getNumberInfo({ locale: '', amount: total, digits: 2 });
            texts.push(`${formatted.amount} FS`);
          }

          if (exists) {
            setState({
              isRunning: true,
              data: jackpot,
              texts: texts,
            });
          }
        }
      }
    }
  }, [dataSourcesItems, gameId, sourceId, jackpotGroup]);

  return state;
};

type HotspinsJackpotProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

type HotspinsJackpotLevel = {
  name: string;
  value: number;
  currency: string;
  icon: string;
};

const HotspinsJackpot = (componentProps: HotspinsJackpotProps) => {
  let props = componentProps;

  const dispatch = useAppDispatch();
  const dataSourcesItems = useAppSelector((state) => state.dataSources.items);
  const hotspinsJackpotGame = useAppSelector((state) => state.hotspinsJackpotGame.game);
  const hotspinsJackpotGameLoading = useAppSelector((state) => state.hotspinsJackpotGame.loading);
  const navigate = useNavigate();
  const matches = useMatches();
  const { i18n } = useTranslation();
  const authenticationToken = useAppSelector((state) => state.authentication.access_token);

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  React.useEffect(() => {
    const loadSrc = [];
    if (props.properties?.sourceId && !dataSourcesItems[props.properties.sourceId]) {
      loadSrc.push(props.properties.sourceId);
    }

    if (props.properties?.jackpotGroup && !dataSourcesItems[`jackpot_games_${props.properties.jackpotGroup}`]) {
      loadSrc.push(`jackpot_games_${props.properties.jackpotGroup}`);
    }

    if (loadSrc.length) dispatch(fetchSources({ ids: loadSrc }));
  }, []);

  React.useEffect(() => {
    if (matches) {
      const match = matches[matches.length - 1];

      if (match.pathname.indexOf('/jackpot-game') !== 0 && hotspinsJackpotGame?.id) {
        const expiresAt = new Date(hotspinsJackpotGame.expires_at).valueOf();
        const now = +new Date();
        if (expiresAt > now) {
          let redirectUrl = '/jackpot-game';

          if (match.params?.gameId) {
            redirectUrl += `/${match.params.gameId}`;
          }

          if (match.params?.name) {
            redirectUrl += `/${match.params.name}`;
          }

          navigate(redirectUrl, { replace: true });
        }
      }
    }
  }, [hotspinsJackpotGame, matches, navigate]);

  React.useEffect(() => {
    if (!hotspinsJackpotGame) dispatch(fetchHotspinsJackpotGame());
  }, []);

  const onSwitchToWidget = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    dispatch(setInGameHeaderSelection(''));
  }, []);

  const onExpired = React.useCallback(() => {
    if (hotspinsJackpotGame?.id) {
      const expiresAt = new Date(hotspinsJackpotGame.expires_at).valueOf();
      const now = +new Date();
      if (expiresAt < now) {
        dispatch(hotspinsJackpotGameReset());
        if (matches) {
          const match = matches[matches.length - 1];
          if (match.params?.gameId && match.params?.name) {
            evBusMain.emit('route-change', { url: '/slot-game/' + match.params.gameId + '/' + match.params.name });
            return;
          }
        }
        evBusMain.emit('route-change', { url: '/' });
      }
    }
  }, [matches, hotspinsJackpotGame, navigate]);

  const onClose = React.useCallback(() => {
    if (matches) {
      const match = matches[matches.length - 1];
      if (match.params?.gameId && match.params?.name) {
        evBusMain.emit('route-change', { url: '/slot-game/' + match.params.gameId + '/' + match.params.name });
        return;
      }
    }
    evBusMain.emit('route-change', { url: '/' });
  }, [matches]);

  const postMessageHandler = React.useCallback(
    (event: MessageEvent) => {
      //console.log('HotspinsJackpot[postMessageHandler]', event?.data);
      if (event?.data === 'hotspinsjackpot_end_game') {
        dispatch(hotspinsJackpotGameReset());
        if (matches) {
          const match = matches[matches.length - 1];
          if (match.params?.gameId && match.params?.name) {
            evBusMain.emit('route-change', { url: '/slot-game/' + match.params.gameId + '/' + match.params.name });
            return;
          }
        }
        evBusMain.emit('route-change', { url: '/' });
      }
    },
    [matches, navigate],
  );

  React.useEffect(() => {
    window.addEventListener('message', postMessageHandler);

    return () => {
      window.removeEventListener('message', postMessageHandler);
    };
  }, []);

  const contextValue = React.useMemo(() => {
    const icons = [
      'https://micros-t.b-cdn.net/hj-1-1730889930129.png',
      'https://micros-t.b-cdn.net/hj-2-1730889930134.png',
      'https://micros-t.b-cdn.net/hj-3-1730889930136.png',
      'https://micros-t.b-cdn.net/hj-4-1730889930137.png',
    ];

    const levels: HotspinsJackpotLevel[] = [];

    const sourceId: string = props.properties?.sourceId;
    const jackpotGroup: string = props.properties?.jackpotGroup;

    if (sourceId && jackpotGroup && dataSourcesItems[sourceId]?.data?.length) {
      const jackpots = dataSourcesItems[props.properties.sourceId].data;
      const jackpot = jackpots.find((j: any) => j.group === jackpotGroup);

      if (jackpot) {
        jackpot?.jackpots?.data?.forEach((j: any, index: number) => {
          const data: any = {};
          data.name = j?.accumulator_states?.data?.name;
          data.value = j?.accumulator_states?.data?.amounts?.[0]?.amount ?? j?.accumulator_states?.data?.value ?? 0;
          data.currency = j?.currency;
          data.icon = icons[index];

          levels.push(data);
        });
      }
    }

    let gameData = null;

    if (hotspinsJackpotGame?.id) {
      gameData = {
        id: hotspinsJackpotGame.id,
        expires_at: new Date(hotspinsJackpotGame.expires_at).valueOf(),
        language: i18n.language,
        access_token: authenticationToken,
        onExpired: onExpired,
      };
    }

    levels.sort((a, b) => b.value - a.value);

    return {
      levels: levels,
      onSwitchToWidget,
      gameData,
      onClose,
    };
  }, [
    dataElementContext,
    componentProps,
    onSwitchToWidget,
    dataSourcesItems,
    i18n.language,
    authenticationToken,
    onExpired,
    onClose,
  ]);

  //console.log('HotspinsJackpot[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default HotspinsJackpot;
