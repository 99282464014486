import { scoreDisplayDef } from './scoreDisplayDef';
import getStore from '../store';
import { getBetsState } from '../store/selectors/betData';
import { sortArrayByKey } from './';
import { isArray } from 'lodash-es';
import { appSaveErrorLog } from '../store/actions/app';
// import overtime_score from "./overtime_score.json";

const defaultParsed = {
  intervalLetter: '',
  currentInterval: -1,
  intervalTime: 0,
  intervalName: '',
  intervalsNumber: 0,
  intervals: [],
  score: [],
  currentScore: '',
  lastScore: '',
  teamLosing: 0,
  teamServing: 0,
  scoreWithFormatData: [],
  scoreNoFormatData: {
    currentScore: [0, 0],
    score: [],
    halfNames: [],
  },
};

export const stdLiveScore = (match, matchStatus = null) => {
  let parsed = null;

  try {
    if (match.provider === 'digitain') {
      // if (match.idSport === "1") {
      //   parsed = _stdLiveScoreDigitain(overtime_score);
      //   return parsed;
      // }

      parsed = _stdLiveScoreDigitain(match, matchStatus);
    } else {
      parsed = _stdLiveScore(match, matchStatus);
    }
  } catch (e) {
    console.error(e);

    if (matchStatus === null) {
      if ('currentStatus' in match) {
        matchStatus = match.currentStatus;
      }
    }

    getStore().dispatch(
      appSaveErrorLog(
        '',
        'Failed to parse live match status',
        JSON.stringify({
          matchStatus,
          error: e.toString(),
          stack: e.stack,
        }),
      ),
    );
    parsed = defaultParsed;
  }

  return parsed;
};

export const _stdLiveScore = (match, matchStatus = null) => {
  let intervalLetter = ''; // momentarily kept in for legacy purposes
  let currentInterval = 1; // momentarily kept in for legacy purposes
  let intervalTime = '';
  let intervalName = '';
  let score = []; // momentarily kept in for legacy purposes
  let currentScore = ''; // momentarily kept in for legacy purposes
  let lastScore = '';
  let teamLosing = 0; // new
  let teamServing = 0; // NEW
  let scoreWithFormatData = []; // NEW
  let scoreNoFormatData = {
    currentScore: [0, 0],
    score: [],
    halfNames: [],
  };

  if (match.mType !== 'live') {
    //console.log("Not a live match", match);
    return {
      intervalLetter: 'Not a live match',
      currentInterval: -1,
      intervalTime: 0,
      intervalName: 'Not a live match',
      intervalsNumber: 0,
      intervals: [],
      score: [],
      currentScore: '',
      lastScore: '',
      teamLosing: 0,
      teamServing: 0,
      scoreWithFormatData: ['-', '-', { isBig: false, isColored: false }],
    };
  }

  if (matchStatus === null) {
    if ('currentStatus' in match) {
      matchStatus = match.currentStatus;
    }
  }

  // get defined sport display defs
  let sdd = null;
  if (scoreDisplayDef.has(match.idSport)) {
    sdd = scoreDisplayDef.get(match.idSport);
  } else {
    sdd = scoreDisplayDef.get('9999'); // default display defs
  }

  let intervalsNumber = sdd.intervalCountDefault;
  let intervalLength = sdd.intervalLengthDefault;
  // let overtimeLength = sdd.overtimeLengthDefault;
  let overtimeLength = null; // no more assumptions/fallbacks for overtime length
  let roundWinPts = null; // removed redundant intervalPointsWinAmount, multi-use intervalLength instead
  let roundWinPtsMD = sdd.intervalPointsWinDifference;
  let matchWinPts = sdd.matchIntervalWinsMinAmount;
  let intervalType = sdd.intervalCountLookup === 'NumberOfSets' ? 'set' : 'period';
  // let matchWinPtsMD = sdd.matchIntervalWinsMinDifference; // momentarily unused
  if ('extraInfo' in match) {
    // interval total count lookup + patching
    if (sdd.intervalCountLookup in match.extraInfo) {
      intervalsNumber = parseInt(match.extraInfo[sdd.intervalCountLookup], 10);
      if (isNaN(intervalsNumber)) {
        if (sdd.intervalFormat === 'score') {
          // fallback value via match win points on score formatting criteria
          intervalsNumber = 2 * matchWinPts - 1;
        } else {
          // fallback to default value (assuming it exists)
          intervalsNumber = sdd.intervalCountDefault;
        }
      }
      // final sanity check
      if (intervalsNumber < 1) intervalsNumber = 1;
    }
    // per-interval length (duration/score/etc) lookup + patching
    if (sdd.intervalLengthLookup in match.extraInfo) {
      intervalLength = parseInt(match.extraInfo[sdd.intervalLengthLookup], 10);
      if (isNaN(intervalLength)) {
        // fallback to default value
        intervalLength = sdd.intervalLengthDefault;
      } else {
        if (sdd.intervalCountType === 'points' && intervalLength >= 1) {
          // override default value of points needed for round win with (valid) live extraInfo data
          roundWinPts = intervalLength;
        }
      }
      // final sanity check
      if (intervalLength < 1) intervalLength = 1;
    }
    // revised overtime length lookup (present for duration matches only) + removed fallbacks
    // NOTE: always check overtimeLength !== null before using it
    if (sdd.overtimeLengthLookup in match.extraInfo) {
      overtimeLength = parseInt(match.extraInfo[sdd.overtimeLengthLookup], 10);
      // don't fall back to default value, don't make any assumptions, drop invalid data
      // if (isNaN(overtimeLength)) overtimeLength = sdd.overtimeLengthDefault;
      // if (overtimeLength < 1) overtimeLength = 1;
      if (isNaN(overtimeLength)) overtimeLength = null;
      if (overtimeLength <= 0) overtimeLength = null;
    }
  }

  // check for various possible overtime criteria
  // UPDATE: drastically revised to much more conservative overtime detection
  let inOvertimeNr = 0;
  let overtimePercentComplete = 0;

  // set extra overtime interval(s) if received scores length longer than expected
  if ('setScoreDetails' in matchStatus && matchStatus.setScoreDetails.length > intervalsNumber) {
    inOvertimeNr = matchStatus.setScoreDetails.length - intervalsNumber;
    overtimePercentComplete = 50;
  }
  // additional overtime interval(s) IF AND ONLY IF:
  //   - we have no scores over expected length AND
  //   - we know what the clear/official overtime length is AND
  //   - extended match time "extra" time is at least as high as (a multiple of) known overtime length
  if (inOvertimeNr === 0 && overtimeLength !== null && overtimeLength > 0 && 'matchtimeExtended' in matchStatus) {
    // plus sign means "extra" time (possible but not guaranteed overtime interval)
    let temp = matchStatus.matchtimeExtended.split('+');
    if (
      temp.length >= 2 &&
      // base match time is at least as high as expected after all regular intervals passed
      temp[0].split(':').map((v) => parseInt(v, 10))[0] >= intervalsNumber * intervalLength
    ) {
      // drop base match time, extract and keep just "extra" time in mm:ss
      temp = temp[1].split(':').map((v) => parseInt(v, 10));
      // convert to minutes with decimals (with fallbacks for potentially missing or faulty data)
      if (isNaN(temp[0])) temp[0] = 0;
      if (temp.length >= 2) {
        if (isNaN(temp[1])) temp[1] = 0;
        temp = temp[0] + temp[1] / 60;
      } else {
        temp = temp[0];
      }
      // only add overtime(s) if "extra" time has at least a (multiple of) full overtime length(s)
      inOvertimeNr = Math.floor(temp / overtimeLength);
      // don't assume we know how much of the extra time is actually in the overtime period
      // vs how much it could have been inside (slightly extended) regular intervals
      overtimePercentComplete = 50;
    }
  }

  /*
  // old over-sensitive overtime detection routine starts here
  // first check if matchtime exceeds expected duration
  if ("matchtime" in matchStatus) {
    if (matchStatus.matchtime > intervalsNumber * intervalLength) {
      if (overtimeLength !== null && overtimeLength > 0) {
        // calculate which overtime we're in and how far into it we are
        inOvertimeNr = Math.ceil(
          (matchStatus.matchtime - intervalsNumber * intervalLength) / overtimeLength
        );
        overtimePercentComplete = Math.ceil(
          (100 *
            (matchStatus.matchtime -
              intervalsNumber * intervalLength -
              (inOvertimeNr - 1) * overtimeLength)) /
            overtimeLength
        );
      } else {
        // fallback for matchtime overtime detected with missing overtime length info
        inOvertimeNr = 1;
        overtimePercentComplete = 50;
      }
    }
  }
  // then check if matchtimeExtended contains any clear overtime info (takes priority if it does)
  if ("matchtimeExtended" in matchStatus) {
    // plus sign means some overtime
    let temp = matchStatus.matchtimeExtended.split("+");
    if (temp.length >= 2) {
      // check if we truly are in a distinct extra overtime interval after entire regular match time is over
      // (i.e. not just some minor in-round overtime that does not count as a distinct interval)
      if (
        // either we have more partial scores than initially expected
        ("setScoreDetails" in matchStatus &&
          matchStatus.setScoreDetails.length > intervalsNumber) ||
        // or base match time is at least as high as initially expected (after all regular intervals)
        temp[0].split(":").map(v => parseInt(v, 10))[0] >= intervalsNumber * intervalLength
      ) {
        // drop base match time, extract and keep just overtime in mm:ss
        temp = temp[1].split(":").map(v => parseInt(v, 10));
        // convert to minutes with decimals (with fallbacks for potentially missing or faulty data)
        if (isNaN(temp[0])) temp[0] = 0;
        if (temp.length >= 2) {
          if (isNaN(temp[1])) temp[1] = 0;
          temp = temp[0] + temp[1] / 60;
        } else {
          temp = temp[0];
        }
        if (overtimeLength !== null && overtimeLength > 0) {
          // calculate which overtime we're in based on known overtime length, overwrite previous guesses
          inOvertimeNr = Math.ceil(temp / overtimeLength);
          overtimePercentComplete = Math.ceil(
            (100 * (temp - (inOvertimeNr - 1) * overtimeLength)) / overtimeLength
          );
        } else {
          // fallback for matchtimeExtended overtime detected with missing overtime length info
          if (
            "setScoreDetails" in matchStatus &&
            matchStatus.setScoreDetails.length > intervalsNumber
          ) {
            // deduce overtime number from received scores length, keep higher guess value
            inOvertimeNr = Math.max(
              inOvertimeNr,
              matchStatus.setScoreDetails.length - intervalsNumber
            );
          } else {
            // final fallback to single extra overtime even if no extra scores were received,
            // but ONLY IF we're past an arbitrary grace period of 20s (21 s = 0.35 min)
            if (inOvertimeNr === 0 && temp >= 0.35) inOvertimeNr = 1;
          }
          overtimePercentComplete = 50;
        }
      }
    }
  }
  // old over-sensitive overtime detection routine ends here
  */

  let intervalNameSuffix = '';
  if ('setScoreDetails' in matchStatus) {
    // best first guess for accurate round number is number of intermediate scores
    currentInterval = matchStatus.setScoreDetails.length;
    intervalNameSuffix = currentInterval;
    // better round number guess on timed matches in case intermediate scores are missing
    if ('matchtime' in matchStatus && intervalLength !== null && intervalLength > 0) {
      let temp = matchStatus.matchtime / intervalLength;
      if (temp > currentInterval) {
        // ignore overtime concerns for now, just quick patch for missing scores
        currentInterval = Math.floor(temp);
      }
      intervalNameSuffix = currentInterval;
    }
    // adjustment for detected overtime number (if any) regardless of intermediate scores count
    if (inOvertimeNr > 0) {
      // treat overtime(s) as separate (additional) interval(s)
      currentInterval = intervalsNumber + inOvertimeNr;
      intervalsNumber = currentInterval;
      intervalNameSuffix = inOvertimeNr;
    }
  } else {
    intervalNameSuffix = '-'; // fallback that should never show up in actual display
  }

  let intervals = Array(intervalsNumber).fill(0);

  const skipScores = matchStatus.status === 'NOT_STARTED' ? true : false;
  let hscore = [];
  if (matchStatus && !skipScores) {
    // let rscore = []; // it's overwritten anyway
    let rscore;
    if (matchStatus.setScoreDetails) {
      rscore = matchStatus.setScoreDetails.reduce((acc, s) => {
        // replace invalid scores with 0s
        let tmp = s.score.split(':').map((v) => (isNaN(parseInt(v, 10)) ? 0 : parseInt(v, 10)));
        // if we didn't find exactly 2 parts to the score, assume interval score was 0:0
        if (tmp.length !== 2) tmp = [0, 0];
        return acc.concat([tmp]);
      }, []);
      hscore = matchStatus.setScoreDetails.reduce((acc, s) => {
        return acc.concat(s.period);
      }, []);
    }
    // score = score.concat(rscore); // score is [] anyway
    score = [].concat(rscore);

    // match scores, with fallbacks in case of unexpected or malformed data
    let ms = ['-', '-'];
    if ('score' in matchStatus) {
      ms = matchStatus.score.split(':').map((s) => parseInt(s, 10));
      if (ms.length !== 2) ms = ['-', '-'];
      if (isNaN(ms[0]) || isNaN(ms[1])) ms = ['-', '-'];
    }

    // preliminary win/lose estimation based on match score only
    if (ms[0] < ms[1]) {
      teamLosing = 1;
    } else if (ms[0] > ms[1]) {
      teamLosing = 2;
    }

    // if tied, for some sports, further refine win/lose estimation based on ongoing round score
    if (
      match.idSport === '3' || // Baseball
      match.idSport === '5' || // Tennis
      match.idSport === '20' || // Table Tennis
      match.idSport === '23' || // Volleyball
      match.idSport === '31' || // Badminton
      match.idSport === '34' || // Beach Volley
      match.idSport === '109' // Counter-Strike
    ) {
      if (teamLosing === 0 && rscore && rscore.length > 0 && matchStatus.status !== 'PAUSED') {
        if (rscore[rscore.length - 1][0] < rscore[rscore.length - 1][1]) {
          teamLosing = 1;
        } else if (rscore[rscore.length - 1][0] > rscore[rscore.length - 1][1]) {
          teamLosing = 2;
        }
      }
    }

    // new style scores including formatting data
    // must keep above old format scores code which can heavily modify initially parsed data
    // no more dots
    /*
    scoreWithFormatData = Array(intervalsNumber + 1).fill([
      // blank/default regular score element
      ".",
      ".",
      {
        isBig: false,
        isColored: false
      }
    ]);
    */
    scoreWithFormatData = new Array(score.length);
    scoreNoFormatData.halfNames = hscore;

    // copy intermediate scores, make not big and not colorful
    for (let i = 0; i < score.length; i++) {
      /*
      // make interval placeholder dots leading, not trailing
      if (typeof score[i] !== "undefined") {
        scoreWithFormatData[i + intervalsNumber - score.length] = [
          score[i][0],
          score[i][1],
          { isBig: false, isColored: false }
        ];
      }
      */
      if (typeof score[i] !== 'undefined') {
        scoreWithFormatData[i] = [score[i][0], score[i][1], { isBig: false, isColored: false }];
        scoreNoFormatData.score[i] = [score[i][0], score[i][1]];
      } else {
        // fallback to 0:0 default for any undefined score
        scoreWithFormatData[i] = [0, 0, { isBig: false, isColored: false }];
        scoreNoFormatData.score[i] = [0, 0];
      }
    }

    intervalsNumber = score.length;

    //console.log("match", match, "score", score, "scoreWithFormatData", scoreWithFormatData, intervalsNumber);

    // make current round score colorful (second to last element now that placeholder dots are leading)
    if (currentInterval > 0 && scoreWithFormatData && scoreWithFormatData[intervalsNumber - 1]) {
      scoreWithFormatData[intervalsNumber - 1][2].isColored = true;
    }
    // load last element with total match score, make big but not colorful
    scoreWithFormatData[intervalsNumber] = [ms[0], ms[1], { isBig: true, isColored: false }];
    scoreNoFormatData.currentScore = [ms[0], ms[1]];

    // for football (soccer), replace all intervals after first with total score up to that interval
    if (match.idSport === '1') {
      // make a short temp copy
      let temp = [];
      scoreWithFormatData.forEach((el) => temp.push([parseInt(el[0], 10), parseInt(el[1], 10)]));
      // rewrite all interval scores as sums
      scoreWithFormatData = [];
      let ts1 = 0;
      let ts2 = 0;
      // last element (i.e. temp[intervalsNumber] ) will always be left out (we replace from scratch)
      for (let i = 0; i < intervalsNumber; i++) {
        // placeholder dots (if any) became NaN in temp, recreate placeholder dots
        if (isNaN(temp[i][0]) || isNaN(temp[i][1])) {
          scoreWithFormatData.push(['.', '.', { isBig: false, isColored: false }]);
        } else {
          // accumulate partial scores
          ts1 = ts1 + temp[i][0];
          ts2 = ts2 + temp[i][1];
          scoreWithFormatData.push([ts1, ts2, { isBig: false, isColored: false }]);
        }
      }
      // finally, make current interval (can be an overtime) score big and colorful
      if (intervalsNumber > 0 && scoreWithFormatData && scoreWithFormatData[intervalsNumber - 1]) {
        scoreWithFormatData[intervalsNumber - 1][2] = { isBig: true, isColored: true };
      }

      scoreNoFormatData.score.push([defValue(matchStatus.corners1, 0), defValue(matchStatus.corners2, 0)]);

      scoreNoFormatData.score.push([defValue(matchStatus.yellowCards1, 0), defValue(matchStatus.yellowCards2, 0)]);
      scoreNoFormatData.score.push([defValue(matchStatus.redCardsTotal1, 0), defValue(matchStatus.redCardsTotal2, 0)]);

      scoreNoFormatData.halfNames.push('CORNERS');
      scoreNoFormatData.halfNames.push('YELLOW-CARDS');
      scoreNoFormatData.halfNames.push('RED-CARDS');

      // old routine (clean up soon)
      /*
      // remove 3rd element (total score)
      //scoreWithFormatData = [scoreWithFormatData[0], scoreWithFormatData[1]];

      // remove the last element
      if (currentInterval < 3) scoreWithFormatData.pop();

      //console.log("scoreWithFormatData", scoreWithFormatData, "currentInterval", currentInterval, match);

      // make current half score big (should already be colorful)
      if (currentInterval > 0) scoreWithFormatData[currentInterval - 1][2].isBig = true;
      // if in second half, replace partial half score with total score, big+colorful
      if (currentInterval >= 2)
        scoreWithFormatData[currentInterval - 1] = [
          ms[0],
          ms[1],
          { isBig: true, isColored: true }
        ];
      */
    }

    // for (field) tennis, add extra field for game points, make big but not colorful
    if (match.idSport === '5') {
      // (field) tennis
      if ('pointsDetails' in matchStatus && matchStatus.pointsDetails.points !== null) {
        let temp = matchStatus.pointsDetails.points.split(':').map((v) => parseInt(v, 10));
        scoreWithFormatData.push([temp[0], temp[1], { isBig: true, isColored: false }]);
        scoreNoFormatData.score.push([temp[0] === 50 ? 'A' : temp[0], temp[1] === 50 ? 'A' : temp[1]]);
        scoreNoFormatData.halfNames.push('P');
        // further refine winner estimation in case of match and set score ties based on game points
        if (teamLosing === 0) {
          if (temp[0] < temp[1]) {
            teamLosing = 1;
          } else if (temp[0] > temp[1]) {
            teamLosing = 2;
          }
        }
      } else {
        scoreWithFormatData.push(['-', '-', { isBig: true, isColored: false }]);
        scoreNoFormatData.score.push(['-', '-']);
        scoreNoFormatData.halfNames.push('P');
      }
    }

    /*
    console.log(
      "DEBUG -", match.team1Name, "vs", match.team2Name,
      "score", score,
      "scoreWithFormatData", scoreWithFormatData,
      "currentInterval", currentInterval,
      "intervalsNumber", intervalsNumber,
      "inOvertimeNr", inOvertimeNr,
      "overtimePercentComplete", overtimePercentComplete,
      matchStatus,
      match,
    );
    */

    // old style scores (no format info) - preparing for obsoletion
    // SCORE DISPLAY ADJUSTMENTS of data in setScoreDetails for various sports
    switch (match.idSport) {
      case '1': // football
        // use total/match score instead of round 2 partial score as highlighted/last score
        if (currentInterval === 2) {
          score.pop();
          score.push(ms);
        }
        break;
      case '2': // basketball
        // add total/match score display from 2nd quarter onwards as highlighted/last score
        if (currentInterval >= 2) {
          score.push(ms);
        }
        break;
      case '3': // baseball
        // add total/match score display from 2nd inning onwards as highlighted/last score
        if (currentInterval >= 2) {
          score.push(ms);
        }
        break;
      case '4': // ice hockey
        // replace rounds 2 and 3 partial score with total/match score for that round
        if (currentInterval === 2) {
          score.pop();
          score.push(ms);
        }
        if (currentInterval === 3) {
          score.pop();
          score.pop();
          let temp1 = matchStatus.setScoreDetails[0].score.split(':').map((s) => parseInt(s, 10));
          let temp2 = matchStatus.setScoreDetails[1].score.split(':').map((s) => parseInt(s, 10));
          let ms2 = [temp1[0] + temp2[0], temp1[1] + temp2[1]];
          score.push(ms2);
          score.push(ms);
        }
        break;
      case '5': // (field) tennis
        // display game points as highlighted/last score
        if ('pointsDetails' in matchStatus && matchStatus.pointsDetails.points !== null) {
          score.push(matchStatus.pointsDetails.points.split(':').map((v) => parseInt(v, 10)));
        }
        break;
      case '109': // Counter-Strike
        // UNCERTAIN USEFULNESS: ignore possibly missing setScoreDetails, use setScores instead?
        score = [].concat(
          ...matchStatus.setScores.split('-').map((ss) => {
            return [ss.split(':').map((s) => parseInt(s, 10))];
          }),
        );
        break;
      default:
    }
  }

  currentScore =
    score.length && isArray(score[score.length - 1])
      ? `${score[score.length - 1][0]}:${score[score.length - 1][1]}`
      : '0:0';
  if (score.length >= 2 && isArray(score[score.length - 2])) {
    lastScore = `${score[score.length - 2][0]}:${score[score.length - 2][1]}`;
  }

  // intervalName (and legacy intervalLetter) calculations
  switch (matchStatus.status) {
    case 'NOT_STARTED':
      intervalLetter = 'NS';
      intervalName = intervalLetter;
      break;
    case 'PAUSED':
      intervalLetter = 'P';
      intervalName = intervalLetter;
      break;
    case 'ENDED':
      intervalLetter = 'F';
      intervalName = intervalLetter;
      // intervals = Array(1).fill(100); // reinitialize directly with single fully filled gauge for simplicity
      for (let i = 0; i < intervalsNumber; i++) {
        intervals[i] = 100;
      } // fill all gauges
      // intervalName = intervalName + ` (${intervalsNumber}|${intervalLength}-${overtimeLength})` // debug only
      return {
        // we're done here, return directly
        halfNames: hscore,
        intervalLetter,
        currentInterval,
        intervalName,
        intervalTime,
        intervalsNumber,
        intervals,
        score,
        currentScore,
        lastScore,
        teamLosing,
        teamServing,
        scoreWithFormatData,
        scoreNoFormatData,
      };
    case 'STOPPED':
      intervalLetter = 'ST';
      intervalName = intervalLetter;
      break;
    default:
      // team at service (for matches that have a serving team, e.g. tennis, volley, badminton),
      // note: will be 0 for matches without a serving party, or if match is not actively in play
      teamServing =
        'server' in matchStatus ? (isNaN(parseInt(matchStatus.server, 10)) ? 0 : parseInt(matchStatus.server, 10)) : 0;
      switch (sdd.intervalFormat) {
        case 'letter':
        case 'score':
          intervalLetter = sdd.intervalLetter;
          intervalTime = '';
          intervalName = intervalLetter + intervalNameSuffix;
          break;
        case 'minute':
          intervalLetter = sdd.intervalLetter;
          intervalTime = typeof matchStatus.matchtime !== 'undefined' ? matchStatus.matchtime + "'" : '';
          intervalName = intervalLetter + intervalNameSuffix;
          break;
        default:
          intervalLetter = 'R'; // default for unknown formats
          intervalName = intervalLetter + intervalNameSuffix;
      }
  }

  // current interval "intervals" gauges max index value in need of (some) filling
  let ci = currentInterval - 1;
  if (ci >= 0) {
    switch (sdd.intervalCountType) {
      // time-based cases (currently handled together)
      case 'fixed':
      case 'variable':
        // fill previous intervals
        for (let i = 0; i < ci; i++) {
          intervals[i] = 100;
        }
        if (inOvertimeNr > 0) {
          intervalName = 'PR' + inOvertimeNr;
          intervals[ci] = overtimePercentComplete;
        } else {
          intervals[ci] = Math.ceil((100 * (matchStatus.matchtime % intervalLength)) / intervalLength);
        }

        // old routine before clear overtime extra interval detection checks
        /*
        if (matchStatus.matchtime >= 1 + intervalsNumber * intervalLength) {
          // we're clearly in overtime
          for (let i = 0; i <= ci; i++) {
            intervals[i] = 100;
          }
          intervals.push(
            Math.ceil(
              (100 *
                ((matchStatus.matchtime - intervalsNumber * intervalLength) %
                  overtimeLength)) /
                overtimeLength
            )
          );
          // patch for visibility - show some minor but visible progress otherwise
          if (intervals[ci + 1] < 5) {
            intervals[ci + 1] = 5;
          }
          intervalName = "PR"; // PRelungire
        } else {
          // we're most likely still in regular match time
          for (let i = 0; i < ci; i++) {
            intervals[i] = 100;
          }
          intervals[ci] = Math.ceil(
            (100 * (matchStatus.matchtime % intervalLength)) / intervalLength
          );
        }
        */
        break;
      // score-based cases
      case 'points':
        for (let i = 0; i < ci; i++) {
          intervals[i] = 100;
        }
        if (isArray(matchStatus.setScoreDetails) && matchStatus.setScoreDetails.length > ci) {
          let cs = matchStatus.setScoreDetails[ci];
          let ss = cs.score.split(':');
          if (ss.length >= 2) {
            // found at least 2 scores to compare
            let si = ss.map((s) => parseInt(s, 10));
            let mws = Math.max(si[0], si[1]);
            let mls = Math.min(si[0], si[1]);
            let endscore = roundWinPts;
            // patch for snooker round end score approximation
            if (match.idSport === '19') endscore = roundWinPts - mls;
            // adjust expected endscore if scores too close for win
            if (mws > endscore - roundWinPtsMD) {
              if (mws - mls < roundWinPtsMD) endscore = roundWinPts + roundWinPtsMD - mws + mls;
            }
            intervals[ci] = Math.ceil((100 * mws) / endscore);
          } else {
            // fallback default progress indicator
            intervals[ci] = 50;
          }
          // patch for baseball inning score not being indicative of inning progress
          if (match.idSport === '3') {
            intervals[ci] = 50;
            if ('atBatter' in matchStatus) {
              intervals[ci] = Math.ceil((100 * matchStatus.atBatter) / 9);
            }
          }
        }
        break;
      // unimplemented sport or no recognizable progress-to-round-end features
      default:
        intervals[ci] = 50;
    }

    // patch for break - paused matches will always have a reminder of 0 but round will be complete
    if (matchStatus.status === 'PAUSED') {
      intervals[ci] = 100;
    }
    // patch for round end with status "paused" not present and/or minor (<1m) regular round overtime
    if (intervals[ci] === 0) {
      intervals[ci] = 95;
    }
    // patch for visibility - show some minor but visible progress otherwise
    if (intervals[ci] < 5) {
      intervals[ci] = 5;
    }

    // intervalName = intervalName + ` (${ci+1}/${intervalsNumber}|${intervalLength}-${overtimeLength})` // debug only
  }

  // debug data
  /*
  console.log("DEBUG - match:",
    "t =",matchStatus.matchtime,
    "sc:", matchStatus.score, "cs:", currentScore, "ls:", lastScore, "(",matchStatus.setScores,")",
    score, scoreWithFormatData,
    "(i",currentInterval,"of", intervalsNumber,")",
    intervals,
    teamLosing, match.team1Name, match.team2Name,
    match.extraInfo,
    matchStatus,
    match,
  ); // debug only
  */

  const res = {
    halfNames: hscore,
    // double-check duplicate code direct return in match status case "ENDED" if changing anything here
    intervalLetter, // legacy, mostly obsolete
    currentInterval, // legacy, mostly obsolete (still has internal uses)
    intervalName, // replacement for intervalLetter + currentInterval
    intervalTime, // clarification: actually MATCH time
    intervalsNumber, // total intervals (can be obsoleted, since it is equal to intervals.length)
    intervals, // interval percentage gauges (integer 0..100)
    score, // all scores array(intervalsNumber) of array(2) - legacy format, partially obsolete
    currentScore, // current total score string (extracted from score legacy format)
    lastScore, // previous interval score string (extracted from score legacy format)
    teamLosing, // NEW: team (0, 1, 2) currently at a DISadvantage in the match (for winner name highlighting)
    teamServing, // NEW: team currently serving (0 for matches with no service or not in active play)
    scoreWithFormatData, // NEW: all scores array containing display properties object
    scoreNoFormatData,
    intervalType,
  };

  //console.log("score res", res);

  return res;
};

export const getMatchMarketGroups = (match, noFirst) => {
  const bst = getBetsState(getStore().getState());
  const smg = {
    ...bst[match.mType].marketGroups[0],
    ...bst[match.mType].marketGroups[match.idSport],
  };
  const bets = bst[match.mType].bets[match.idSport];

  if (typeof bets === 'undefined') {
    console.log('no bets', match, bst);
    return [];
  }

  //console.log("bst", bst);

  const mkg = {};

  const others = [];

  match.matchBets.forEach((mb) => {
    if (!(mb.idBet in bets)) {
      return;
    }

    const b = bets[mb.idBet];

    if (typeof b === 'undefined' || b === null) {
      return;
    }

    //console.log("b", b);

    if (typeof b.betGroups !== 'undefined' && b.betGroups !== null && b.betGroups.length > 0) {
      b.betGroups.forEach((bgId) => {
        let addToOthers = false;

        if (bgId in smg) {
          const gn = smg[bgId].name.toLowerCase();

          if (gn.indexOf('winner') !== -1) {
            addToOthers = true;
          } else {
            mkg[bgId] = mkg[bgId] || { ...smg[bgId], matchBets: [] };
            mkg[bgId].matchBets.push(mb.idMb);
          }
        } else {
          addToOthers = true;
        }

        if (addToOthers) {
          others.push(mb.idMb);
        }
      });
    } else {
      others.push(mb.idMb);
    }
  });

  if (0 && others.length > 0) {
    mkg[0] = {
      isOthers: true,
      matchBets: others,
      position: 9999,
    };
  }

  if (!noFirst) mkg['all'] = {
    id: 'all',
    isAll: true,
    position: -1,
  };

  const mkgs = Object.values(mkg);
  sortArrayByKey(mkgs, 'position');

  //console.log("mkg", mkg, mkgs);

  return mkgs;
};

const defValue = (val, def) => {
  if (typeof val !== 'undefined') return val;
  return def;
};

const DEBUGG = false;
const DEBUGG2 = false;
const _stdLiveScoreDigitain = (match, matchStatus = null) => {
  DEBUGG && console.log('current match status= ', match.currentStatus);

  // declar variabilele
  let lastScore = '';
  let intervalTime = '';
  let intervalName = '';
  let teamLosing = 0; // new
  let teamServing = 0; // NEW
  let scoreWithFormatData = []; // NEW
  let scoreNoFormatData = {
    currentScore: [0, 0],
    score: [],
    halfNames: [],
  };

  // verific daca meciul e live:
  if (match.mType !== 'live') {
    DEBUGG && console.log('Not a live match', match);
    return {
      intervalLetter: 'Not a live match',
      currentInterval: -1,
      intervalTime: 0,
      intervalName: 'Not a live match',
      intervalsNumber: 0,
      intervals: [],
      score: [],
      currentScore: '',
      lastScore: '',
      teamLosing: 0,
      teamServing: 0,
      scoreWithFormatData: ['-', '-', { isBig: false, isColored: false }],
    };
  }

  // verific tipul meciului pt score display defaults
  let sdd = null;
  if (scoreDisplayDef.has(match.idSport)) {
    sdd = scoreDisplayDef.get(match.idSport);
  } else {
    sdd = scoreDisplayDef.get('9999'); // default display defs ****************** TODO : change back to 9999
  }

  DEBUGG2 && console.log('SDD= ', sdd);

  let intervalsNumber = sdd.intervalCountDefault; // cate reprize are meciul default
  let intervalLength = sdd.intervalLengthDefault; // cat de lunga e o repriza
  let intervalLetter = sdd.intervalLetter; // 1h / 2h / 1Q .. 4Q / 1S

  const lang = getStore().getState().application.language;
  if (intervalLetter === 'R' && lang !== 'ro') {
    intervalLetter = 'H';
  }

  let roundWinPts = 0;
  let roundWinPtsMD = sdd.intervalPointsWinDifference;

  let intervals = Array(intervalsNumber).fill(0);
  let inOvertime = false;
  let overtimeLength = sdd.overtimeLengthDefault;

  // TODO: verific ca datele sunt ok si le pot procesa -> daca nu ramane default

  // din SS:  impart stringul ("-" =>
  //  scorul pe fiecare repriza -> fac o variabila care sa si numere in ce repriza se afla currentPeriodNumber sau raman cu array.lenght
  //  scorul general
  let currentPeriodNumber = null;
  let status = '';
  let halfNames = [];
  if (match.currentStatus) {
    const cs = match.currentStatus;
    intervalTime = cs.LiveEventTime;
    if (match.idSport === '4') {
      intervalTime = 12 - intervalTime;
    }
    const eachPeriodScore = cs.LiveSetScore.split('-');

    if (cs.LiveMatchShortStatus && typeof cs.LiveMatchShortStatus === 'object' && cs.LiveMatchShortStatus[2]) {
      status = cs.LiveMatchShortStatus[2].toLowerCase();
      let cpn = status.match(/(\d+)/);
      DEBUGG && console.log('cpn', cpn);
      if (cpn) {
        let overtimePeriods = 0;
        if (status.toLowerCase().indexOf('extra') !== -1 || cs.EventAddedExtraTime > 0) {
          overtimePeriods = eachPeriodScore.length - intervalsNumber;
        }
        currentPeriodNumber = parseInt(cpn[0]) + overtimePeriods;
        DEBUGG && console.log('detected currentPeriodNumber', currentPeriodNumber);
      } else {
        if (status === 'not started' || status === '') {
          currentPeriodNumber = 0;
        } else if (status === 'penalty shootout') {
          currentPeriodNumber = eachPeriodScore.length;
        } else if (status === 'finished') {
          currentPeriodNumber = eachPeriodScore.length;
        }
      }
    }

    DEBUGG && console.log('eachPeriodScore= ', eachPeriodScore);
    // pt fiecare perioada , split ":" ca sa iau scorurile
    // perechea de scoruri o bag intr-un array

    const maxP = currentPeriodNumber !== null ? currentPeriodNumber : eachPeriodScore.length;

    let periodsScores = [];

    for (let i = 0; i < maxP; i++) {
      let pS = eachPeriodScore[i];
      if (typeof pS === 'undefined' || pS === null || pS === '') {
        pS = '0:0';
      }
      pS = pS.trim();
      // pS = "11:22"
      DEBUGG && console.log('PS= ', pS);
      const teamScore = pS.split(':');
      if (teamScore.length) {
        const cpHS = !isNaN(parseInt(teamScore[0], 10)) ? parseInt(teamScore[0], 10) : 0;
        const cpAS = !isNaN(parseInt(teamScore[1], 10)) ? parseInt(teamScore[1], 10) : 0;
        periodsScores.push([cpHS, cpAS]);
      } else {
        const cpHS = !isNaN(parseInt(pS[0], 10)) ? parseInt(pS[0], 10) : 0;
        const cpAS = !isNaN(parseInt(pS[1], 10)) ? parseInt(pS[1], 10) : 0;
        periodsScores.push([cpHS, cpAS]);
      }

      if (i < intervalsNumber) {
        halfNames.push(sdd.intervalLetter + (i + 1));
      } else {
        halfNames.push('OT' + (i - intervalsNumber + 1));
      }
    }

    // console.log("halfNames", halfNames);

    // calculez in ce perioada se afla meciul in functie de TM
    // daca TM < intervalLength => prima perioada
    // daca TM > intervalLength => impart TM la intervalLength si fac Math.floor
    if (currentPeriodNumber === null) {
      if (intervalTime && intervalTime < intervalLength) {
        currentPeriodNumber = 1;
      } else if (intervalTime && intervalTime >= intervalLength) {
        currentPeriodNumber = Math.floor(intervalTime / intervalLength) + 1;
      }
    }

    if (currentPeriodNumber > periodsScores.length) currentPeriodNumber = periodsScores.length;

    // TODO: pt tennis
    // trebuie sa stiu in ce set se afla : adun scorurile AS+HS(scorurile din seturile trecute) si adaug +1
    if (match.idSport === '3' || match.idSport === '25') currentPeriodNumber = cs.AwayTeamScore + cs.HomeTeamScore + 1;

    // salvez datele in rezultatul functiei
    scoreNoFormatData.currentScore = [cs.HomeTeamScore, cs.AwayTeamScore];
    scoreNoFormatData.score = periodsScores;
    scoreNoFormatData.halfNames = halfNames;
    DEBUGG && console.log('curentPeriodNumber= ', currentPeriodNumber);

    //scoreWithFormatData pt inceput = array cu scoruri si {isBig: false , isColored: false}
    scoreWithFormatData = new Array(scoreNoFormatData.score.length);
    for (let i = 0; i < scoreNoFormatData.score.length; i++) {
      if (typeof scoreNoFormatData.score[i] !== 'undefined') {
        scoreWithFormatData[i] = [
          scoreNoFormatData.score[i][0],
          scoreNoFormatData.score[i][1],
          { isBig: false, isColored: false },
        ];
      } else {
        scoreWithFormatData[i] = [0, 0, { isBig: false, isColored: false }];
        scoreNoFormatData.score[i] = [0, 0];
      }
    }
    // element cu perioada curenta -> isColored true:
    if (currentPeriodNumber && scoreWithFormatData && scoreWithFormatData[currentPeriodNumber - 1]) {
      scoreWithFormatData[currentPeriodNumber - 1][2].isColored = true;
      DEBUGG && console.log('scorul din perioada curenta= ', scoreWithFormatData[currentPeriodNumber - 1]);
    }

    // ultimul element arata scorul general si isBig true
    // pt soccer, basket ...
    scoreWithFormatData.push([cs.HomeTeamScore, cs.AwayTeamScore, { isBig: true, isColored: false }]);

    // pt tenis:
    if (cs.LiveGameScore) {
      // trebuie sa stiu scorul game-ului : il am in GS
      const currentGameScore = cs.LiveGameScore.split(':');
      const cgHS = !isNaN(parseInt(currentGameScore[0], 10)) ? parseInt(currentGameScore[0], 10) : 0;
      const cgAS = !isNaN(parseInt(currentGameScore[1], 10)) ? parseInt(currentGameScore[1], 10) : 0;

      // bag ca ultim scor in array
      scoreWithFormatData.push([cgHS !== 50 ? cgHS : 'A', cgAS !== 50 ? cgAS : 'A', { isBig: true, isColored: false }]);
      scoreNoFormatData.score.push([cgHS !== 50 ? cgHS : 'A', cgAS !== 50 ? cgAS : 'A']);
      scoreNoFormatData.halfNames.push('P');
      // TODO : pt teamLoosing trebuie sa verific cine are avantaj

      teamServing = cs.LiveServer;
    }

    if (cs.HomeTeamScore < cs.AwayTeamScore) {
      teamLosing = 1;
    } else if (cs.HomeTeamScore > cs.AwayTeamScore) {
      teamLosing = 2;
    }

    if (match.idSport === '1') {
      if (cs.LiveScoresRCard) {
        const rs = cs.LiveScoresRCard.split(':');
        scoreNoFormatData.score.push([defValue(parseInt(rs[0], 10), 0), defValue(parseInt(rs[1], 10), 0)]);
      } else {
        scoreNoFormatData.score.push([0, 0]);
      }
      scoreNoFormatData.halfNames.push('RED-CARDS');
    }
  }

  let ci = currentPeriodNumber - 1;
  if (ci >= 0) {
    switch (sdd.intervalCountType) {
      // time-based cases (currently handled together)
      case 'fixed':
      case 'variable':
        // fill previous intervals
        for (let i = 0; i < ci; i++) {
          intervals[i] = 100;
        }
        if (overtimeLength && intervalTime >= 1 + intervalsNumber * intervalLength) {
          // we're clearly in overtime
          intervals.push(
            Math.ceil((100 * ((intervalTime - intervalsNumber * intervalLength) % overtimeLength)) / overtimeLength),
          );
        } else {
          intervals[ci] = Math.ceil((100 * (intervalTime % intervalLength)) / intervalLength);
        }
        break;
      // score-based cases
      case 'points':
        intervalTime = '';
        for (let i = 0; i < ci; i++) {
          intervals[i] = 100;
        }
        // let ssd = match.currentStatus.LiveSetScore.split("-");
        // console.log(`ssd`, ssd);
        // if (isArray(ssd) && ssd.length > ci) {
        //   let cs = ssd[ci];
        //   let ss = cs.score.split(":");
        //   if (ss.length >= 2) {
        //     // found at least 2 scores to compare
        //     let si = ss.map(s => parseInt(s, 10));
        //     let mws = Math.max(si[0], si[1]);
        //     let mls = Math.min(si[0], si[1]);
        //     let endscore = roundWinPts;
        //     // patch for snooker round end score approximation
        //     if (match.idSport === "19") endscore = roundWinPts - mls;
        //     // adjust expected endscore if scores too close for win
        //     if (mws > endscore - roundWinPtsMD) {
        //       if (mws - mls < roundWinPtsMD) endscore = roundWinPts + roundWinPtsMD - mws + mls;
        //     }
        //     intervals[ci] = Math.ceil((100 * mws) / endscore);
        //   } else {
        //     // fallback default progress indicator
        //     intervals[ci] = 50;
        //   }
        //   // patch for baseball inning score not being indicative of inning progress
        //   if (match.idSport === "3") {
        //     intervals[ci] = 50;
        //     if ("atBatter" in matchStatus) {
        //       intervals[ci] = Math.ceil((100 * matchStatus.atBatter) / 9);
        //     }
        //   }
        // }
        break;
      // unimplemented sport or no recognizable progress-to-round-end features
      default:
        intervals[ci] = 50;
    }

    // patch for break - paused matches will always have a reminder of 0 but round will be complete
    if (status === 'halftime' || status === 'break time') {
      intervals[ci] = 100;
    }
    // patch for round end with status "paused" not present and/or minor (<1m) regular round overtime
    if (intervals[ci] === 0) {
      intervals[ci] = 95;
    }
    // patch for visibility - show some minor but visible progress otherwise
    if (intervals[ci] < 5) {
      intervals[ci] = 5;
    }

    // intervalName = intervalName + ` (${ci+1}/${intervalsNumber}|${intervalLength}-${overtimeLength})` // debug only
  }

  switch (status) {
    case '':
    case 'not started':
      intervalName = 'NS';
      intervalTime = '';
      break;
    case 'halftime':
    case 'break time':
      intervalName = 'P';
      intervalTime = '';
      break;
    case 'finished':
      intervalName = 'F';
      intervalTime = '';
      break;
    case 'penalty shootout':
      intervalName = 'PS';
      intervalTime = '';
      break;
    default:
      if (currentPeriodNumber > intervalsNumber) {
        intervalName = 'OT' + (currentPeriodNumber - intervalsNumber);
      } else {
        let il = sdd.intervalLetter;
        if (il === 'R' && lang !== 'ro') {
          il = 'H';
        }
        intervalName = il + currentPeriodNumber;
      }
      if (intervalTime !== '') intervalTime += "'";
  }

  const res = {
    halfNames: scoreNoFormatData.halfNames,
    teamLosing, // NEW: team (0, 1, 2) currently at a DISadvantage in the match (for winner name highlighting)
    teamServing, // NEW: team currently serving (0 for matches with no service or not in active play)
    scoreWithFormatData, // NEW: all scores array containing display properties object
    scoreNoFormatData,
    intervals,
    intervalName,
    intervalTime,

    // extra info if needed
    intervalType: intervalLetter,
    period: currentPeriodNumber,
  };

  DEBUGG && console.log('res', res);

  return res;
};
