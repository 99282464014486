export const TaxType = {
  WITHDRAW: 1,
  DEPOSIT: 2,
  SPORT_STAKE: 3,
  SPORT_WIN: 4,
};

export type TaxRequest = {
  taxType: number;
  totalAmount: number;
  notTaxableAmount?: number;
  key: string;
};

export type TaxResponse = {
  rawTaxAmount: number;
  taxAmount: number;
  originalAmount: number;
  amountAfterTax: number;
  taxedAmount: number;
};

export type TaxLevel = {
  threshold?: number | null;
  tax: number;
};

export abstract class TaxCalculatorBase {
  public calculateTax = (request: TaxRequest): TaxResponse => {
    switch (request.taxType) {
      case TaxType.DEPOSIT:
        return this.getDepositTax(request);
      case TaxType.WITHDRAW:
        return this.getWithdrawTax(request);
      case TaxType.SPORT_STAKE:
        return this.getSportsbookBetTax(request);
      case TaxType.SPORT_WIN:
        return this.getSportsbookWinTax(request);
      default:
        throw new Error('Invalid tax type');
    }
  };

  protected calculateProgressiveTax(totalAmount: number, taxLevels: TaxLevel[]): number {
    let taxedSoFar = 0;
    let rawTaxAmount = 0;
    for (const level of taxLevels) {
      let taxableAmount = totalAmount;
      if (level.threshold) {
        taxableAmount = Math.min(totalAmount, level.threshold - taxedSoFar);
      }
      taxedSoFar += taxableAmount;
      totalAmount -= taxableAmount;
      rawTaxAmount += level['tax'] * taxableAmount;
    }
    return rawTaxAmount;
  }

  abstract getDepositTax: (request: TaxRequest) => TaxResponse;
  abstract getWithdrawTax: (request: TaxRequest) => TaxResponse;
  abstract getSportsbookBetTax: (request: TaxRequest) => TaxResponse;
  abstract getSportsbookWinTax: (request: TaxRequest) => TaxResponse;
}
