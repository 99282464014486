import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import Animator from './utils/animator';

//import './index.scss';

type AviatorAnimationProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const AviatorAnimation = (componentProps: AviatorAnimationProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  React.useEffect(() => {
    const animator = new Animator();
    animator.startAnimation();

    return () => {
      animator.stopAnimation();
    };
  }, []);

  const contextValue = React.useMemo(() => {
    return {};
  }, [dataElementContext, componentProps]);

  console.log('AviatorAnimation[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default AviatorAnimation;
