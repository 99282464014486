import React from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { appUKSetSelectedBet, appUKSetSelectedArgument } from '@/modules/bets/store/actions/app';

//import './index.scss';

type BetsSelectorProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const extractUriParams = (match: any, props: any) => {
  let idSport = props.properties.idSport;

  let paramIdSport = props.properties.paramIdSport ?? 'idSport';
  if (paramIdSport[0] === ':') paramIdSport = paramIdSport.substr(1);
  if (match.params && paramIdSport && match.params[paramIdSport] != null) {
    idSport = match.params[paramIdSport];
  } else {
    idSport = null;
  }

  return [idSport ?? '1'];
};

const BetsSelector = (componentProps: BetsSelectorProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const uriMatches = useMatches();
  const dispatch = useAppDispatch();

  const ukSelectedBet: string | null = useAppSelector((state) => state.bets.app.ukSelectedBet);
  const ukSelectedArgument: number | null = useAppSelector((state) => state.bets.app.ukSelectedArgument);
  const ukMarketsBySport: any = useAppSelector((state) => state.bets.app.ukMarketsBySport);

  let idSport = componentProps.properties.idSport;
  if (componentProps.properties.targetIdFromPath) {
    if (uriMatches && uriMatches.length) {
      // there is no gameId set as props; maybe were in a route that includes a :gameId param
      const match = uriMatches[0];

      [idSport] = extractUriParams(match, componentProps);
    }
  }

  //console.log('BetsSelector[idSport]', idSport);

  React.useEffect(() => {
    if (ukMarketsBySport[idSport]) {
      if (ukSelectedBet == null) {
        if (ukMarketsBySport?.[idSport]?.length) {
          // @ts-ignore
          dispatch(appUKSetSelectedBet(ukMarketsBySport[idSport][0].idBet));

          if (ukMarketsBySport[idSport][0].arguments?.length) {
            // @ts-ignore
            dispatch(appUKSetSelectedArgument(ukMarketsBySport[idSport][0].arguments[0]));
          }
        }
      } else {
        const selected = ukMarketsBySport[idSport].find((o: any) => o.idBet === ukSelectedBet);

        if (!selected) {
          // @ts-ignore
          dispatch(appUKSetSelectedBet(ukMarketsBySport[idSport][0].idBet));

          if (ukMarketsBySport[idSport][0].arguments?.length) {
            // @ts-ignore
            dispatch(appUKSetSelectedArgument(ukMarketsBySport[idSport][0].arguments[0]));
          }
        } else if (ukMarketsBySport?.[idSport]?.length && ukSelectedArgument == null) {
          if (selected?.arguments?.length && selected.arguments[0]) {
            // @ts-ignore
            dispatch(appUKSetSelectedArgument(selected.arguments[0]));
          }
        }
      }
    }
  }, [ukSelectedBet, ukSelectedArgument, ukMarketsBySport, idSport]);

  const onSelectBet = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (e.currentTarget.dataset.idBet) {
        const idBet = e.currentTarget.dataset.idBet;
        // @ts-ignore
        dispatch(appUKSetSelectedBet(idBet));
      }
    },
    [dispatch, appUKSetSelectedBet],
  );

  const onSelectArgument = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (e.currentTarget.dataset.id) {
        const id = parseFloat(e.currentTarget.dataset.id);
        // @ts-ignore
        dispatch(appUKSetSelectedArgument(id));
      }
    },
    [dispatch, appUKSetSelectedBet],
  );

  const contextValue = React.useMemo(() => {
    let options: any[] = [];

    if (ukMarketsBySport?.[idSport]) {
      options = ukMarketsBySport[idSport];
    }

    let betArguments: any[] = [];
    let argumentPrefix = '';
    let argumentPostfix = '';
    if (ukSelectedBet) {
      const selected = options.find((o) => o.idBet === ukSelectedBet);
      if (selected?.arguments) {
        betArguments = selected.arguments.map((id: number) => ({ id }));
        argumentPrefix = selected.argumentPrefix ?? '';
        argumentPostfix = selected.argumentPostfix ?? '';
      }
    }

    return {
      selected: ukSelectedBet,
      selectedIndex: ukSelectedBet ? options.findIndex((o) => o.idBet === ukSelectedBet) : -1,
      options,
      onSelectBet,
      selectedArgument: ukSelectedArgument,
      betArguments,
      argumentPrefix,
      argumentPostfix,
      onSelectArgument,
    };
  }, [
    dataElementContext,
    componentProps,
    ukSelectedBet,
    ukMarketsBySport,
    onSelectBet,
    ukSelectedArgument,
    onSelectArgument,
    idSport,
  ]);

  //console.log('BetsSelector[contextValue]', contextValue, idSport);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default BetsSelector;
