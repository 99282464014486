import React from 'react';
import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import * as EmailValidator from 'email-validator';

import './index.scss';
import {
  changePassword,
  clearPasswordCache,
  loginPassword,
  loginStart,
  loginUsername,
  requestSmsCode,
  resetPasswordSendEmail,
  resetPasswordSendNewPassword,
  resetPasswordSetToken,
  setLoginUsername,
  needsResetPassword,
} from '../../../modules/casino/store/actions';
import PasswordValidator from '../../../utils/PasswordValidator';
import { resetPasswordClearErrorMessage } from '../../../modules/casino/store/actions/resetPassword';
import PhoneNumberValidator from '@/modules/casino/utils/PhoneNumberValidator';
import { IS_PHONE } from '@/modules/casino/utils/LoginMethods';

type ForgotPasswordProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const ForgotPassword = (componentProps: ForgotPasswordProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;
  const dispatch = useAppDispatch();

  const resetPassword = useAppSelector((state) => state.resetPassword);
  const allCountries = useAppSelector((state) => state.allCountries);
  const login = useAppSelector((state) => state.login);
  const profile = useAppSelector((state) => state.profile);

  const [phoneNumber, setPhoneNumber] = React.useState(
    login && login.needsResetPassword && login.username ? login.username : '',
  );
  const [phoneCode, setPhoneCode] = React.useState('');
  const [receivedPassword, setReceivedPassword] = React.useState('');
  const [receivedPasswordIsValid, setReceivedPasswordIsValid] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [retypePassword, setRetypePassword] = React.useState('');
  const [newPasswordIsValid, setNewPasswordIsValid] = React.useState(false);
  const [retypePasswordIsValid, setRetypePasswordIsValid] = React.useState(false);
  const [finalPassword, setFinalPassword] = React.useState('');
  const [isValid, setIsValid] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);
  const [step, setStep] = React.useState('step-1');
  const [showReceivedPassword, setShowReceivedPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showReTypePassword, setShowReTypePassword] = React.useState(false);
  const [token, setToken] = React.useState('');
  const [isValidPhone, setIsValidPhone] = React.useState(false);
  const [smsCodeInRequest, setSmsCodeInRequest] = React.useState(false);
  const [hasMadeOneRequest, setHasMadeOneRequest] = React.useState(false);
  const [SMSCode, setSMSCode] = React.useState('');

  React.useEffect(() => {
    if (login.needsResetPassword) dispatch(needsResetPassword(false));
  }, []);

  React.useEffect(() => {
    if (!resetPassword.loading && resetPassword && email) {
      if (resetPassword.token === 'sent' && resetPassword.errorMessage === '') {
        setEmailSent(true);
        setStep('step-2');
      }
    }

    if (!resetPassword.loading && resetPassword && newPassword && retypePassword) {
      if (resetPassword.success) {
        setStep('step-4');
      }
    }
  }, [resetPassword.loading]);

  React.useEffect(() => {
    if (!profile?.loading && receivedPassword && receivedPasswordIsValid && profile?.receivedChangePassword) {
      if (profile?.receivedChangePassword?.ResponseCode) {
        setReceivedPassword('');
      } else {
        setStep('step-4');
        authenticateUser();
      }
    }
  }, [profile.loading]);

  React.useEffect(() => {
    getToken();

    return () => {
      setEmailSent(false);
    };
  }, []);

  React.useEffect(() => {
    if (allCountries?.currentCountry) {
      const selectedCountry: any = allCountries?.countries?.find(
        (country) => country.countryCode === allCountries?.currentCountry,
      );
      if (selectedCountry?.phoneCode) {
        setPhoneCode(selectedCountry?.phoneCode);
      }
    }
  }, [allCountries?.currentCountry]);

  const getToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token && token !== 'undefined' && token !== '' && token.length > 30) {
      dispatch(resetPasswordSetToken(token));
      setToken(token);
      setStep('step-3');
    } else {
      return;
    }
  };

  const passwordValidator = (password: string) => {
    const passwordIsValid = PasswordValidator(password);
    // return passwordIsValid.success;
    return passwordIsValid;
  };

  const phoneValidator = (phone: string) => {
    const valid = PhoneNumberValidator.validate(phone, allCountries?.currentCountry);
    setIsValidPhone(valid);
  };

  const onValidation = (isValid: boolean) => {
    setIsValidPhone(isValid && isValidPhone);
  };

  React.useEffect(() => {
    if (phoneNumber) {
      phoneValidator(phoneNumber);
    }
  }, [phoneNumber]);

  const validator: any = PasswordValidator(newPassword);

  const handleSendSms = () => {
    dispatch(requestSmsCode());
    isSmsCodeInRequest();
  };

  const isSmsCodeInRequest = () => {
    if (!hasMadeOneRequest) {
      setHasMadeOneRequest(true);
    }
    setSmsCodeInRequest(true);

    setTimeout(() => {
      setSmsCodeInRequest(false);
    }, 2000);
  };

  const resendTempSMSCodeHandler = () => {
    if (login.etaSmsRequest <= 1) {
      dispatch(requestSmsCode());
      isSmsCodeInRequest();
      setSMSCode('');
    }
  };

  const authenticateUser = () => {
    if (finalPassword && phoneNumber && isValidPhone) {
      dispatch(loginUsername(phoneNumber.replaceAll(' ', '')));
      dispatch(loginPassword(finalPassword));
      dispatch(loginStart(undefined));
    }
  };

  React.useEffect(() => {
    if (isValidPhone && !smsCodeInRequest) {
      setStep('step-2');
    }
  }, [smsCodeInRequest]);

  const checkErrors = () => {
    if (step === 'step-3' && !newPassword) {
      return { hasErrors: true, errorMessage: validator.message };
    } else if (step === 'step-3' && newPassword) {
      // 6 or config.min ?
      if (validator && validator.success && newPassword !== retypePassword) {
        return { hasErrors: true, errorMessage: 'Passwords are different' };
      } else {
        if (validator && validator.success === false) {
          return { hasErrors: true, errorMessage: validator.message };
        }
      }
    }
    if (profile?.receivedChangePassword?.ResponseCode) {
      return { hasErrors: true };
    }
    return { hasErrors: false, errorMessage: validator.message };
  };

  const contextValue = {
    email: email,
    phoneNumber: phoneNumber,
    phoneCode: phoneCode,
    receivedPassword: receivedPassword,
    newPassword: newPassword,
    retypePassword: retypePassword,
    isValid: isValid,
    isValidPhone: isValidPhone,
    onValidation: onValidation,
    smsCodeInRequest: smsCodeInRequest,
    resendTempSMSCode: resendTempSMSCodeHandler,
    etaSmsRequest: login.etaSmsRequest ? login.etaSmsRequest : '',
    receivedPasswordIsValid: receivedPasswordIsValid,
    newPasswordIsValid: newPasswordIsValid,
    retypePasswordIsValid: retypePasswordIsValid,
    loading: resetPassword.loading || profile.loading,
    emailSent: emailSent,
    showReceivedPassword: showReceivedPassword,
    showPassword: showPassword,
    showReTypePassword: showReTypePassword,
    step: step,
    needsResetPassword: login.needsResetPassword,
    errorMessage:
      resetPassword.errorMessage || step === 'step-1' ? resetPassword.errorMessage : checkErrors().errorMessage,

    passwordStrength: validator.score,
    passwordShortStrengthLabel: validator.labels.short,
    passwordLongStrengthLabel: validator.labels.long,
    passwordMessage: validator.message,
    passwordAccepted: validator.success,
    passwordConditionsNotMet: validator.conditionsNotMet,
    // hasErrors: false,
    hasErrors: checkErrors().hasErrors,

    onChangePhoneNumber: (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.target ? (e.target as HTMLInputElement).value : '';
      setIsValid(PhoneNumberValidator.validate(value));
      if (resetPassword.errorMessage) {
        dispatch(resetPasswordClearErrorMessage());
      }
      if (phoneCode && value) {
        dispatch(setLoginUsername(`+${phoneCode}${value.replaceAll(' ', '')}`, false, IS_PHONE));
      }
      setPhoneNumber(value);
    },
    onChangeEmail: (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.target ? (e.target as HTMLInputElement).value : '';
      setIsValid(EmailValidator.validate(value));
      if (resetPassword.errorMessage) {
        dispatch(resetPasswordClearErrorMessage());
      }
      setEmail(value);
    },
    onChangeReceivedPassword: (e: React.FormEvent<HTMLInputElement>) => {
      let value = e.target ? (e.target as HTMLInputElement).value : '';
      value = value.replace(/\s/g, '');
      setReceivedPasswordIsValid(value.length >= 4);
      if (resetPassword.errorMessage) {
        dispatch(resetPasswordClearErrorMessage());
      }
      dispatch(clearPasswordCache());
      setReceivedPassword(value);
    },
    onChangeNewPassword: (e: React.FormEvent<HTMLInputElement>) => {
      let value = e.target ? (e.target as HTMLInputElement).value : '';
      value = value.replace(/\s/g, '');

      if (window.config.password.restrictSpecialCharacters === '1' && value.match(/[^A-Za-z0-9]/g)) {
        console.log('Special characters are not allowed');
        return;
      }

      setNewPasswordIsValid(passwordValidator(value).success);
      if (resetPassword.errorMessage) {
        dispatch(resetPasswordClearErrorMessage());
      }
      setNewPassword(value);
    },
    onChangeRetypePassword: (e: React.FormEvent<HTMLInputElement>) => {
      let value = e.target ? (e.target as HTMLInputElement).value : '';
      value = value.replace(/\s/g, '');
      setRetypePasswordIsValid(passwordValidator(value).success);
      if (resetPassword.errorMessage) {
        dispatch(resetPasswordClearErrorMessage());
      }
      setRetypePassword(value);
    },
    onSendEmail: () => email !== '' && isValid && dispatch(resetPasswordSendEmail(email)),
    onSendSms: () => phoneNumber !== '' && isValidPhone && handleSendSms(),
    onPreviousStep: () => {
      setStep('step-1');
      setEmail('');
    },
    showReceivedPasswordHandler: () => setShowReceivedPassword(!showReceivedPassword),
    showPasswordHandler: () => setShowPassword(!showPassword),
    showReTypePasswordHandler: () => setShowReTypePassword(!showReTypePassword),
    onVerifyReceivedPasswordHandler: () => {
      setStep('step-3');
    },
    onSubmitNewPasswordHandler: (e: any) => {
      if (e.currentTarget.dataset.id === 'change-password') {
        if (newPasswordIsValid && retypePasswordIsValid && receivedPasswordIsValid && phoneNumber && isValidPhone) {
          dispatch(
            changePassword(receivedPassword, newPassword, 'sms', `+${phoneCode}${phoneNumber.replaceAll(' ', '')}`),
          );
          setFinalPassword(newPassword);
        }
      } else if (phoneNumber && isValidPhone && receivedPassword && receivedPasswordIsValid) {
        dispatch(
          changePassword(receivedPassword, receivedPassword, 'sms', `+${phoneCode}${phoneNumber.replaceAll(' ', '')}`),
        );
        setFinalPassword(receivedPassword);
      }
      if (
        token &&
        newPassword &&
        newPasswordIsValid &&
        retypePassword &&
        retypePasswordIsValid &&
        newPassword === retypePassword
      ) {
        dispatch(resetPasswordSendNewPassword(token, newPassword));
      }
    },
  };

  console.log('Forgot password: ', { contextValue, phoneNumber, isValidPhone, login, phoneCode, step });

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default ForgotPassword;
