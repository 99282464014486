import * as actionTypes from './actionTypes';

export const validateField = (value, field) => ({
  type: actionTypes.register.VALIDATE_FIELD,
  field: field,
  value: value,
});

export const validateFieldResponse = (message, field) => ({
  type: actionTypes.register.VALIDATE_FIELD_RESPONSE,
  field: field,
  message: message,
});

export const sendRegisterSMS = (data) => ({
  type: actionTypes.register.SEND_REGISTER_SMS,
  data: data,
});

export const sendRegisterSMSResponse = (data) => ({
  type: actionTypes.register.SEND_REGISTER_SMS_RESPONSE,
  data,
});

export const register = (data) => ({
  type: actionTypes.register.REGISTER,
  data,
});

export const registerResponse = (data) => ({
  type: actionTypes.register.REGISTER_RESPONSE,
  errors: data,
});

export const setPartialAccount = (data) => ({
  type: actionTypes.register.SET_PARTIAL_ACCOUNT,
  data,
});

export const partialAccountResponse = (data) => ({
  type: actionTypes.register.PARTIAL_ACCOUNT_RESPONSE,
  data,
});
export const resetRegister = () => ({
  type: actionTypes.register.RESET_REGISTER,
});
