import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { DataElementContext } from '../common/DataElementContext';
import { equalWithoutChildren } from '../../utils/fastDeepEqual';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { useMediaQuery } from '@/components/utils/useQueryMedia';
import { processTranslatableText } from '@/page-components/utils/functions';

const TextComponent = styled.div((props) => props.$styleText);
const LabelComponent = styled.label((props) => props.$styleText);
const ParagraphComponent = styled.p((props) => props.$styleText);
const SpanComponent = styled.span((props) => props.$styleText);
const H1Component = styled.h1((props) => props.$styleText);
const H2Component = styled.h2((props) => props.$styleText);
const H3Component = styled.h3((props) => props.$styleText);
const H4Component = styled.h4((props) => props.$styleText);
const H5Component = styled.h5((props) => props.$styleText);
const H6Component = styled.h6((props) => props.$styleText);

const interceptClickEvent = (onClick) => (e) => {
  const target = e.target || e.srcElement;
  if (target.tagName === 'A' && window.config.cordova) {
    const href = target.getAttribute('href');

    if (href.indexOf('http') > -1) {
      console.log('Opening external link in system browser', href);
      e.preventDefault();
      window.cordova.InAppBrowser.open(href, '_system');
    }
  }
  onClick?.(e);
};

export const Text = (componentProps) => {
  let props = componentProps;

  const { i18n, t } = useTranslation();
  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  const isTablet = useMediaQuery(`(min-width: ${window.config.breakpointTablet})`);
  const isDesktop = useMediaQuery(`(min-width: ${window.config.breakpointDesktop})`);
  const breakpoints = React.useMemo(() => ({ isTablet, isDesktop }), [isTablet, isDesktop]);

  [props, isVisible] = processComponentProps(props, dataElementContext, breakpoints);

  const text = React.useMemo(() => {
    return processTranslatableText(props.properties.text, props.properties.placeholders, i18n.language, t);
  }, [props.properties.text, props.properties.placeholders, i18n.language, t]);

  if (!isVisible) return null;

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  if (props?.properties?.title) {
    extraDataProps['title'] = props?.properties?.title;
  }
  if (props?.properties?.for) {
    extraDataProps['htmlFor'] = props?.properties?.for;
  }
  if (props?.properties?.id) {
    extraDataProps['id'] = props?.properties?.id;
  }

  if (props?.properties?.asLabel) {
    return (
      <LabelComponent
        className={props.className ?? ''}
        $styleText={props.styleText}
        style={props.style}
        dangerouslySetInnerHTML={{ __html: text }}
        data-node-id={props.nodeId}
        onClick={props?.properties?.onClick ?? null}
        {...extraDataProps}
      />
    );
  }

  let onClick = props.properties.onClick ?? null;

  if (window.config.cordova === true) {
    onClick = interceptClickEvent(onClick);
  }

  if (props?.properties?.tag && props?.properties?.tag !== 'div') {
    let TagComponent = TextComponent;

    switch (props?.properties?.tag) {
      case 'span':
        TagComponent = SpanComponent;
        break;
      case 'paragraph':
        TagComponent = ParagraphComponent;
        break;
      case 'h1':
        TagComponent = H1Component;
        break;
      case 'h2':
        TagComponent = H2Component;
        break;
      case 'h3':
        TagComponent = H3Component;
        break;
      case 'h4':
        TagComponent = H4Component;
        break;
      case 'h5':
        TagComponent = H5Component;
        break;
      case 'h6':
        TagComponent = H6Component;
        break;
    }

    return (
      <TagComponent
        className={props.className ?? ''}
        $styleText={props.styleText}
        style={props.style}
        dangerouslySetInnerHTML={{ __html: text }}
        data-node-id={props.nodeId}
        onClick={onClick}
        {...extraDataProps}
      />
    );
  }

  return (
    <TextComponent
      className={props.className ?? ''}
      $styleText={props.styleText}
      style={props.style}
      dangerouslySetInnerHTML={{ __html: text }}
      data-node-id={props.nodeId}
      onClick={onClick}
      {...extraDataProps}
    />
  );
};

export default React.memo(Text, equalWithoutChildren);
