import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import { ticketSettledListRequest } from '../../../../modules/lotto/store/actions/tickets';
import { lottoDateToString, MONTHS } from '../../../../page-components/utils/functions';
import computeWinningTicket from '../../../../modules/lotto/utils/computeWinningTicket';

type LottoSettledProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const LottoSettled = (componentProps: LottoSettledProps) => {
  const dispatch = useAppDispatch();
  const settled = useAppSelector<any[]>((state) => state.lotto.tickets.ticketsSettled);
  const inProgress = useAppSelector((state) => state.lotto.tickets.requestInProgress.settled);
  const showMore = useAppSelector((state) => state.lotto.tickets.showMore);
  const { i18n } = useTranslation();
  const [expanded, setExpanded] = React.useState<any>({});

  const [page, setPage] = React.useState(1);

  const onShowMore = () => {
    setPage(page + 1);
  };

  const onToggleExpanded = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.serial != null && e.currentTarget.dataset.serial !== '') {
      const serial = e.currentTarget.dataset.serial;
      setExpanded((v: any) => ({ ...v, [serial]: v[serial] ? !v[serial] : true }));
    }
  }, []);

  React.useEffect(() => {
    dispatch(ticketSettledListRequest({ page }));
  }, [page]); //eslint-disable-line

  const contextValue = React.useMemo(() => {
    const tickets: any[] = [];
    settled.forEach((ticket) => {
      const dt = moment(ticket.event_time / 1e6).locale(i18n.language === 'ro' ? 'ro' : 'en');
      const event_date = dt.format('YYYY-MM-DD HH:mm:ss');
      const sd = dt.format('D') + ' ' + (i18n.language === 'ro' ? MONTHS[dt.format('M')] : dt.format('MMM'));
      const tm = dt.format('HH:mm');

      const ticket_time_formatted = lottoDateToString(ticket.time / 1e6);

      let lucky_number = 0;
      let lucky_number_won = false;
      let stake_is_free_bet = false;
      let stake_is_redeemable = false;
      let free_bet_name = '';
      let bonuses: any[] = [];
      let free_bet_line = '';

      if (ticket && ticket.bonus_data) {
        bonuses = [];
        let bonus_data = [];
        try {
          bonus_data = JSON.parse(ticket.bonus_data);
        } catch (err) {}

        bonus_data.forEach((b: any) => {
          if (b && b.type === 'lotto_lucky_number') {
            if (b.data) {
              let data: any = {};
              try {
                data = JSON.parse(b.data);
              } catch (err) {}

              if (
                data &&
                typeof data.number !== 'undefined' &&
                ticket.numbers.indexOf(parseInt(data.number, 10)) > -1
              ) {
                lucky_number = parseInt(data.number, 10);
                bonuses.push('Lotto Lucky Number');
              }
            }
          } else if (b && b.type === 'lotto_chance') {
            if (b.data) {
              let data: any = {};
              try {
                data = JSON.parse(b.data);
              } catch (err) {}

              if (data && typeof data.systems !== 'undefined') {
                if (data.systems.indexOf(`${ticket.numbers.length}/${ticket.numbers.length}`) > -1) {
                  bonuses.push('Lotto Chance');
                }
              }
            }
          } else if (b && b.type === 'free_bet') {
            if (b.data) {
              let data: any = {};
              try {
                data = JSON.parse(b.data);
              } catch (err) {}

              if (data && typeof data.consumed !== 'undefined' && data.consumed) {
                stake_is_free_bet = true;
                free_bet_name = data.name ? data.name : 'N/A';
                stake_is_redeemable = typeof data.redeemable !== 'undefined' && data.redeemable === 1 ? true : false;
              }
            }
          }
        });
      }

      if (ticket && ticket.bonus_settle_data) {
        bonuses = [];
        let bonus_settle_data = [];
        try {
          bonus_settle_data = JSON.parse(ticket.bonus_settle_data);
        } catch (err) {}

        bonus_settle_data.forEach((b: any) => {
          if (b && b.type === 'lotto_lucky_number') {
            if (b.data) {
              let data: any = {};
              try {
                data = JSON.parse(b.data);
              } catch (err) {}

              if (
                data &&
                typeof data.number !== 'undefined' &&
                ticket.numbers.indexOf(parseInt(data.number, 10)) > -1 &&
                lucky_number === parseInt(data.number, 10)
              ) {
                lucky_number_won = true;
                bonuses.push('Lotto Lucky Number');
              }

              if (
                data &&
                typeof data.free_bet_token !== 'undefined' &&
                typeof data.free_bet_amount_small !== 'undefined' &&
                typeof data.free_bet_amount !== 'undefined'
              ) {
                free_bet_line = `${data.free_bet_name ? data.free_bet_name : 'Name'}: ${
                  data.free_bet_token *
                  (data.free_bet_amount_small === 1 ? data.free_bet_amount / 100 : data.free_bet_amount)
                } Lei`;
              }
            }
          } else if (b && b.type === 'lotto_chance') {
            bonuses.push('Lotto Chance');
            if (b.data) {
              let data: any = {};
              try {
                data = JSON.parse(b.data);
              } catch (err) {}

              if (
                data &&
                typeof data.free_bet_token !== 'undefined' &&
                typeof data.free_bet_amount_small !== 'undefined' &&
                typeof data.free_bet_amount !== 'undefined'
              ) {
                free_bet_line = `${data.free_bet_name ? data.free_bet_name : 'Name'}: ${
                  data.free_bet_token *
                  (data.free_bet_amount_small === 1 ? data.free_bet_amount / 100 : data.free_bet_amount)
                } Lei`;
              }
            }
          }
        });
      } else if (ticket && ticket.status !== 'OPEN') {
        bonuses = [];
      }

      const computed_odds: any = [];
      if (expanded[ticket.serial]) {
        const response = computeWinningTicket({
          numbers: ticket.numbers,
          systems: ticket.systems,
          r: ticket.event_R,
          n: ticket.event_N,
          odds: ticket.odds,
          custom_odds: [], // TODO - include actual custom odds
          stake_amount: ticket.amount,
          max_winning: 100000, // TODO - we need to fetch this from lotto instead of using the hard coded value
          drawn_numbers: ticket.event_results,
        });

        const systems: any = {};
        response.system_lines_won.forEach((e) => {
          if (typeof systems[e[0]] === 'undefined') systems[e[0]] = {};
          systems[e[0]].lines_won = e[1];
        });
        response.system_win_amount.forEach((e) => {
          if (typeof systems[e[0]] === 'undefined') systems[e[0]] = {};
          systems[e[0]].win_amount = e[1];
        });

        for (let i = 0; i < ticket.event_M; i++) {
          if (typeof systems[i + 1] !== 'undefined') {
            computed_odds.push({
              hits: `${i + 1}. ${systems[i + 1].lines_won}`,
              odd: 'x ' + ticket.odds[i],
              winning: systems[i + 1].win_amount,
            });
          } else {
            computed_odds.push({
              hits: `${i + 1}. 0`,
              odd: 'x ' + ticket.odds[i],
              winning: '-',
            });
          }
        }
        computed_odds.push({ hits: '', odd: '', winning: response.total_win_amount });
      }

      tickets.push({
        ...ticket,
        event_date,
        ticket_time_formatted,
        event_time_ms: ticket.event_time / 1e6,
        is_past: ticket.event_time / 1e6 < Date.now(),
        lucky_number,
        lucky_number_won,
        stake_is_free_bet,
        stake_is_redeemable,
        free_bet_name,
        free_bet_line,
        bonuses,
        sd,
        tm,
        numbers:
          ticket.numbers?.map((n: number) => ({
            value: n,
            isLucky: n === lucky_number,
            isWon: ticket?.event_results?.indexOf(n) > -1,
          })) ?? [],
        event_results:
          ticket.event_results?.map((n: number) => ({
            value: n,
            exists: ticket.numbers?.indexOf(n) > -1,
          })) ?? [],
        expanded: expanded[ticket.serial] ?? false,
        onToggleExpanded,
        computed_odds,
      });
    });

    const contextValue = {
      tickets: tickets,
      inProgress,
      showMore,
      onShowMore,
      onToggleExpanded,
    };

    return contextValue;
  }, [settled, inProgress, showMore, onShowMore, i18n]);

  //console.log('LottoSettled[contextValue]: ', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default LottoSettled;
